<i18n>
{
  "de": {
    "utilityHeader": "Elektrizitätsversorgung und Preise",
    "noGisData": "Keine Daten verfügbar"
  }
}
</i18n>

<template>
  <detail-list-item class="electricity-utility-row" :title="$t('utilityHeader')" expandable @expand="onModalOpen">
    <div v-if="building.el_utility">
      {{ building.el_utility.name }}
    </div>
    <div v-else>
      {{ $t('noGisData') }}
    </div>
    <template #modal>
      <electricity-utility-modal v-if="modalOpen" @close="onModalClose" :building="building" />
    </template>
  </detail-list-item>
</template>

<script>
import DetailListItem from '@/components/shared/lists/DetailListItem'
import ElectricityUtilityModal from '@/components/building/feasibility/ElectricityUtilityModal'

export default {
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      modalOpen: false
    }
  },
  computed: {
    tarifMedium () {
      return this.building.el_utility.grid_tarifs.find(v => v.category === 'H4').total
    }
  },
  components: {
    DetailListItem,
    ElectricityUtilityModal
  },
  methods: {
    onModalOpen () {
      this.modalOpen = true
    },
    onModalClose () {
      this.modalOpen = false
    }
  }
}
</script>
