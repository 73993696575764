<i18n>
{
  "de": {
    "locationHeader": "Identifikation"
  }
}
</i18n>

<template>
  <detail-list-item :title="$t('locationHeader')" expandable @expand="onModalOpen">
    <span>{{ building.description }} ({{ building.identifier }})</span>
    <template #modal>
      <modal v-if="modalOpen" @close="onModalClose">
        <template v-slot:header>
          <h2>{{ $t('locationHeader') }}</h2>
        </template>
        <edit-base-data :allow-edit="building.permissions.EDIT_DESCRIPTION" :building="building" />
      </modal>
    </template>
  </detail-list-item>
</template>

<script>
import Modal from '@/components/shared/Modal'
import ModalMixin from '@/components/shared/ModalMixin'

import EditBaseData from '@/components/building/edit/EditBaseData'
import DetailListItem from '@/components/shared/lists/DetailListItem'

export default {
  mixins: [ModalMixin],
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  components: {
    Modal,
    EditBaseData,
    DetailListItem
  }
}
</script>
