<i18n>
{
  "de": {
    "modalTitle": "Wärmebedarf Warmwasser",
    "dhwCalibrationLabel": "Kalibrierungsfaktor",
    "dhwTotalLabel": "Nutzenergie",
    "dhwEndEnergyLabel": "Endenergie",
    "calibrationInfoBox": "Abweichung des effektiven Warmwasserbedarfs vom modellierten Warmwasserbedarf. Dieser Wert wird aus der Kalibrierung des Endenergiebedarfs der Wärmeerzeuger ermittelt",
    "calibrationInfoBoxTitle": "Kalibrierungsfaktor Nutzenergie Warmwasser",
    "dhwInfoBoxTitle": "Nutzenergie Warmwasser",
    "dhwInfoBox": "Nutzenergiebedarf Warmwasser unter Berücksichtigung des Kalibrierungsfaktors.",
    "dhwEndEnergyInfoBoxTitle": "Endenergie Warmwasser",
    "dhwEndEnergyInfoBox": "Endenergiebedarf Warmwasser (netto gelieferte Energie, z.B. Brennwert von Heizöl) unter Berücksichtigung des Kalibrierungsfaktors. Die Differenz zur Nutzenergie entsteht durch die Nutzungsziffer des Wärmeerzeugers.",
    "highCalibrationFactorWarningTitle": "Effektiver Energieverbrauch höher als erwartet",
    "highCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich über dem modellierten Energiebedarf. Für mögliche Ursachen siehe Kalibrierung Wärmeerzeugung.",
    "lowCalibrationFactorWarningTitle": "Effektiver Energieverbrauch tiefer als erwartet",
    "lowCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich unter dem modellierten Energiebedarf. Für mögliche Ursachen siehe Kalibrierung Wärmeerzeugung."
  }
}
</i18n>

<template>
  <modal @close="$emit('close', $event)" class="dhw-demand-modal">
    <template v-slot:header>
      <h2>{{ $t('modalTitle') }}</h2>
      <h3>{{ building.identifier }} / {{ building.description }}</h3>
    </template>
    <detail-list>
      <detail-list-item :title="$t('dhwTotalLabel')" :info-title="$t('dhwInfoBoxTitle')" :info-text="$t('dhwInfoBox')">
        <div class="two-columns">
          <span>{{ formatNumber(building.kpi.dhw_demand / 1000, 1) }} MWh</span>
          <span>{{ formatNumber(building.kpi.dhw_demand / building.kpi.energy_area, 1) }} kWh/m<sup>2</sup></span>
        </div>
      </detail-list-item>
      <detail-list-item :title="$t('dhwEndEnergyLabel')" :info-title="$t('dhwEndEnergyInfoBoxTitle')" :info-text="$t('dhwEndEnergyInfoBox')">
        <div class="two-columns">
          <span>{{ formatNumber(building.kpi.dhw_end_energy / 1000, 1) }} MWh</span>
          <span>{{ formatNumber(building.kpi.dhw_end_energy / building.kpi.energy_area, 0) }} kWh/m<sup>2</sup></span>
        </div>
      </detail-list-item>
      <detail-list-item v-if="building.kpi.dhw_calibration !== 1.0" :title="$t('dhwCalibrationLabel')" :info-title="$t('calibrationInfoBoxTitle')" :info-text="$t('calibrationInfoBox')">
        <span v-if="building.kpi.dhw_calibration >= 1.0">
          <data-warning v-if="building.kpi.dhw_calibration > 1.667" :title="$t('highCalibrationFactorWarningTitle')"><span v-html="$t('highCalibrationFactorWarning')" /></data-warning>
          +{{ formatNumber(building.kpi.dhw_calibration * 100 - 100, 1) }} %
        </span>
        <span v-else>
          <data-warning v-if="building.kpi.dhw_calibration < 0.6" :title="$t('lowCalibrationFactorWarningTitle')"><span v-html="$t('lowCalibrationFactorWarning')" /></data-warning>
          {{ formatNumber(building.kpi.dhw_calibration * 100 - 100, 1) }} %
        </span>
      </detail-list-item>
    </detail-list>
  </modal>
</template>

<script>
import Modal from '@/components/shared/Modal'
import DetailList from '@/components/shared/lists/DetailList'
import DetailListItem from '@/components/shared/lists/DetailListItem'
import DataWarning from '@/components/shared/DataWarning'

export default {
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  components: {
    Modal,
    DataWarning,
    DetailList,
    DetailListItem
  }
}
</script>
