<i18n>
{
  "de": {
    "locationHeader": "Standort",
    "noLocation": "Standortdaten fehlen",
    "gwrEntries": "Adresse(n)",
    "noAddressDefined": "Standort nicht gefunden"
  }
}
</i18n>

<template>
  <detail-list-item :title="$t('locationHeader')" expandable @expand="onModalOpen">
    <span v-if="building.addresses !== null">
      <span v-if="building.addresses.length > 1">{{ building.addresses.length }} {{ $t('gwrEntries') }}, </span>
      <span v-else-if="building.addresses.length === 1">{{ building.addresses[0] }}, </span>
    </span>
    <span v-if="building.location_info.state === 'OK'">{{ building.location_info.data.municipality }} {{ building.location_info.data.canton }}</span>
    <missing-data-label v-if="building.latitude === null || building.longitude === null">{{ $t('noLocation') }}</missing-data-label>
    <template #modal>
      <location-modal v-if="modalOpen" @close="onModalClose" :building="building" />
    </template>
  </detail-list-item>
</template>

<script>
import ModalMixin from '@/components/shared/ModalMixin'

import DetailListItem from '@/components/shared/lists/DetailListItem'
import MissingDataLabel from '@/components/shared/MissingDataLabel'
import LocationModal from '@/components/building/overview/LocationModal'

export default {
  mixins: [ModalMixin],
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  components: {
    DetailListItem,
    MissingDataLabel,
    LocationModal
  }
}
</script>
