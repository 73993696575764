<i18n>
{
  "de": {
    "yLabel": "Kapital (CHF)",
    "balance": "Kapitalentwicklung",
    "types": {
      "subsidies": "Einmalvergütung",
      "operation_costs": "Betriebskosten",
      "revenue_ownuse": "Reduktion Netzbezug",
      "revenue_feedin": "Einspeisung",
      "investment": "Investitionskosten"
    }
  }
}
</i18n>
<template>
  <exportable-bar-chart class="npv-chart" :chart-data="chartData" :options="options" />
</template>
<script>
import colorPalettes from '@/services/color-palettes'
import ExportableBarChart from '@/components/shared/charts/ExportableBarChart'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    options () {
      const self = this
      return {
        responsive: true,
        maintainAspectRatio: false,
        /* aspectRatio: 3, */
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return self.formatNumber(value, 0)
              }
            },
            scaleLabel: {
              display: true,
              labelString: this.$t('yLabel')
            }
          }]
        },
        tooltips: {
          mode: 'index',
          callbacks: {
            label: (tooltipItem, data) => `${data.datasets[tooltipItem.datasetIndex].label}: ${self.formatNumber(tooltipItem.yLabel, 0)} CHF`
          }
        },
        legend: {
          position: 'bottom',
          align: 'start',
          reverse: true
        }
      }
    },
    chartData () {
      let datasets = []
      datasets.push({
        label: this.$t(`balance`),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.data.npv,
        backgroundColor: colorPalettes.pvNpvColors['cumulative_total'],
        borderColor: colorPalettes.pvNpvColors['cumulative_total'],
        stack: 'CAPITAL'
      })
      for (const type in this.data.cashflow) {
        if (this.data.cashflow.hasOwnProperty(type)) {
          datasets.push({
            label: this.$t(`types.${type}`),
            lineTension: 0.1,
            borderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 10,
            data: this.data.cashflow[type],
            backgroundColor: colorPalettes.pvNpvColors[type],
            borderColor: colorPalettes.pvNpvColors[type],
            stack: 'CASHFLOW'
          })
        }
      }
      return {
        labels: this.data.years,
        datasets: datasets
      }
    }
  },
  components: {
    ExportableBarChart
  }
}
</script>
