<i18n>
{
  "de": {
    "remarksLabel": "Beschreibung",
    "yearLabel": "Jahr",
    "phaseLabel": "Status",
    "noMeasures": "Keine Massnahmen ausgewählt",
    "envelopeMeasureTitle": "Sanierung Gebäudehülle",
    "heatingMeasureTitle": "Heizungsersatz",
    "pvMeasureTitle": "Photovoltaik",
    "zoneMeasureTitle": "Flächen- und Nutzungsänderung",
    "addEnvelopeMeasure": "Gebäudehülle",
    "addHeatingMeasure": "Heizungsersatz",
    "addPvMeasure": "Photovoltaik",
    "addZonesMeasure": "Fläche und Nutzung",
    "removeMeasure": "Massnahme entfernen"
  }
}
</i18n>

<template>
  <edit-container :bind-form-state="formState" @container="onFormEvent" class="measure-edit">
    <form-row :label="$t('remarksLabel')" v-if="isEditing || model.remarks">
      <text-input v-model="model.remarks" :edit="isEditing" />
    </form-row>
    <form-row :label="$t('yearLabel')">
      <numeric-input noFormatting int v-model="model.year" :min="1900" :max="maxYear" :edit="isEditing" class="year-input" />
    </form-row>
    <form-row :label="$t('phaseLabel')">
      <div v-if="isEditing">
        <input type="radio" id="phase-planned" value="PLANNED" v-model="model.phase">
        <label for="phase-planned">{{ $t('_measurePhases.PLANNED') }}</label>
        <br>
        <input type="radio" id="phase-completed" value="COMPLETED" v-model="model.phase">
        <label for="phase-completed">{{ $t('_measurePhases.COMPLETED') }}</label>
      </div>
      <div v-else class="measure-phase">
        <img src="/icons/calendar.svg" :title="$t('_measurePhases.PLANNED')" v-if="model.phase === 'PLANNED'">
        <img src="/icons/check.svg" :title="$t('_measurePhases.COMPLETED')" v-if="model.phase === 'COMPLETED'">
        <span>{{ $t(`_measurePhases.${model.phase}`) }}</span>
      </div>
    </form-row>

    <!-- Add measure buttons -->
    <div v-if="isEditing">
      <button class="button" v-if="!model.actions.envelope && !hideEnvelopeMeasures && portfolio.enabled_features.ENVELOPE_MEASURES" @click="addEnvelopeMeasure">
        <img class="icon" src="/icons/plus.svg"> {{ $t('addEnvelopeMeasure') }}
      </button>
      <button class="button" v-if="!model.actions.heating && portfolio.enabled_features.HEATING_MEASURES" @click="addHeatingMeasure">
        <img class="icon" src="/icons/plus.svg"> {{ $t('addHeatingMeasure') }}
      </button>
      <button class="button" v-if="!model.actions.pv && portfolio.enabled_features.PV_MEASURES" @click="addPvMeasure">
        <img class="icon" src="/icons/plus.svg"> {{ $t('addPvMeasure') }}
      </button>
      <button class="button" v-if="!model.actions.zones && portfolio.enabled_features.ZONES_MEASURES" @click="addZonesMeasure">
        <img class="icon" src="/icons/plus.svg"> {{ $t('addZonesMeasure') }}
      </button>
    </div>

    <div v-if="noSelectedMeasures" class="measure-box">
      {{ $t('noMeasures') }}
    </div>

    <!-- Envelope -->
    <div v-if="model.actions.envelope !== null" class="measure-box">
      <div class="title-grid">
        <h2>{{ $t('envelopeMeasureTitle') }}</h2>
        <button class="button" @click="removeEnvelopeMeasure" v-if="isEditing"><img class="icon" src="/icons/minus.svg"> {{ $t('removeMeasure') }}</button>
      </div>
      <edit-envelope-measure
        v-model="model.actions.envelope"
        :edit="isEditing"
        @input="onEnvelopeChange"
        @validation="onValidation($event, 'envelope-measures')"
      />
    </div>

    <!-- Heating -->
    <div v-if="model.actions.heating !== null" class="measure-box">
      <div class="title-grid">
        <h2>{{ $t('heatingMeasureTitle') }}</h2>
        <button class="button" @click="removeHeatingMeasure" v-if="isEditing"><img class="icon" src="/icons/minus.svg"> {{ $t('removeMeasure') }}</button>
      </div>
      <edit-heating-measure
        :heaters-results="nextState && nextState.result && nextState.result.heaters"
        :portfolio="portfolio"
        v-model="model.actions.heating"
        :edit="isEditing"
        @input="onHeatingChange"
      />
    </div>

    <!-- PV -->
    <div v-if="model.actions.pv !== null" class="measure-box">
      <div class="title-grid">
        <h2>{{ $t('pvMeasureTitle') }}</h2>
        <button class="button" @click="removePvMeasure" v-if="isEditing"><img class="icon" src="/icons/minus.svg"> {{ $t('removeMeasure') }}</button>
      </div>
      <edit-pv-measure
        v-model="model.actions.pv"
        :edit="isEditing"
        @input="onPvChange"
        :pv-results="nextState && nextState.result && nextState.result.pv"
      />
    </div>

    <!-- ZONES -->
    <div v-if="model.actions.zones !== null" class="measure-box">
      <div class="title-grid">
        <h2>{{ $t('zoneMeasureTitle') }}</h2>
        <button class="button" @click="removeZonesMeasure" v-if="isEditing"><img class="icon" src="/icons/minus.svg"> {{ $t('removeMeasure') }}</button>
      </div>
      <edit-zones-table
        :edit="isEditing"
        v-model="model.actions.zones.zones"
        @input="onZonesChange"
        :zone-results="nextState && nextState.result && nextState.result.zones"
      />
    </div>
  </edit-container>
</template>

<script>
import { mapActions } from 'vuex'
import ApiFormMixin from '@/components/shared/forms/ApiFormMixin'
import EditContainer from '@/components/shared/forms/EditContainer'

import FormRow from '@/components/shared/forms/FormRow'
import TextInput from '@/components/shared/forms/TextInput'
import NumericInput from '@/components/shared/forms/NumericInput'
import EditZonesTable from '@/components/building/edit/EditZonesTable'
import EditPvMeasure from '@/components/building/measures/edit/EditPvMeasure'
import EditHeatingMeasure from '@/components/building/measures/edit/EditHeatingMeasure'
import EditEnvelopeMeasure from '@/components/building/measures/edit/EditEnvelopeMeasure'

export default {
  mixins: [
    ApiFormMixin
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation */
  ],
  props: {
    building: { // create new measure for this building
      type: Object,
      required: true
    },
    measure: {
      type: Object,
      required: false
    },
    portfolio: {
      type: Object,
      required: true
    }
  },
  watch: {
    building () {
      this.resetModel()
    }
  },
  computed: {
    nextState () {
      return this.measure && this.measure.nextState
    },
    hideEnvelopeMeasures () {
      return this.building.initial_state.envelope.without_heating || this.building.initial_state.envelope.manual_rh_demand
    },
    noSelectedMeasures () {
      return this.model.actions.pv === null && this.model.actions.heating === null && this.model.actions.envelope === null && this.model.actions.zones === null
    },
    valid () {
      // At least one measure must be selected
      if (this.noSelectedMeasures) {
        return false
      }
      if (this.model.actions.heating !== null && (this.totalRhShare !== 100 || this.totalDhwShare !== 100)) {
        return false
      }
      // If envelope renovation is selected, at least one component must be renovated
      if (this.model.actions.envelope !== null && !(this.model.actions.envelope.walls !== null || this.model.actions.envelope.windows !== null || this.model.actions.envelope.roof !== null || this.model.actions.envelope.basement !== null)) {
        return false
      }
      // Heating type must be selected
      if (this.model.actions.heating !== null && this.model.actions.heating.heaters.length === 0) {
        return false
      }
      // At least one zone must be defined
      if (this.model.actions.zones !== null && this.model.actions.zones.zones.length === 0) {
        return false
      }
      return true
    },
    maxYear () {
      if (this.model.phase === 'PLANNED') {
        return 2100
      } else {
        return new Date().getFullYear()
      }
    },
    totalRhShare () {
      return this.model.actions.heating && this.model.actions.heating.heaters.reduce((pv, cv) => pv + cv.rh_share, 0)
    },
    totalDhwShare () {
      return this.model.actions.heating && this.model.actions.heating.heaters.reduce((pv, cv) => pv + cv.dhw_share, 0)
    }
  },
  created: function () {
    if (!this.model.id) {
      // Start editing immediately when adding a new measure
      this.onFormEvent('edit')
    }
    this.resetModel()
  },
  methods: {
    ...mapActions({
      addMeasure: 'building/addMeasure',
      updateMeasure: 'building/updateMeasure'
    }),
    resetModel () {
      if (this.measure && this.measure.phase === 'SCENARIO') {
        // Create planned measure when editing a scenario measure
        this.model = {
          building_id: this.measure.building_id,
          year: this.measure.year,
          phase: 'PLANNED',
          actions: JSON.parse(JSON.stringify(this.measure.actions))
        }
      } else if (this.measure) {
        this.model = {
          id: this.measure.id,
          building_id: this.measure.building_id,
          year: this.measure.year,
          phase: this.measure.phase,
          remarks: this.measure.remarks,
          actions: JSON.parse(JSON.stringify(this.measure.actions))
        }
      } else {
        this.model = {
          building_id: this.building.id,
          year: new Date().getFullYear(),
          phase: 'PLANNED',
          remarks: null,
          actions: {
            envelope: null,
            pv: null,
            heating: null,
            zones: null
          }
        }
      }
    },
    async saveHandler (model) {
      if (model.actions.pv !== null) {
        if (model.actions.pv.method === 'MANUAL') {
          delete model.actions.pv.dimensioning
        } else {
          delete model.actions.pv.sections
        }
      }
      if (this.measure && this.measure.phase === 'SCENARIO') {
        // Create new measure based on scenario measure
        await this.addMeasure(model)
        this.$emit('created')
      } else if (this.measure) {
        // Update existing measure
        await this.updateMeasure(model)
        this.resetModel()
      } else {
        // Create new measure
        await this.addMeasure(model)
        this.$emit('created')
      }
    },
    onZonesChange (zones) {
      this.model.actions.zones.zones = zones
    },
    addZonesMeasure () {
      this.model.actions.zones = JSON.parse(JSON.stringify(this.building.initial_state.zones))
    },
    removeZonesMeasure () {
      this.model.actions.zones = null
    },
    onEnvelopeChange (envelope) {
      this.model.actions.envelope = envelope
    },
    addEnvelopeMeasure () {
      this.model.actions.envelope = {
        walls: { standard: 'MuKEn_2014_RENOVATION', 'costs': null },
        windows: { standard: 'MuKEn_2014', 'costs': null },
        roof: { standard: 'MuKEn_2014_RENOVATION', 'costs': null },
        basement: { standard: 'MuKEn_2014_RENOVATION', 'costs': null }
      }
    },
    removeEnvelopeMeasure () {
      this.model.actions.envelope = null
    },
    onPvChange (pv) {
      this.model.actions.pv = pv
    },
    addPvMeasure () {
      this.model.actions.pv = {
        method: 'AUTO',
        dimensioning: null,
        sections: null,
        costs: null,
        subsidies: null
      }
    },
    removePvMeasure () {
      this.model.actions.pv = null
    },
    onHeatingChange (heating) {
      this.model.actions.heating = heating
    },
    addHeatingMeasure () {
      this.model.actions.heating = {
        heaters: [{
          type: 'UNKNOWN',
          rh_share: 100,
          dhw_share: 100
        }],
        costs: null
      }
    },
    removeHeatingMeasure () {
      this.model.actions.heating = null
    }
  },
  components: {
    EditContainer,
    EditZonesTable,
    EditPvMeasure,
    EditHeatingMeasure,
    EditEnvelopeMeasure,
    TextInput,
    NumericInput,
    FormRow
  }
}
</script>
<style scoped>
.measure-box {
  margin-top: var(--spacing-s);
  padding: var(--spacing-m);
  background-color: var(--background-color);
  border: var(--box-border);
  border-radius: var(--box-radius);
}
.title-grid {
  display: grid;
  grid-template-columns: 1fr max-content;
  margin-bottom: var(--spacing-s);
}

.year-input {
  width: 100px;
}
.measure-phase img {
  vertical-align: bottom;
  margin-right: var(--spacing-s);
  opacity: 0.7;
}
</style>
