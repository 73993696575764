<i18n>
{
  "de": {
    "descriptionLabel": "Bezeichnung",
    "identifierLabel": "Eindeutige Liegenschaftsnr.",
    "remarksLabel": "Bemerkungen"
  }
}
</i18n>

<template>
  <edit-container :bind-form-state="formState" @container="onFormEvent">
    <form-row :label="$t('identifierLabel')">
      <text-input :min-length="1" v-model="model.identifier" :edit="isEditing" @validation="onValidation($event, 'identifier')" />
    </form-row>
    <form-row :label="$t('descriptionLabel')">
      <text-input :min-length="1" v-model="model.description" :edit="isEditing" @validation="onValidation($event, 'description')" />
    </form-row>
    <form-row :label="$t('remarksLabel')">
      <textarea class="remarks-input" v-if="isEditing" v-model="model.remarks" />
      <div class="remarks-text" v-else>{{ model.remarks }}</div>
    </form-row>
  </edit-container>
</template>
<script>
import { mapActions } from 'vuex'
import ApiFormMixin from '@/components/shared/forms/ApiFormMixin'
import EditContainer from '@/components/shared/forms/EditContainer'

import TextInput from '@/components/shared/forms/TextInput'
import FormRow from '@/components/shared/forms/FormRow'

export default {
  mixins: [
    ApiFormMixin
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation */
  ],
  components: {
    EditContainer,
    TextInput,
    FormRow
  },
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  watch: {
    building () {
      this.resetModel()
    }
  },
  methods: {
    ...mapActions({
      updateBuilding: 'building/updateBuilding'
    }),
    resetModel () {
      this.model = {
        'description': this.building.description || '',
        'identifier': this.building.identifier || '',
        'remarks': this.building.remarks || ''
      }
    },
    async saveHandler (model) {
      await this.updateBuilding({
        'id': this.building.id,
        'description': model.description,
        'identifier': model.identifier,
        'remarks': model.remarks
      })
    }
  }
}
</script>

<style scoped>
.remarks-text {
  white-space: pre-wrap;
}
.remarks-input {
  width: 100%;
}
</style>
