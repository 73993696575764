<i18n>
{
  "de": {
    "utilityTitle": "Elektrizitätsversorgung",
    "noDataMessage": "Keine Daten verfügbar",
    "feedinTarifsTitle": "Rückliefertarife",
    "gridTarifsTitle": "Stromtarife",
    "tarifCategoryTitle": "BFE-Verbraucherprofil",
    "gridTarifTitle": "Netznutzung",
    "energyTarifTitle": "Energie",
    "taxesTarifTitle": "Steuern",
    "kevTarifTitle": "KEV",
    "totalTitle": "Total",
    "downloadSheetButton": "Tarifblatt herunterladen",
    "powerTitle": "Installierte PV-Leistung",
    "energyTitle": "Vergütung Energie",
    "ecoTitle": "Vergütung Herkunftsnachweise (HKN)",
    "totalTitle": "Vergütung total",
    "powerFromLabel": "ab",
    "pvtarifSource": "Quelle Rücklieferdaten: VESE / pvtarif.ch",
    "pvtarifYear": "Stand",
    "elcomSource": "Quelle Stromtarife: Elektrizitätskommission (ElCom)"
  }
}
</i18n>

<template>
  <modal @close="$emit('close', $event)" wide class="electricity-utility-modal">
    <template v-slot:header>
      <h2>{{ building.el_utility.name }}</h2>
    </template>
    <button-wrapper v-if="building.el_utility.tarif_sheet_urls !== null && building.el_utility.tarif_sheet_urls.length > 0">
      <a target="_blank" class="button" :href="url" v-for="(url, idx) in building.el_utility.tarif_sheet_urls" v-bind:key="idx">{{ $t('downloadSheetButton') }} <span v-if="building.el_utility.tarif_sheet_urls.length > 1">{{ idx + 1 }}</span></a>
    </button-wrapper>
    <h3>{{ $t('feedinTarifsTitle') }}</h3>
    <div v-if="building.el_utility.feedin_tarifs !== null && building.el_utility.feedin_tarifs.length > 0">
      <detail-list class="feedin-tarifs-table">
        <template #header>
          <span>{{ $t('powerTitle') }}</span>
          <span>{{ $t('energyTitle') }}</span>
          <span>{{ $t('ecoTitle') }}</span>
          <span>{{ $t('totalTitle') }}</span>
        </template>
        <li v-for="(tarif, idx) in building.el_utility.feedin_tarifs" :key="idx">
          <span>{{ $t('powerFromLabel') }} {{ tarif.power }} kWp</span>
          <span>{{ formatNumber(tarif.energy, 1) }} Rp./kWh</span>
          <span>{{ formatNumber(tarif.eco, 1) }} Rp./kWh</span>
          <span>{{ formatNumber(tarif.energy + tarif.eco, 1) }} Rp./kWh</span>
        </li>
      </detail-list>
      <br />
      <a href="http://www.pvtarif.ch" target="_blank">{{ $t('pvtarifSource') }}</a> ({{ $t('pvtarifYear') }} {{ building.el_utility.feedin_tarifs_year }})
    </div>
    <div v-else>{{ $t('noDataMessage') }}</div>
    <h3>{{ $t('gridTarifsTitle') }}</h3>
    <div v-if="building.el_utility.grid_tarifs !== null && building.el_utility.grid_tarifs.length > 0">
      <detail-list class="grid-tarifs-table">
        <template #header>
          <span>{{ $t('tarifCategoryTitle') }}</span>
          <span>{{ $t('gridTarifTitle') }}</span>
          <span>{{ $t('energyTarifTitle') }}</span>
          <span>{{ $t('taxesTarifTitle') }}</span>
          <span>{{ $t('kevTarifTitle') }}</span>
          <span>{{ $t('totalTitle') }}</span>
        </template>
        <li v-for="(tarif, idx) in building.el_utility.grid_tarifs" :key="idx">
          <span>{{ tarif.category }}</span>
          <span>{{ formatNumber(tarif.grid_tarif, 1) }} Rp./kWh</span>
          <span>{{ formatNumber(tarif.energy_tarif, 1) }} Rp./kWh</span>
          <span>{{ formatNumber(tarif.taxes, 1) }} Rp./kWh</span>
          <span>{{ formatNumber(tarif.kev, 1) }} Rp./kWh</span>
          <span>{{ formatNumber(tarif.total, 1) }} Rp./kWh</span>
        </li>
      </detail-list>
      <br />
      <a href="https://www.strompreis.elcom.admin.ch/" target="_blank">{{ $t('elcomSource') }}</a> ({{ $t('pvtarifYear') }} {{ building.el_utility.grid_tarifs_year }})
    </div>
    <div v-else>{{ $t('noDataMessage') }}</div>
  </modal>
</template>

<script>
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import Modal from '@/components/shared/Modal'
import DetailList from '@/components/shared/lists/DetailList'

export default {
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  components: {
    ButtonWrapper,
    Modal,
    DetailList
  }
}
</script>
