<i18n>
{
  "de": {
    "header": "Stromproduktion",
    "ownUse": "Eigenverbrauch"
  }
}
</i18n>

<template>
  <detail-list-item :title="$t('header')" expandable @expand="onModalOpen">
    <div class="three-columns" v-if="building.kpi.el_production > 0">
      <span>{{ formatNumber(building.kpi.el_production / 1000, 1) }} MWh</span>
      <span></span>
      <span>{{ $t('ownUse') }}: {{ formatNumber(building.kpi.el_ownuse / building.kpi.el_production * 100, 0) }} %</span>
    </div>
    <div v-else>-</div>
    <template #modal>
      <electricity-production-modal v-if="modalOpen" @close="onModalClose" :building="building" :portfolio="portfolio" />
    </template>
  </detail-list-item>
</template>

<script>
import DetailListItem from '@/components/shared/lists/DetailListItem'
import ElectricityProductionModal from '@/components/building/kpi/ElectricityProductionModal'

export default {
  data: function () {
    return {
      modalOpen: false
    }
  },
  props: {
    portfolio: {
      type: Object
    },
    building: {
      type: Object,
      required: true
    }
  },
  methods: {
    onModalOpen () {
      this.modalOpen = true
    },
    onModalClose () {
      this.modalOpen = false
    }
  },
  components: {
    DetailListItem,
    ElectricityProductionModal
  }
}
</script>
