<i18n>
{
  "de": {
    "heatingReplacement": "Heizungsersatz",
    "pvInstallation": "Photovoltaikanlage",
    "changeZones": "Änderung von Nutzung und Flächen",
    "windowsReplacement": "Fensterersatz",
    "wallsRenovation": "Sanierung Fassade",
    "roofRenovation": "Sanierung Dach",
    "basementRenovation": "Sanierung Kellerdecke"
  }
}
</i18n>
<template>
  <span class="measure-summary">
    <div v-if="measure.actions.zones !== null" class="partial-measure">
      <div>{{ $t('changeZones') }}</div>
      <div></div>
    </div>
    <div v-if="measure.actions.pv !== null && measure.next_state.pv.sections.length > 0" class="partial-measure">
      <div>{{ $t('pvInstallation') }} {{ formatNumber(pvCapacity, 0) }} kW</div>
      <div>{{ formatNumber(measure.costs.pv.costs, -3) }} CHF</div>
    </div>
    <div v-if="measure.actions.heating !== null" class="partial-measure">
      <div>{{ $t('heatingReplacement') }} {{ $t(`_heatingTypes.${measure.actions.heating.heaters[0].type}`) }}</div>
      <div>{{ formatNumber(measure.costs.heating.costs, -3) }} CHF</div>
    </div>
    <div v-if="measure.actions.envelope !== null && measure.actions.envelope.windows !== null && measure.actions.envelope.windows.standard !== 'NONE'" class="partial-measure">
      <div>{{ $t('windowsReplacement') }}</div>
      <div>{{ formatNumber(measure.costs.windows.costs, -3) }} CHF</div>
    </div>
    <div v-if="measure.actions.envelope !== null && measure.actions.envelope.walls !== null && measure.actions.envelope.walls.standard !== 'NONE'" class="partial-measure">
      <div>{{ $t('wallsRenovation') }}</div>
      <div>{{ formatNumber(measure.costs.walls.costs, -3) }} CHF</div>
    </div>
    <div v-if="measure.actions.envelope !== null && measure.actions.envelope.roof !== null && measure.actions.envelope.roof.standard !== 'NONE'" class="partial-measure">
      <div>{{ $t('roofRenovation') }}</div>
      <div>{{ formatNumber(measure.costs.roof.costs, -3) }} CHF</div>
    </div>
    <div v-if="measure.actions.envelope !== null && measure.actions.envelope.basement !== null && measure.actions.envelope.basement.standard !== 'NONE'" class="partial-measure">
      <div>{{ $t('basementRenovation') }}</div>
      <div>{{ formatNumber(measure.costs.basement.costs, -3) }} CHF</div>
    </div>
  </span>
</template>

<script>

export default {
  props: {
    measure: {
      type: Object,
      required: true
    }
  },
  computed: {
    pvCapacity () {
      return this.measure.pv_sections.reduce((pv, cv) => pv + cv.capacity, 0)
    }
  }
}
</script>

<style>
.measure-summary {
  display: grid;
  gap: var(--spacing-s);
  grid-template-columns: 1fr 1fr;
}
.measure-summary .partial-measure {
  display: contents;
}
.measure-summary .measure-remarks {
  font-size: var(--font-xs);
}
</style>
