<!--
Edit form for HeaterItemSchema (many=true)
-->
<i18n>
{
  "de": {
    "heatingTypeTitle": "Typ",
    "rhShareTitle": "Anteil Raumwärme",
    "dhwShareTitle": "Anteil Warmwasser",
    "energyCalibration": "Modellkalibrierung",
    "addHeatingButton": "Wärmeerzeuger hinzufügen",
    "emissionFactorTitle": "Emissionsfaktor",
    "peFactorTitle": "Primärenergiefaktor",
    "factorSourceDefault": "Standardwert",
    "factorSourceEnergyGrid": "Quelle: GIS-Daten",
    "heatGridDefaultText": "Fernwärmenetz",
    "heatGridGis": "Quelle: GIS-Daten",
    "heatGridCustom": "Quelle: Eigenes Netz",
    "noHeatingDefined": "Keine Wärmeerzeuger",
    "endEnergyTitle": "Modellwert",
    "yearsLabel": "Jahre",
    "measuredTitle": "Messwert",
    "totalRhShareWarning": "Summe Anteil Raumwärme muss exakt 100% betragen!",
    "totalDhwShareWarning": "Summe Anteil Warmwasser muss exakt 100% betragen!",
    "total": "Total",
    "selectHeatGrid": "Auswahl Wärmenetz",
    "selectGridInfo": {
      "title": "Auswahl Wärmenetz",
      "text": "Wählen Sie ein selber definiertes Wärmenetz aus (siehe Einstellungen > Wärmenetze)."
    },
    "calibrationMethods": {
      "MANUAL": "Manuell",
      "INHERITED": "Vererbt",
      "MEASUREMENTS": "Messdaten",
      "NONE": "Keine Kalibrierung"
    },
    "highCalibrationFactorWarningTitle": "Effektiver Energieverbrauch höher als erwartet",
    "highCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich über dem modellierten Energiebedarf.<br /><br />Mögliche Ursachen:<li>Erfasste Sanierungen an der Gebäudehülle wurden nur teilweise oder nur oberflächlich und ohne Dämmung durchgeführt</li><li>Der Wärmeerzeuger liefert Wärme an weitere nicht erfasste Gebäude</li><li>Es befinden sich zusätzliche Nutzungen mit hohem Warmwasserverbrauch in der Liegenschaft (z.B. Gastronomie)</li><li>Falls mehrere Wärmeerzeuger vorhanden sind, entsprechen die angegebenen Anteile Raumwärme und Warmwasser nicht der Realität</li>",
    "lowCalibrationFactorWarningTitle": "Effektiver Energieverbrauch tiefer als erwartet",
    "lowCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich unter dem modellierten Energiebedarf.<br /><br />Mögliche Ursachen:<li>Es wurden zusätzliche Sanierungen an der Gebäudehülle durchgeführt aber nicht erfasst</li><li>Als der Kalibrierungswert erhoben wurde, gab es einen wesentlichen Leerstand</li><li>Es existieren zusätzliche Wärmeerzeuger, die nicht erfasst wurden (z.B. elektrische Warmwasserboiler)</li><li>Der Kalibrierungswert ist fehlerhaft oder unvollständig (z.B. nur eine Teilperiode)</li><li>Falls mehrere Wärmeerzeuger vorhanden sind, entsprechen die angegebenen Anteile Raumwärme und Warmwasser nicht der Realität</li>",
    "rhShareInfoBoxTitle": "Anteil Raumwärme",
    "rhShareInfoBox": "Anteil der Raumwärme, der durch diesen Wärmeerzeuger bereitgestellt wird.",
    "dhwShareInfoBoxTitle": "Anteil Warmwasser",
    "dhwShareInfoBox": "Anteil des Warmwassers, der durch diesen Wärmeerzeuger bereitgestellt wird.",
    "ghgFactorInfoBoxTitle": "GHG-Emissionsfaktor",
    "ghgFactorInfoBox": "GHG-Emissionen pro verbrauchte Endenergiemenge (netto gelieferte Energie, z.B. Brennwert von Heizöl). Wenn dieses Feld leergelassen wird, werden die Standardwerte aus den Portfolioeinstellungen verwendet. Für Wärmepumpen und Elektroheizungen wird der Strommix und der Anteil Photovoltaikstrom berücksichtigt.",
    "peFactorInfoBoxTitle": "Primärenergiefaktor",
    "peFactorInfoBox": "Verbrauchte Primärenergie pro verbrauchte Endenergiemenge (netto gelieferte Energie, z.B. Brennwert von Heizöl). Wenn dieses Feld leergelassen wird, werden die Standardwerte aus den Portfolioeinstellungen verwendet. Für Wärmepumpen und Elektroheizungen wird der Strommix und der Anteil Photovoltaikstrom berücksichtigt.",
    "endEnergyInfoBoxTitle": "Modellierter Endenergiebedarf (ohne Kalibrierung)",
    "endEnergyInfoBox": "Berechneter Endenergiebedarf dieses Wärmeerzeugers (netto gelieferte Energie, z.B. Brennwert von Heizöl). Der Kalibrierungsfaktor wird hier nicht berücksichtigt.",
    "measuredInfoBoxTitle": "Gemessener Endenergiebedarf (witterungsbereinigter Durchschnittswert)",
    "measuredInfoBox": "Gemessener Endenergiebedarf dieses Wärmeerzeugers (netto gelieferte Energie, z.B. Brennwert von Heizöl).",
    "calibrationInfoBoxTitle": "Kalibrierung Endenergie",
    "calibrationInfoBox": "Effektiver Endenergieverbrauch dieses Wärmeerzeugers (netto gelieferte Energie, z.B. Brennwert von Heizöl). Mit diesem wird ein Kalibrierungsfaktor berechnet und damit die modellierte Nutzenergie (Raumwärme- und Warmwasserbedarf) sowohl des aktuellen Gebäudezustandes als auch des künftigen Verbrauchs korrigiert. Idealerweise wird ein langjähriger witterungsbereinigter Mittelwert verwendet, welcher den aktuellen Gebäudezustand repräsentiert und keine Leerstände in der Nutzung aufweist."
  }
}
</i18n>

<template>
  <div class="edit-heaters-table">
    <detail-list class="heating-table" v-if="model.length > 0">
      <template #header>
        <span>{{ $t('heatingTypeTitle') }}</span>
        <span class="align-right">{{ $t('rhShareTitle') }} <info-box :title="$t('rhShareInfoBoxTitle')">{{ $t('rhShareInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('dhwShareTitle') }} <info-box :title="$t('dhwShareInfoBoxTitle')">{{ $t('dhwShareInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('measuredTitle') }} <info-box :title="$t('measuredInfoBoxTitle')">{{ $t('measuredInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('endEnergyTitle') }} <info-box :title="$t('endEnergyInfoBoxTitle')">{{ $t('endEnergyInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('energyCalibration') }} <info-box :title="$t('calibrationInfoBoxTitle')">{{ $t('calibrationInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('emissionFactorTitle') }} <info-box :title="$t('ghgFactorInfoBoxTitle')">{{ $t('ghgFactorInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('peFactorTitle') }} <info-box :title="$t('peFactorInfoBoxTitle')">{{ $t('peFactorInfoBox') }}</info-box></span>
        <span v-if="isEditing"></span>
      </template>
      <li v-for="(h, index) in model" :key="index">
        <div>
          <heating-type-picker v-model="h.type" :edit="isEditing" @input="onInput" />
          <div v-if="h.type === 'LOCAL'">
            <div v-if="gridOptions && gridOptions.length > 0">
              <div class="select-grid-title" v-if="isEditing">{{ $t('selectHeatGrid') }} <info-box :title="$t('selectGridInfo.title')">{{ $t('selectGridInfo.text') }}</info-box></div>
              <heat-grid-picker :grid-options="gridOptions" v-model="h.heat_grid_identifier" :edit="isEditing" @input="onInput" />
            </div>
          </div>
          <div v-if="!isEditing && heatersResults && heatersResults[index] && heatersResults[index].energy_grid && heatersResults[index].energy_grid.source === 'GIS_ENERGY_GRID'">
            <div v-if="heatersResults[index].energy_grid.source !== 'DEFAULT'">
              {{ heatersResults[index].energy_grid.name }} <span v-if="heatersResults[index].energy_grid.operator">({{ heatersResults[index].energy_grid.operator }})</span>
            </div>
            <div class="source-info">{{ $t('heatGridGis') }}</div>
          </div>
        </div>

        <div class="align-right">
          <numeric-input @input="onInput" v-model="h.rh_share" :min="0" :max="100" :edit="isEditing" :units="'%'" />
        </div>
        <div class="align-right">
          <numeric-input @input="onInput" v-model="h.dhw_share" :min="0" :max="100" :edit="isEditing" :units="'%'" />
        </div>
        <div class="align-right">
          <span v-if="getMeasuredEnergy(index)">
            {{ formatNumber(getMeasuredEnergy(index).value / 1000, 0) }} MWh
            <div class="source-info">{{ getMeasuredEnergy(index).years }} {{ $t('yearsLabel') }}</div>
          </span>
          <span v-else>-</span>
        </div>
        <div class="align-right">
          <span v-if="getEndEnergy(index)">
            {{ formatNumber(getEndEnergy(index) / 1000, 0) }} MWh
          </span>
          <span v-else>-</span>
        </div>
        <div class="align-right">
          <numeric-input @input="onInput" v-model="h.energy_calibration" optional :min="0" :edit="isEditing" v-if="isEditing" :units="'MWh'" />
          <div v-else>
            <span v-if="getCalibrationFactor(index)">
              <div v-if="getCalibrationFactor(index).method != 'NONE'">
                <div v-if="getCalibrationFactor(index).value >= 1.0">
                  <data-warning v-if="getCalibrationFactor(index).value > 1.667" :title="$t('highCalibrationFactorWarningTitle')"><span v-html="$t('highCalibrationFactorWarning')" /></data-warning>
                  +{{ formatNumber(getCalibrationFactor(index).value * 100 - 100, 1) }} %
                </div>
                <div v-else>
                  <data-warning v-if="getCalibrationFactor(index).value < 0.6" :title="$t('lowCalibrationFactorWarningTitle')"><span v-html="$t('lowCalibrationFactorWarning')" /></data-warning>
                  {{ formatNumber(getCalibrationFactor(index).value * 100 - 100, 1) }} %
                </div>
              </div>
              <div v-else>-</div>
              <div class="source-info">
                {{ $t(`calibrationMethods.${getCalibrationFactor(index).method}`) }}
              </div>
            </span>
          </div>
        </div>
        <div class="align-right">
          <!-- ghg_factor -->
          <numeric-input v-if="isEditing && h.type === 'DISTRICT'" @input="onInput" optional v-model="h.ghg_factor" :min="0" :edit="isEditing" :units="'kg/kWh'" />
          <div v-else-if="!isEditing && heatersResults && heatersResults[index]">
            {{ formatNumber(heatersResults[index].kpi_factors.ghg_factor, 3) }} kg/kWh
            <div class="source-info" v-if="heatersResults[index].kpi_factors.source === 'DEFAULT'">{{ $t('factorSourceDefault')}}</div>
            <div class="source-info" v-if="heatersResults[index].kpi_factors.source === 'GIS_ENERGY_GRID'">{{ $t('factorSourceEnergyGrid')}}</div>
            <div class="source-info" v-if="heatersResults[index].kpi_factors.source === 'HEAT_GRID'">{{ $t('heatGridCustom') }}</div>
          </div>
          <span v-else-if="!isEditing && h.ghg_factor !== null">{{ h.ghg_factor }} kg/kWh</span>
          <div v-else>-</div>
        </div>
        <div class="align-right">
          <!-- pe_factor -->
          <numeric-input v-if="isEditing && h.type === 'DISTRICT'" @input="onInput" optional v-model="h.pe_factor" :min="0" :edit="isEditing" :units="''" />
          <div v-else-if="!isEditing && heatersResults && heatersResults[index]">
            {{ formatNumber(heatersResults[index].kpi_factors.pe_factor, 3) }}
            <div class="source-info" v-if="heatersResults[index].kpi_factors.source === 'DEFAULT'">{{ $t('factorSourceDefault')}}</div>
            <div class="source-info" v-if="heatersResults[index].kpi_factors.source === 'GIS_ENERGY_GRID'">{{ $t('factorSourceEnergyGrid')}}</div>
            <div class="source-info" v-if="heatersResults[index].kpi_factors.source === 'HEAT_GRID'">{{ $t('heatGridCustom') }}</div>
          </div>
          <span v-else-if="!isEditing && h.pe_factor !== null">{{ h.pe_factor }}</span>
          <div v-else>-</div>
        </div>
        <list-button-wrapper>
          <list-delete-button v-if="isEditing" @click="onDeleteHeating(index)" />
        </list-button-wrapper>
      </li>
      <template #footer>
        <span>{{ $t('total') }}</span>
        <span class="align-right">{{ totalRhShare}} %</span>
        <span class="align-right">{{ totalDhwShare}} %</span>
        <span class="align-right" v-if="totalMeasuredEnergy">{{ formatNumber(totalMeasuredEnergy / 1000, 0) }} MWh</span>
        <span class="align-right" v-else>-</span>
        <span class="align-right" v-if="totalEndEnergy">{{ formatNumber(totalEndEnergy / 1000, 0) }} MWh</span>
        <span class="align-right" v-else>-</span>
        <span class="align-right" v-if="totalCalibration">{{ formatNumber(totalCalibration, 0) }} MWh</span>
        <span></span>
        <span></span>
      </template>
    </detail-list>
    <div v-else>
      {{ $t('noHeatingDefined') }}
    </div>
    <div class="sum-warning" v-if="model.length > 0 && totalRhShare !== 100">
      <p>{{ $t('totalRhShareWarning') }}</p>
    </div>
    <div class="sum-warning" v-if="model.length > 0 && totalDhwShare !== 100">
      <p>{{ $t('totalDhwShareWarning') }}</p>
    </div>
    <button-wrapper>
      <button class="button" @click.prevent="onAddHeating" v-if="isEditing">{{ $t('addHeatingButton') }}</button>
    </button-wrapper>
  </div>
</template>
<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton'
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import HeatingTypePicker from '@/components/building/edit/HeatingTypePicker'
import HeatGridPicker from '@/components/building/edit/HeatGridPicker'
import DetailList from '@/components/shared/lists/DetailList'
import NumericInput from '@/components/shared/forms/NumericInput'
import DataWarning from '@/components/shared/DataWarning'
import InfoBox from '@/components/shared/InfoBox'

export default {
  props: {
    heatersResults: {
      type: Array,
      required: false
    },
    portfolio: {
      type: Object
    }
  },
  mixins: [ComplexFormMixin],
  computed: {
    // Overrides mixin function
    isValid () {
      return this.noInvalidFields && (this.model.length === 0 || (this.totalRhShare === 100 && this.totalDhwShare === 100))
    },
    totalRhShare () {
      return this.model.reduce((pv, cv) => pv + cv.rh_share, 0)
    },
    totalDhwShare () {
      return this.model.reduce((pv, cv) => pv + cv.dhw_share, 0)
    },
    totalEndEnergy () {
      if (this.heatersResults) {
        return this.heatersResults.reduce((pv, cv) => pv + cv.rh_end_energy + cv.dhw_end_energy, 0)
      }
      return undefined
    },
    totalMeasuredEnergy () {
      if (this.heatersResults) {
        return this.heatersResults.reduce((pv, cv) => pv + (cv.measured_end_energy !== null ? cv.measured_end_energy.value : 0), 0)
      }
      return undefined
    },
    totalCalibration () {
      return this.model.reduce((pv, cv) => pv + cv.energy_calibration, 0)
    },
    gridOptions () {
      return this.portfolio && this.portfolio.heat_grids.map(g => {
        return {
          id: g.identifier,
          label: g.name
        }
      })
    }
  },
  methods: {
    getCalibrationFactor (heaterIdx) {
      if (this.heatersResults && this.heatersResults[heaterIdx] !== undefined && this.heatersResults[heaterIdx].calibration_factor !== null) {
        return this.heatersResults[heaterIdx].calibration_factor
      }
      return undefined
    },
    getEndEnergy (heaterIdx) {
      if (this.heatersResults && this.heatersResults[heaterIdx] !== undefined && this.heatersResults[heaterIdx].rh_end_energy !== null) {
        return (this.heatersResults[heaterIdx].rh_end_energy + this.heatersResults[heaterIdx].dhw_end_energy)
      }
      return undefined
    },
    getMeasuredEnergy (heaterIdx) {
      if (this.heatersResults && this.heatersResults[heaterIdx] !== undefined && this.heatersResults[heaterIdx].measured_end_energy !== null) {
        return this.heatersResults[heaterIdx].measured_end_energy
      }
      return undefined
    },
    onAddHeating () {
      this.model = [...this.model, {
        'type': 'UNKNOWN',
        'heat_grid_identifier': null,
        'rh_share': this.model.length === 0 ? 100 : 0,
        'dhw_share': this.model.length === 0 ? 100 : 0,
        'ghg_factor': null,
        'pe_factor': null,
        'energy_calibration': null
      }]
      this.onInput()
    },
    onDeleteHeating (index) {
      this.model.splice(index, 1)
      this.onInput()
    }
  },
  components: {
    ListButtonWrapper,
    ListDeleteButton,
    ButtonWrapper,
    NumericInput,
    DetailList,
    InfoBox,
    DataWarning,
    HeatingTypePicker,
    HeatGridPicker
  }
}
</script>
<style>
.edit-heaters-table .detail-list > ul > li {
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 50px;
}
.edit-heaters-table .sum-warning {
  color: var(--warning-color);
  padding: var(--box-padding);
}
.edit-heaters-table .align-right {
  text-align: right;
}
.edit-heaters-table .source-info {
  color: var(--secondary-text-color);
  font-size: var(--font-xs);
}
.edit-heaters-table .select-grid-title {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-xxs);
  color: var(--secondary-highlight-color);
  font-size: var(--font-xs);
  font-weight: 600;
}
</style>
