<i18n>
{
  "de": {
    "total": "Total",
    "classTitle": "Eignungsklasse",
    "shareTitle": "Anteil",
    "roofAreaTitle": "Dachfläche",
    "fassadeAreaTitle": "Fassadenfläche",
    "irradiationTitle": "Einstrahlung"
  }
}
</i18n>

<template>
  <detail-list class="pv-potential-table">
    <template #header>
      <span>{{ $t('classTitle') }}</span>
      <!-- <span>{{ $t('shareTitle') }}</span> -->
      <span>{{ $t('roofAreaTitle') }}</span>
      <span>{{ $t('fassadeAreaTitle') }}</span>
      <span>{{ $t('irradiationTitle') }}</span>
    </template>
    <li v-for="c in areasByClass" :key="c.solar_class">
      <span :class="liClasses(c.solar_class)">{{ $t(`_solarPotential.${c.solar_class}`) }}</span>
    <!--   <span v-if="c.area">{{ formatNumber(c.area / totalArea * 100, 0) }} %</span>
      <span v-else>–</span> -->
      <span v-if="c.roofArea">{{ formatNumber(c.roofArea, 0) }} m<sup>2</sup></span>
      <span v-else>–</span>
      <span v-if="c.fassadeArea">{{ formatNumber(c.fassadeArea, 0) }} m<sup>2</sup></span>
      <span v-else>–</span>
      <span v-if="c.irradiation">{{ formatNumber(c.irradiation, 0) }} MWh</span>
      <span v-else>–</span>
    </li>
    <template #footer>
      <span>{{ $t('total') }}</span>
      <!-- <span v-if="totalArea">100 %</span>
      <span v-else>–</span> -->
      <span v-if="totalRoofArea">{{ formatNumber(totalRoofArea, 0) }} m<sup>2</sup></span>
      <span v-else>–</span>
      <span v-if="totalFassadeArea">{{ formatNumber(totalFassadeArea, 0) }} m<sup>2</sup></span>
      <span v-else>–</span>
      <span v-if="totalIrradiation">{{ formatNumber(totalIrradiation, 0) }} MWh</span>
      <span v-else>–</span>
    </template>
  </detail-list>
</template>

<script>
import DetailList from '@/components/shared/lists/DetailList'

export default {
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  computed: {
    areasByClass () {
      let result = []
      for (let solarClass = 5; solarClass >= 1; solarClass--) {
        const roofTiles = this.building.roof.roof_tiles.filter(t => t.solar_class === solarClass)
        const roofArea = roofTiles.reduce((pv, cv) => pv + cv.area, 0)
        const roofIrradiation = roofTiles.reduce((pv, cv) => pv + cv.area * cv.irradiation / 1000, 0)
        const fassadeTiles = this.building.roof.fassade_tiles.filter(t => t.solar_class === solarClass)
        const fassadeArea = fassadeTiles.reduce((pv, cv) => pv + cv.area, 0)
        const fassadeIrradiation = fassadeTiles.reduce((pv, cv) => pv + cv.area * cv.irradiation / 1000, 0)
        result.push({
          'solar_class': solarClass,
          'roofArea': roofArea,
          'fassadeArea': fassadeArea,
          'irradiation': roofIrradiation + fassadeIrradiation
        })
      }
      return result
    },
    totalRoofArea () {
      return this.areasByClass.reduce((pv, cv) => pv + cv.roofArea, 0)
    },
    totalFassadeArea () {
      return this.areasByClass.reduce((pv, cv) => pv + cv.fassadeArea, 0)
    },
    totalIrradiation () {
      return this.areasByClass.reduce((pv, cv) => pv + cv.irradiation, 0)
    }
  },
  methods: {
    liClasses (c) {
      const classes = {}
      classes[`ranking-${c}`] = true
      return classes
    }
  },
  components: {
    DetailList
  }
}
</script>

<style>
.pv-potential-table li span:first-child {
  grid-column: 1 / 3;
}

.pv-potential-table .ranking-1::before,
.pv-potential-table .ranking-2::before,
.pv-potential-table .ranking-3::before,
.pv-potential-table .ranking-4::before,
.pv-potential-table .ranking-5::before,
.pv-potential-table .ranking-small::before {
  display: inline-block;
  content: "";
  place-self: center;
  width: 1.3rem;
  height: .8rem;
  margin-right: 0.5rem;
  border: var(--box-border);
}

.pv-potential-table > dl > .ranking-total {
  padding-top: var(--spacing-m);
}

.pv-potential-table .ranking-5::before {
  background-color: var(--ranking-1-color);
}

.pv-potential-table .ranking-4::before {
  background-color: var(--ranking-2-color);
}

.pv-potential-table .ranking-3::before {
  background-color: var(--ranking-3-color);
}

.pv-potential-table .ranking-2::before {
  background-color: var(--ranking-4-color);
}

.pv-potential-table .ranking-1::before {
  background-color: var(--ranking-5-color);
}

.pv-potential-table .ranking-small::before {
  background-color: #aaaaaa;
}

</style>
