<i18n>
{
  "de": {
    "energyGridsTitle": "Wärmenetze und Wärmequellen",
    "reloadLabel": "Daten aktualisieren",
    "pendingMessage": "Daten werden abgefragt und Gebäude neu simuliert...",
    "lastUpdateLabel": "Aktualisiert am",
    "nameTitle": "Name",
    "detailsUrlLink": "Details",
    "heatSourceTitle": "Wärmequelle",
    "operatorTitle": "Betreiber",
    "ghgFactorTitle": "Emissionsfaktor",
    "peFactorTitle": "Energiefaktor",
    "statusTitle": "Status",
    "distanceTitle": "Distanz",
    "sourcesTitle": "Quellen",
    "noDataAvailable": "An diesem Standort sind keine Daten zu Wärmenetzen oder Wärmequellen verfügbar"
  }
}
</i18n>

<template>
    <modal @close="$emit('close', $event)" wide class="energy-grids-modal">
        <template v-slot:header>
            <h2>{{ $t('energyGridsTitle') }}</h2>
        </template>
        <div v-if="error">
          <p>{{ error }}</p>
        </div>
        <div v-if="pending">
          <p>{{ $t('pendingMessage') }}</p>
        </div>
        <div class="missing-data" v-else-if="building.energy_grids.state !== 'OK'">
          {{ $t(`_energyGridsState.${building.energy_grids.state}`) }}
        </div>
        <div v-else-if="building.energy_grids.energy_grids.length === 0 && building.energy_grids.energy_grids_simplified === 'UNKNOWN'">
          {{ $t('noDataAvailable') }}
        </div>
        <detail-list v-else class="energy-grids-list">
            <template #header>
                <span>{{ $t('nameTitle') }}</span>
                <span>{{ $t('operatorTitle') }}</span>
                <span>{{ $t('heatSourceTitle') }}</span>
                <span>{{ $t('ghgFactorTitle') }}</span>
                <span>{{ $t('peFactorTitle') }}</span>
                <span>{{ $t('statusTitle') }}</span>
                <span>{{ $t('distanceTitle') }}</span>
                <span></span>
            </template>
            <li v-for="(grid, index) in building.energy_grids.energy_grids" :key="index">
                <span>{{ grid.name }}</span>
                <span>{{ grid.operator }}</span>
                <span>{{ grid.heatsource }}</span>
                <span v-if="grid.ghg_factor !== null">{{ formatNumber(grid.ghg_factor, 1) }} g/kWh</span>
                <span v-else>-</span>
                <span v-if="grid.pe_factor !== null">{{ formatNumber(grid.pe_factor, 2) }}</span>
                <span v-else>-</span>
                <text-with-color :color="getColor(grid.status, grid.distance)">
                  <span v-if="grid.distance > 0">{{ $t(`_gridAvailability.${grid.status}_NEAR`) }}</span>
                  <span v-else>{{ $t(`_gridAvailability.${grid.status}`) }}</span>
                </text-with-color>
                <span>{{ (Math.ceil(grid.distance / 10) * 10) }} m</span>
                <a v-if="grid.detailsurl" target="_blank" :href="grid.detailsurl">{{ $t('detailsUrlLink') }}</a>
            </li>
        </detail-list>
        <div v-if="dataSources.lenght > 0" class="sources-list">
          {{ $t('sourcesTitle') }}:
          <div v-for="(source, index) in dataSources" v-bind:key="index">
            {{ source }}
          </div>
        </div>
        <button-wrapper v-if="!pending">
          <p v-if="building.energy_grids.state === 'OK'">{{ $t('lastUpdateLabel') }}: {{ lastUpdateDate }}</p>
          <button v-if="building.permissions.REFRESH_ENERGY_GRIDS" class="button" @click="onReload">{{ $t('reloadLabel') }}</button>
        </button-wrapper>
    </modal>
</template>

<script>
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import TextWithColor from '@/components/shared/TextWithColor'
import DetailList from '@/components/shared/lists/DetailList'
import Modal from '@/components/shared/Modal'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      'pending': null,
      'error': null
    }
  },
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  computed: {
    lastUpdateDate () {
      return new Date(this.building.energy_grids.last_update).toLocaleDateString('de-DE')
    },
    dataSources () {
      return new Set(this.building.energy_grids.energy_grids.map(g => g.data_source))
    }
  },
  methods: {
    ...mapActions({
      queryEnergyGrids: 'building/queryEnergyGrids'
    }),
    getColor (status, distance) {
      let colors = {
        'HEAT_SOURCE_NEAR': 'yellow',
        'HEAT_POTENTIAL_NEAR': 'yellow',
        'GAS_RETREAT': 'red',
        'EXISTING': 'green',
        'EXISTING_NEAR': 'yellow',
        'PLANNED': 'yellow',
        'PLANNED_NEAR': 'yellow',
        'EVALUATION': 'yellow',
        'EVALUATION_NEAR': 'yellow',
        'NONE': 'red',
        'ERROR': 'gray',
        'UNKNOWN': 'gray'
      }
      return colors[status + (distance > 0 ? '_NEAR' : '')]
    },
    async onReload () {
      this.pending = true
      try {
        await this.queryEnergyGrids({
          'id': this.building.id
        })
      } catch (error) {
        this.error = error
      }
      this.pending = false
    }
  },
  components: {
    ButtonWrapper,
    DetailList,
    TextWithColor,
    Modal
  }
}
</script>

<style scoped>
.missing-data {
  color:var(--warning-color);
}
.sources-list {
  margin-top: var(--spacing-m);
}
.sources-list > div {
  margin-left: var(--spacing-s);
}
</style>
<style>
.energy-grids-modal .detail-list > ul > li {
  grid-template-columns: 2fr 2fr 3fr 1fr 1fr 2fr 1fr 1fr;
}
</style>
