<template>
    <v-select v-model="model" @input="onInput" :options="zoneUsageOptions" :clearable="false" :reduce="t => t.id" />
</template>
<script>

export default {
  props: {
    value: {
      type: String
    }
  },
  data () {
    return {
      model: null,
      zoneUsageOptions: [
        { id: 'RESIDENTIAL', label: this.$t('_usageTypes.RESIDENTIAL') },
        { id: 'RESIDENTIAL_SFH', label: this.$t('_usageTypes.RESIDENTIAL_SFH') },
        { id: 'OFFICE', label: this.$t('_usageTypes.OFFICE') },
        { id: 'SCHOOLS', label: this.$t('_usageTypes.SCHOOLS') },
        { id: 'RETAIL', label: this.$t('_usageTypes.RETAIL') },
        { id: 'RESTAURANT', label: this.$t('_usageTypes.RESTAURANT') },
        { id: 'EVENT_BUILDING', label: this.$t('_usageTypes.EVENT_BUILDING') },
        { id: 'HOSPITAL', label: this.$t('_usageTypes.HOSPITAL') },
        { id: 'INDUSTRIAL', label: this.$t('_usageTypes.INDUSTRIAL') },
        { id: 'STORAGE', label: this.$t('_usageTypes.STORAGE') },
        { id: 'SPORTS', label: this.$t('_usageTypes.SPORTS') },
        { id: 'INDOOR_SWIMMING', label: this.$t('_usageTypes.INDOOR_SWIMMING') },
        { id: 'UNKNOWN', label: this.$t('_usageTypes.UNKNOWN') }
      ]
    }
  },
  watch: {
    value () {
      this.model = this.value
    }
  },
  created: function () {
    this.model = this.value
  },
  methods: {
    onInput () {
      this.$emit('input', this.model)
    }
  }
}
</script>
