<script>
import BaseLayer from '@/components/building/map/BaseLayer'

export default {
  extends: BaseLayer,
  components: {
    BaseLayer
  },
  computed: {
    sourceConfig () {
      let features = []
      if (this.building.roof.state === 'OK') {
        let tileIndex = 0
        const tiles = this.building.roof.roof_tiles
        for (let i = 0; i < tiles.length; i++) {
          let parcel = tiles[i]
          features.push({
            'id': tileIndex,
            'type': 'Feature',
            'geometry': parcel['geometry'],
            'properties': parcel
          })
          tileIndex++
        }
      }
      return {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': features
        }
      }
    },
    layerConfig () {
      return {
        type: 'fill',
        source: this.sourceId,
        paint: {
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.9,
            0.4
          ],
          'fill-outline-color': '#555555',
          'fill-color': [
            'match',
            ['get', 'solar_class'],
            5,
            '#a80000',
            4,
            '#ff6500',
            3,
            '#ffaa00',
            2,
            '#ffff00',
            1,
            '#00c5ff',
            /* other */ '#ccc'
          ]
        }
      }
    }
  }
}
</script>
