<template>
  <span class="data-warning">
    <v-popover trigger="hover" placement="right">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
      <template slot="popover">
        <header v-if="title">{{ title }}</header>
        <main><slot /></main>
      </template>
    </v-popover>
  </span>
</template>

<script>
import { VPopover } from 'v-tooltip'

export default {
  props: {
    title: {
      type: String
    }
  },
  components: {
    VPopover
  }
}
</script>

<style>
.data-warning .feather-alert-triangle {
  height: 16px;
  vertical-align: sub;
}
.data-warning {
  color: var(--warning-color);
}
.data-warning .v-popover {
  display: inline-block;
}
</style>
