<i18n>
{
  "de": {
    "estimateLabel": "Unsicher"
  }
}
</i18n>

<template>
  <span v-if="edit" @click="toggle" class="button">
    <v-popover trigger="hover" placement="right">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" :class="svgClasses"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
      <template slot="popover">
        <main v-if="tooltip">{{ tooltip }}</main>
        <main v-else>{{ $t('estimateLabel') }}</main>
      </template>
      <span :class="labelClasses">{{ label }}</span>
    </v-popover>
  </span>
  <span v-else-if="value">
    <v-popover trigger="hover" placement="right">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
      <slot name="value" />
      <template slot="popover">
        <main v-if="tooltip">{{ tooltip }}</main>
        <main v-else>{{ $t('estimateLabel') }}</main>
      </template>
      <span :class="labelClasses">{{ label }}</span>
    </v-popover>
  </span>
  <span v-else></span>
</template>

<script>
import { VPopover } from 'v-tooltip'

export default {
  data () {
    return {
      model: null
    }
  },
  created () {
    this.model = this.value
  },
  watch: {
    value () {
      this.model = this.value
    }
  },
  props: {
    value: {
      type: Boolean
    },
    edit: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    tooltip: {
      type: String
    }
  },
  computed: {
    svgClasses () {
      return {
        'feather': true,
        'feather-alert-triangle': true,
        'edit': true,
        'disabled': !this.model
      }
    },
    labelClasses () {
      return {
        'label-enabled': this.model
      }
    }
  },
  methods: {
    toggle () {
      this.model = !this.model
      this.$emit('input', this.model)
    }
  },
  components: {
    VPopover
  }
}
</script>
<style scoped>
  .feather-alert-triangle {
    color: var(--warning-color);
    height: 16px;
  }
  .feather-alert-triangle.disabled {
    color: #aaa;
  }
  .label-enabled {
    color: var(--warning-color);
  }
  .v-popover {
    display: inline-block;
  }
  .button {
    padding: var(--spacing-xs);
    display: inline-block;
  }
  .estimate-input {
    display: inline-block;
  }
</style>
