<!--
Used for a custom form component that provides v-model for a complex data structure (e.g. heaters table)
    Usage example:
    - Bind form state (isEditing), model and validation for each input field
      <numeric-input :edit="isEditing" @input="onInput" v-model="model.myDataField" @validation="onValidation('myDataField')" />

    - Override default validation (optional)
      computed: {
        isValid () {
          return this.noInvalidFields && this.model.length > 0
        }
      }
-->
<script>
export default {
  props: {
    value: {},
    edit: {
      type: Boolean
    }
  },
  data () {
    return {
      private__invalidFields: [], // List of fields that failed validation
      model: null // Temporary data model
    }
  },
  computed: {
    noInvalidFields () {
      return this.private__invalidFields.length === 0
    },
    // this method can be overwritten to implement custom validation logic
    isValid () {
      return this.noInvalidFields
    },
    isEditing () {
      return this.edit
    }
  },
  watch: {
    value () {
      this.resetModel()
    }
  },
  created: function () {
    this.resetModel()
  },
  methods: {
    resetModel () {
      if (this.value) {
        this.model = JSON.parse(JSON.stringify(this.value))
      }
    },
    // Handler for edit fields that have validation event
    onValidation (isValid, fieldName) {
      if (isValid) {
        this.private__invalidFields = this.private__invalidFields.filter((r) => r !== fieldName)
      } else {
        if (this.private__invalidFields.indexOf(fieldName) === -1) {
          this.private__invalidFields.push(fieldName)
        }
      }
    },
    onInput () {
      this.$emit('input', this.model)
      this.$emit('validation', this.isValid)
    }
  }
}
</script>
