<i18n>
{
  "de": {
    "detailsUrlLink": "Details",
    "heatSourceTitle": "Wärmequelle",
    "operatorTitle": "Betreiber",
    "ghgFactorTitle": "Emissionsfaktor",
    "peFactorTitle": "Energiefaktor",
    "statusTitle": "Status",
    "distanceTitle": "Distanz",
    "detailsUrlTitle": "Quelle"
  }
}
</i18n>
<template>
  <mapbox-popup
    :coordinates="coordinates"
    :showed="showed"
    anchor="top"
    @close="onPopupClose"
  >
    <div class="energy-grid-popup">
      <template v-if="feature !== undefined">
        <div class="title">{{ feature.properties.name }}</div>
        <div class="two-cols">
          <span>{{ $t('operatorTitle') }}</span>
          <span v-if="feature.properties.operator !== 'null'">{{ feature.properties.operator }}</span>
          <span v-else>-</span>

          <span>{{ $t('heatSourceTitle') }}</span>
          <span v-if="feature.properties.heatsource !== 'null'">{{ feature.properties.heatsource }}</span>
          <span v-else>-</span>

          <span>{{ $t('statusTitle') }}</span>
          <text-with-color :color="getColor(feature.properties.status)" v-if="feature.properties.status">
            <span>{{ $t(`_gridAvailability.${feature.properties.status}`) }}</span>
          </text-with-color>

          <span>{{ $t('distanceTitle') }}</span>
          <span>{{ (Math.ceil(feature.properties.distance / 10) * 10) }} m</span>

          <span>{{ $t('ghgFactorTitle') }}</span>
          <span v-if="feature.properties.ghg_factor !== 'null'">{{ formatNumber(feature.properties.ghg_factor, 0) }} g/kWh</span>
          <span v-else>-</span>

          <span>{{ $t('peFactorTitle') }}</span>
          <span v-if="feature.properties.pe_factor !== 'null'">{{ formatNumber(feature.properties.pe_factor, 2) }}</span>
          <span v-else>-</span>

          <span>{{ $t('detailsUrlTitle') }}</span>
          <span>
            <span v-if="feature.properties.data_source !== 'null'">{{ feature.properties.data_source }}</span>
            &nbsp;<a v-if="feature.properties.detailsurl !== 'null'" target="_blank" :href="feature.properties.detailsurl">{{ $t('detailsUrlLink') }}</a>
          </span>
        </div>
      </template>
    </div>
  </mapbox-popup>
</template>

<script>
import MapboxPopup from '@/components/shared/MapboxPopup'
import TextWithColor from '@/components/shared/TextWithColor'

export default {
  props: {
    feature: {
      type: Object
    },
    coordinates: {
      type: Array,
      required: true
    },
    showed: {
      type: Boolean,
      required: true
    }
  },
  components: {
    MapboxPopup,
    TextWithColor
  },
  computed: {
  },
  methods: {
    onPopupClose (event) {
      this.$emit('close', event)
    },
    getColor (status) {
      let colors = {
        'HEAT_SOURCE': 'yellow',
        'HEAT_POTENTIAL': 'yellow',
        'GAS_RETREAT': 'red',
        'EXISTING': 'green',
        'PLANNED': 'yellow',
        'EVALUATION': 'yellow',
        'NONE': 'red',
        'ERROR': 'gray',
        'UNKNOWN': 'gray'
      }
      return colors[status]
    }
  }
}
</script>

<style>
.energy-grid-popup {
  min-width:400px;
}
.energy-grid-popup .two-cols {
  display: grid;
  grid-template-columns: 130px 1fr;
}

.energy-grid-popup .title {
  font-weight: 600;
  margin-bottom: var(--spacing-m);
}

</style>
