<!--
Modal Wrapper for EditMeasure
-->
<i18n>
{
  "de": {
    "editMeasureTitle": "Massnahme bearbeiten",
    "createMeasureTitle": "Massnahme erstellen",
    "editScenarioMeasureTitle": "Massnahme basierend auf Szenario erstellen"
  }
}
</i18n>
<template>
  <modal @close="$emit('close', $event)" wide>
    <template v-slot:header>
      <h1 v-if="!measure">{{ $t('createMeasureTitle') }}</h1>
      <h1 v-else-if="measure && measure.phase === 'SCENARIO'">{{ $t('editScenarioMeasureTitle') }}</h1>
      <h1 v-else>{{ $t('editMeasureTitle') }}</h1>
    </template>
    <edit-measure
      :allow-edit="building.permissions.EDIT_MEASURES"
      :measure="measure"
      :building="building"
      :last-kpi="lastKpi"
      :portfolio="portfolio"
      @cancel="onCancel"
      @created="onCreated"
    />
  </modal>
</template>

<script>
import EditMeasure from '@/components/building/measures/edit/EditMeasure'
import Modal from '@/components/shared/Modal'

export default {
  props: {
    building: {
      type: Object
    },
    portfolio: {
      type: Object
    },
    measure: { // edit existing measure
      type: Object
    },
    lastKpi: {
      type: Object
    }
  },
  methods: {
    onCancel () {
      if (!this.measure) {
        // Close dialog if adding new is canceled
        this.$emit('close')
      }
    },
    onCreated () {
      this.$emit('close') // Close modal after creating new measure
    }
  },
  components: {
    Modal,
    EditMeasure
  }
}
</script>
