<script>
import BaseLayer from '@/components/building/map/BaseLayer'

export default {
  extends: BaseLayer,
  components: {
    BaseLayer
  },
  computed: {
    sourceConfig () {
      let features = []
      if (this.building.roof.state === 'OK') {
        for (let i = 0; i < this.measure.pv_sections.length; i++) {
          let section = this.measure.pv_sections[i]
          let tile = this.building.roof.roof_tiles.find(t => t.id === section.roof_tile_id)
          if (tile !== undefined) {
            section['color_category'] = section['n_modules'] === section['n_modules_max'] ? 'full' : 'partial'
            features.push({
              'id': i,
              'type': 'Feature',
              'geometry': tile['geometry'],
              'properties': section
            })
          }
        }
      }
      return {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': features
        }
      }
    },
    layerConfig () {
      return {
        type: 'fill',
        source: this.sourceId,
        paint: {
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.9,
            0.6
          ],
          'fill-outline-color': '#555555',
          'fill-color': [
            'match',
            ['get', 'color_category'],
            'full',
            '#eeee00',
            'partial',
            '#eeee88',
            /* other */ '#ccc'
          ]
        }
      }
    }
  }
}
</script>
