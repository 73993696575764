<!--
Edit form for PvMeasureSchema
-->
<i18n>
{
  "de": {
    "pvMethod": "Auslegung",
    "pvMethodAuto": "Automatische Auslegung mit GIS-Daten",
    "pvMethodManual": "Manuelle Auslegung",
    "costsAuto": "Automatische Kostenschätzung",
    "costsLabel": "Kosten",
    "subsidiesLabel": "Subventionen"
  }
}
</i18n>

<template>
  <div class="edit-pv-measure">
    <form-row :label="$t('pvMethod')">
      <div v-if="edit">
        <input type="radio" id="pv-method-auto" value="AUTO" v-model="model.method" @change="onInput">
        <label for="pv-method-auto">{{ $t('pvMethodAuto') }}</label>
        <br>
        <input type="radio" id="pv-method-manual" value="MANUAL" v-model="model.method" @change="onInput">
        <label for="pv-method-manual">{{ $t('pvMethodManual') }}</label>
      </div>
      <div v-else>
        <span v-if="model.method === 'AUTO'">{{ $t('pvMethodAuto') }}</span><span v-else>{{ $t('pvMethodManual') }}</span>
      </div>
      <edit-pv-sections class="spacing-top"
        v-if="model.method === 'MANUAL'"
        v-model="model.sections"
        :edit="isEditing"
        @input="onSectionsChange"
        :pv-results="pvResults"
      />
      <edit-pv-dimensioning class="spacing-top"
        v-if="model.method === 'AUTO'"
        v-model="model.dimensioning"
        :edit="isEditing"
        @input="onDimensioningChange"
      />
    </form-row>

    <form-row :label="$t('costsLabel')">
      <numeric-input @input="onInput" int optional v-model="model.costs" :min="0" :edit="isEditing" :units="'CHF'" class="limit-width" />
    </form-row>
    <form-row :label="$t('subsidiesLabel')">
      <numeric-input @input="onInput" int optional v-model="model.subsidies" :min="0" :edit="isEditing" :units="'CHF'" class="limit-width" />
    </form-row>
  </div>
</template>
<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin'

import EditPvSections from '@/components/building/edit/EditPvSections'
import EditPvDimensioning from '@/components/building/measures/edit/EditPvDimensioning'
import FormRow from '@/components/shared/forms/FormRow'
import NumericInput from '@/components/shared/forms/NumericInput'

export default {
  props: {
    pvResults: {
      type: Array
    }
  },
  mixins: [ComplexFormMixin],
  methods: {
    onSectionsChange (sections) {
      this.model.sections = sections
      this.onInput()
    },
    onDimensioningChange (dimensioning) {
      this.model.dimensioning = dimensioning
      this.onInput()
    }
  },
  components: {
    FormRow,
    EditPvSections,
    EditPvDimensioning,
    NumericInput
  }
}
</script>
<style scoped>
.spacing-top {
  margin-top: var(--spacing-m);
}
.limit-width {
  max-width: 150px;
}
</style>
