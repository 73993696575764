<i18n>
{
  "de": {
    "pvHeader": "Photovoltaik"
  }
}
</i18n>

<template>
  <detail-list-item class="pv-summary-row" :title="$t('pvHeader')" expandable @expand="onModalOpen">
    <div class="pv-summary-contents">
      <span v-if="pvSummary !== null">{{ pvSummary }} kWp</span>
      <span v-else>-</span>
    </div>
    <template #modal>
      <modal v-if="modalOpen" @close="onModalClose" wide>
        <template v-slot:header>
          <h2>{{ $t('pvHeader') }}</h2>
        </template>
        <edit-pv
          :building="building"
          :allow-edit="building.permissions.EDIT_PV"
        />
      </modal>
    </template>
  </detail-list-item>
</template>

<script>
import Modal from '@/components/shared/Modal'
import ModalMixin from '@/components/shared/ModalMixin'

import DetailListItem from '@/components/shared/lists/DetailListItem'
import EditPv from '@/components/building/edit/EditPv'

export default {
  mixins: [ModalMixin],
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  computed: {
    pvSummary () {
      if (this.building.initial_state.pv.sections.length > 0) {
        return this.building.initial_state.pv.sections.reduce((c, pv) => c + pv.capacity, 0)
      } else {
        return null
      }
    }
  },
  components: {
    Modal,
    DetailListItem,
    EditPv
  }
}
</script>
