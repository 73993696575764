<i18n>
{
  "de": {
    "modalTitle": "Verbrauchsdaten",
    "pendingMessage": "Daten werden gespeichert...",
    "noMeters": "Dieser Liegenschaft hat noch keine Verbrauchsdatenpunkte",
    "meterRemarksTitle": "Bemerkungen",
    "meterIdentifierTitle": "Datenpunkt Nr.",
    "meterTypeTitle": "Typ",
    "meterCarrierTitle": "Energieträger",
    "addMeterButton": "Datenpunkt hinzufügen",
    "closeEditMeterButton": "Abbrechen",
    "valuesTitle": "Erfasste Werte",
    "startMonthTitle": "Stichmonat",
    "valuesCountTitle": "Jahre",
    "noValues": "keine",
    "sumTitle": "Summe",
    "amountNormalizedTitle": "Endenergie (witterungsbereinigt)",
    "ghgEmissionsS1Title": "Scope 1-Emissionen",
    "ghgEmissionsTitle": "Scope 2-Emissionen",
    "peDemandTitle": "Primärenergie",
    "measuredAmounts": "Entwicklung Energieverbrauch",
    "deleteConfirmation": "Wollen Sie diesen Datenpunkt und alle erfasste Werte wirklich löschen?",
    "normalizationTitle": "Witterungsbereinigung",
    "normalizationSource": "Methode: HGT20/12 (HEV Schweiz / MeteoSchweiz)",
    "normalizationStation": "Klimastation",
    "altitudeLabel": "Höhe",
    "normTempLabel": "Normtemperatur"
  }
}
</i18n>
<template>
  <modal @close="$emit('close', $event)" wide class="meters-modal">
    <template v-slot:header>
      <h2>{{ $t('modalTitle') }}</h2>
    </template>
    <div v-if="error">
      <p>{{ error }}</p>
    </div>
    <div v-if="pending">
      <p>{{ $t('pendingMessage') }}</p>
    </div>
    <div v-else-if="building.meters.length === 0 && edit !== 'add'">
      {{ $t('noMeters')}}
    </div>
    <detail-list class="meters-table" v-else>
      <template #header>
        <span>{{ $t('meterTypeTitle') }}</span>
        <span>{{ $t('meterCarrierTitle') }}</span>
        <span>{{ $t('meterIdentifierTitle') }}</span>
        <!-- <span>{{ $t('meterRemarksTitle') }}</span> -->
        <span>{{ $t('startMonthTitle') }}</span>
        <span class="align-right">{{ $t('valuesTitle') }}</span>
        <span class="align-right">{{ $t('amountNormalizedTitle') }}</span>
        <span class="align-right">{{ $t('ghgEmissionsS1Title') }}</span>
        <span class="align-right">{{ $t('ghgEmissionsTitle') }}</span>
        <span class="align-right">{{ $t('peDemandTitle') }}</span>
        <span></span>
      </template>
      <li v-for="(m, index) in building.meters" :key="index">
        <!-- meter_type -->
        <div v-if="edit === index">
          <v-select v-model="edit_type" :options="typeOptions" :clearable="false" :reduce="t => t.id" />
        </div>
        <div v-else>
          {{ $t(`_meterTypes.${m.meter_type}`) }}
        </div>
        <!-- energy_carrier -->
        <div v-if="edit === index && edit_type === 'END_ENERGY_HEATER'">
          <v-select v-model="edit_carrier" :options="carrierOptions" :clearable="false" :reduce="t => t.id" />
        </div>
        <div v-else>
          <div v-if="m.meter_type === 'END_ENERGY_HEATER'">
            {{ $t(`_energyCarriers.${m.energy_carrier}`) }}
          </div>
        </div>
        <!-- identifier -->
        <text-input v-if="edit === index" v-model="edit_identifier" :edit="true" />
        <div v-else>{{ m.identifier }}</div>
        <!-- remarks -->
        <!-- <text-input v-if="edit === index" v-model="edit_remarks" :edit="true" />
        <div v-else>{{ m.remarks }}</div> -->
        <!-- start_month -->
        <numeric-input noFormatting int optional v-model="edit_start_month" :min="1" :max="12" :edit="true" class="limit-width" v-if="edit === index" />
        <div v-else>{{ m.start_month }}</div>
        <!-- number of values / average -->
        <div v-if="m.values.length > 0">
          {{ m.values.length }} {{ $t('valuesCountTitle') }}
        </div>
        <div v-else>{{ $t('noValues') }}</div>
        <!-- <div class="align-right" v-if="m.values.length > 0 && edit !== index">{{ formatNumber(averageAmount(m), 0) }} kWh</div>
        <div class="align-right" v-else>-</div> -->
        <div class="align-right" v-if="m.values.length > 0 && edit !== index">{{ formatNumber(averageAmountNormalized(m) / 1000, 1) }} MWh</div>
        <div class="align-right" v-else>-</div>
        <div class="align-right" v-if="m.values.length > 0 && edit !== index">{{ formatNumber(averageGhgS1(m) / 1000, 1) }} t</div>
        <div class="align-right" v-else>-</div>
        <div class="align-right" v-if="m.values.length > 0 && edit !== index">{{ formatNumber(averageGhg(m) / 1000, 1) }} t</div>
        <div class="align-right" v-else>-</div>
        <div class="align-right" v-if="m.values.length > 0 && edit !== index">{{ formatNumber(averagePe(m) / 1000, 1) }} MWh</div>
        <div class="align-right" v-else>-</div>
        <!-- actions -->
        <list-button-wrapper>
          <list-save-button v-if="edit === index" @click="onSaveMeter(index)" />
          <list-edit-button v-if="edit === false && building.permissions.EDIT_METERS" @click="onEditMeter(index)" />
          <list-delete-button v-if="edit === false && building.permissions.EDIT_METERS" @click="onDeleteMeter(index)" />
          <list-button v-if="edit === index" @click="onCloseEditMeter" :img-src="'/icons/x-circle.svg'" :label="$t('closeEditMeterButton')" />
          <list-details-button v-if="edit === false && building.permissions.EDIT_METERS" @click="onShowValues(index)" />
        </list-button-wrapper>
      </li>
      <li v-if="edit === 'add'">
        <div>
          <v-select v-model="edit_type" :options="typeOptions" :clearable="false" :reduce="t => t.id" />
        </div>
        <div v-if="edit_type === 'END_ENERGY_HEATER'">
          <v-select v-model="edit_carrier" :options="carrierOptions" :clearable="false" :reduce="t => t.id" />
        </div>
        <div v-else></div>
        <text-input v-model="edit_identifier" :edit="true" />
        <numeric-input noFormatting int optional v-model="edit_start_month" :min="1" :max="12" :edit="true" class="limit-width" />
        <!-- <text-input v-model="edit_remarks" :edit="true" /> -->
        <!-- <div></div> -->
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div>
          <list-save-button @click="onAddMeter" />
          <list-button :img-src="'/icons/x-circle.svg'" @click="onCloseEditMeter" />
        </div>
      </li>
      <template #footer v-if="building.meters.length > 0">
        <span>{{ $t('sumTitle') }}</span>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <!-- <span class="align-right">{{ formatNumber(sumAmount, 0) }} kWh</span> -->
        <span class="align-right">{{ formatNumber(sumAmountNormalized / 1000, 1) }} MWh</span>
        <span class="align-right">{{ formatNumber(sumGhgS1 / 1000, 1) }} t</span>
        <span class="align-right">{{ formatNumber(sumGhg / 1000, 1) }} t</span>
        <span class="align-right">{{ formatNumber(sumPe / 1000, 1) }} MWh</span>
        <div></div>
      </template>
    </detail-list>

    <meter-values-modal :meter="building.meters[showValues]" :allow-edit="building.permissions.EDIT_METERS" v-if="showValues !== false" @close="onCloseShowValues" />
    <button-wrapper>
      <button class="button" v-if="!pending && edit === false && building.permissions.EDIT_METERS" @click.prevent="onShowAddMeter">{{ $t('addMeterButton') }}</button>
    </button-wrapper>

    <div v-if="edit === false && building.meters.length > 0">
      <h3>{{ $t('measuredAmounts') }}</h3>
      <measured-kpi-chart :kpi-type="'ee'" class="amount-chart" :portfolio="portfolio" :query="{ building_id: this.building.id }" />
    </div>

    <div class="normalization-hint" v-if="edit === false && building.meters.length > 0">
      <h4>{{ $t('normalizationTitle') }}</h4>
      <div>{{ $t('normalizationSource') }}</div>
      {{ $t('normalizationStation') }}: {{ building.location_info.data.climate_station.name }} ({{ building.location_info.data.climate_station.code }}), {{ $t('altitudeLabel') }}: {{ building.location_info.data.climate_station.altitude }} m, {{ $t('normTempLabel') }}: {{ building.location_info.data.climate_station.normTemperature }} °C
    </div>
  </modal>
</template>

<script>
import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper'
import ListButton from '@/components/shared/lists/ListButton'
import ListEditButton from '@/components/shared/lists/ListEditButton'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton'
import ListSaveButton from '@/components/shared/lists/ListSaveButton'
import ListDetailsButton from '@/components/shared/lists/ListDetailsButton'

import MeasuredKpiChart from '@/components/report/charts/MeasuredKpiChart'

import ButtonWrapper from '@/components/shared/ButtonWrapper'
import MeterValuesModal from '@/components/building/overview/MeterValuesModal'
import DetailList from '@/components/shared/lists/DetailList'
import Modal from '@/components/shared/Modal'
import TextInput from '@/components/shared/forms/TextInput'
import NumericInput from '@/components/shared/forms/NumericInput'

import { mapActions } from 'vuex'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    building: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      edit: false,
      error: null,
      pending: false,
      showValues: false,
      edit_remarks: null,
      edit_identifier: null,
      edit_type: null,
      edit_carrier: null,
      edit_start_month: null,
      typeOptions: [
        { id: 'END_ENERGY_HEATER', label: this.$t('_meterTypes.END_ENERGY_HEATER') },
        { id: 'ELECTRICITY_TENANTS', label: this.$t('_meterTypes.ELECTRICITY_TENANTS') },
        { id: 'ELECTRICITY_GENERAL', label: this.$t('_meterTypes.ELECTRICITY_GENERAL') },
        { id: 'ELECTRICITY_PV', label: this.$t('_meterTypes.ELECTRICITY_PV') }
      ],
      carrierOptions: [
        { id: 'HEATING_OIL', label: this.$t('_energyCarriers.HEATING_OIL') },
        { id: 'NATURAL_GAS', label: this.$t('_energyCarriers.NATURAL_GAS') },
        { id: 'PELLET', label: this.$t('_energyCarriers.PELLET') },
        { id: 'WOOD_CHIPS', label: this.$t('_energyCarriers.WOOD_CHIPS') },
        { id: 'DISTRICT_HEAT', label: this.$t('_energyCarriers.DISTRICT_HEAT') },
        { id: 'LOCAL_HEAT', label: this.$t('_energyCarriers.LOCAL_HEAT') },
        { id: 'ELECTRICITY', label: this.$t('_energyCarriers.ELECTRICITY') },
        { id: 'SOLAR_HEAT', label: this.$t('_energyCarriers.SOLAR_HEAT') }
      ]
    }
  },
  computed: {
    /* sumAmount () {
      const sum = this.building.meters.reduce((pv, cv) => pv + this.averageAmount(cv), 0)
      return sum
    }, */
    sumAmountNormalized () {
      const sum = this.building.meters.reduce((pv, cv) => pv + this.averageAmountNormalized(cv), 0)
      return sum
    },
    sumGhgS1 () {
      const sum = this.building.meters.reduce((pv, cv) => pv + this.averageGhgS1(cv), 0)
      return sum
    },
    sumGhg () {
      const sum = this.building.meters.reduce((pv, cv) => pv + this.averageGhg(cv), 0)
      return sum
    },
    sumPe () {
      const sum = this.building.meters.reduce((pv, cv) => pv + this.averagePe(cv), 0)
      return sum
    }
  },
  methods: {
    ...mapActions({
      addMeter: 'building/addMeter',
      updateMeter: 'building/updateMeter',
      deleteMeter: 'building/deleteMeter'
    }),
    /* averageAmount (meter) {
      if (meter.values.length === 0) {
        return null
      }
      const sum = meter.values.reduce((pv, cv) => pv + cv.amount, 0)
      return sum / meter.values.length
    }, */
    averageAmountNormalized (meter) {
      if (meter.values.length === 0) {
        return null
      }
      const sum = meter.values.reduce((pv, cv) => pv + cv.amount_normalized, 0)
      return sum / meter.values.length
    },
    averageGhgS1 (meter) {
      if (meter.values.length === 0) {
        return null
      }
      const sum = meter.values.reduce((pv, cv) => pv + cv.ghg_s1, 0)
      return sum / meter.values.length
    },
    averageGhg (meter) {
      if (meter.values.length === 0) {
        return null
      }
      const sum = meter.values.reduce((pv, cv) => pv + cv.ghg, 0)
      return sum / meter.values.length
    },
    averagePe (meter) {
      if (meter.values.length === 0) {
        return null
      }
      const sum = meter.values.reduce((pv, cv) => pv + cv.pe, 0)
      return sum / meter.values.length
    },
    onShowAddMeter () {
      // this.edit_remarks = ''
      this.edit_start_month = 1
      this.edit_identifier = ''
      this.edit_type = 'END_ENERGY_HEATER'
      this.edit_carrier = 'HEATING_OIL'
      this.edit = 'add'
    },
    onCloseEditMeter () {
      this.edit = false
    },
    onEditMeter (index) {
      this.edit_start_month = this.building.meters[index].start_month
      // this.edit_remarks = this.building.meters[index].remarks
      this.edit_identifier = this.building.meters[index].identifier
      this.edit_type = this.building.meters[index].meter_type
      this.edit_carrier = this.building.meters[index].energy_carrier
      this.edit = index
    },
    onShowValues (index) {
      this.showValues = index
    },
    onCloseShowValues () {
      this.showValues = false
    },
    async onDeleteMeter (index) {
      const confirmationText = this.$t('deleteConfirmation')
      if (confirm(confirmationText)) {
        this.pending = true
        try {
          await this.deleteMeter({
            'id': this.building.meters[index].id,
            'building_id': this.building.id
          })
        } catch (error) {
          this.error = error
        }
        this.pending = false
      }
    },
    async onSaveMeter (index) {
      this.pending = true
      try {
        await this.updateMeter({
          'id': this.building.meters[index].id,
          'building_id': this.building.id,
          // 'remarks': this.edit_remarks,
          'start_month': this.edit_start_month,
          'identifier': this.edit_identifier,
          'meter_type': this.edit_type,
          'energy_carrier': this.edit_carrier
        })
      } catch (error) {
        this.error = error
      }
      this.edit = false
      this.pending = false
    },
    async onAddMeter () {
      this.pending = true
      try {
        await this.addMeter({
          'building_id': this.building.id,
          // 'remarks': this.edit_remarks,
          'start_month': this.edit_start_month,
          'identifier': this.edit_identifier,
          'meter_type': this.edit_type,
          'energy_carrier': this.edit_carrier
        })
      } catch (error) {
        this.error = error
      }
      this.edit = false
      this.pending = false
    }
  },
  components: {
    ListButton,
    ListButtonWrapper,
    ListSaveButton,
    ListEditButton,
    ListDetailsButton,
    ListDeleteButton,
    ButtonWrapper,
    MeterValuesModal,
    MeasuredKpiChart,
    DetailList,
    TextInput,
    NumericInput,
    Modal
  }
}
</script>
<style>
.meters-modal .meters-table.detail-list > ul > li {
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 180px;
}
.meters-modal .modal-wrapper {
  min-height: 500px;
}
.meters-modal .normalization-hint {
  padding: var(--spacing-s);
  border: var(--box-border);
  border-radius: var(--box-radius);
}
</style>
