<i18n>
{
  "de": {
    "header": "Strombedarf"
  }
}
</i18n>

<template>
  <detail-list-item :title="$t('header')" expandable @expand="onModalOpen">
    <div class="two-columns">
      <span>{{ formatNumber(building.kpi.el_demand / 1000, 1) }} MWh</span>
      <span>{{ formatNumber(building.kpi.el_demand / building.kpi.energy_area, 1) }} kWh/m<sup>2</sup></span>
    </div>
    <template #rightContent>
      <histogram-chart
          :key="'el_histogram'"
          :group-by-key="'el_demand'"
          area-specific
          :buildings="portfolio.buildings"
          :highlight-value="building.kpi['el_demand'] / building.kpi.energy_area"
          mini-chart
          class="histogram-chart"
        />
    </template>
    <template #modal>
      <electricity-demand-modal v-if="modalOpen" @close="onModalClose" :portfolio="portfolio" :building="building" />
    </template>
  </detail-list-item>
</template>

<script>
import DetailListItem from '@/components/shared/lists/DetailListItem'
import ElectricityDemandModal from '@/components/building/kpi/ElectricityDemandModal'
import HistogramChart from '@/components/portfolio/kpi/charts/HistogramChart'

export default {
  data: function () {
    return {
      modalOpen: false
    }
  },
  props: {
    portfolio: {
      type: Object
    },
    building: {
      type: Object,
      required: true
    }
  },
  methods: {
    onModalOpen () {
      this.modalOpen = true
    },
    onModalClose () {
      this.modalOpen = false
    }
  },
  components: {
    DetailListItem,
    HistogramChart,
    ElectricityDemandModal
  }
}
</script>
