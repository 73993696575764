<i18n>
{
  "de": {
    "kpiBeforeTitle": "Vor Massnahme",
    "kpiAfterTitle": "Nach Massnahme",
    "kpiAreaLabel": "Energiebezugsfläche",
    "kpiGhgLabel": "GHG-Emissionen",
    "kpiPeLabel": "Primärenergie",
    "kpiRhLabel": "Raumwärmebedarf",
    "kpiDhwDemandLabel": "Warmwasserbedarf",
    "kpiHeatingPowerLabel": "Heizleistung",
    "kpiElDemandLabel": "Strombedarf",
    "kpiElProductionLabel": "Stromproduktion",
    "kpiElOwnUseLabel": "Stromeigenverbrauch"
  }
}
</i18n>
<template>
  <div class="kpi-grid">
    <div></div>
    <div class="kpi-grid-header">{{ $t('kpiBeforeTitle') }}</div>
    <div class="kpi-grid-header">{{ $t('kpiAfterTitle') }}</div>
    <div></div>

    <kpi-change-row :label="$t('kpiAreaLabel')" :before="kpiBefore.energy_area" :after="kpiAfter.energy_area" :units="'m²'" />
    <kpi-change-row :label="$t('kpiGhgLabel')" :before="kpiBefore.ghg / 1000" :after="kpiAfter.ghg / 1000" :units="'t'" />
    <kpi-change-row
      :label="$t('kpiPeLabel')"
      :before="kpiBefore.pe / 1000"
      :energy-label-before-ratio="kpiBefore.pe / kpiBefore.energy_area / kpiBefore.pe_ref"
      :after="kpiAfter.pe / 1000"
      :energy-label-after-ratio="kpiAfter.pe / kpiAfter.energy_area / kpiAfter.pe_ref"
      :units="'MWh'"
    />
    <kpi-change-row
      :label="$t('kpiRhLabel')"
      :before="kpiBefore.rh_demand / kpiBefore.energy_area"
      :energy-label-before-ratio="kpiBefore.rh_demand / kpiBefore.energy_area / kpiBefore.rh_limit"
      :after="kpiAfter.rh_demand / kpiAfter.energy_area"
      :energy-label-after-ratio="kpiAfter.rh_demand / kpiAfter.energy_area / kpiAfter.rh_limit"
      :units="'kWh/m²'"
    />
    <!-- <kpi-change-row :label="$t('kpiDhwDemandLabel')" :before="kpiBefore.dhw_demand / kpiBefore.energy_area" :after="kpiAfter.dhw_demand / kpiAfter.energy_area" :units="'kWh/m²'" /> -->
    <kpi-change-row :label="$t('kpiHeatingPowerLabel')" :before="kpiBefore.heating_power" :after="kpiAfter.heating_power" :units="'kW'" />
    <kpi-change-row :label="$t('kpiElDemandLabel')" :before="kpiBefore.el_demand / kpiBefore.energy_area" :after="kpiAfter.el_demand / kpiAfter.energy_area" :units="'kWh/m²'" />
    <kpi-change-row :label="$t('kpiElProductionLabel')" :before="kpiBefore.el_production / kpiBefore.energy_area" :after="kpiAfter.el_production / kpiAfter.energy_area" :units="'kWh/m²'" />
    <!-- <kpi-change-row :label="$t('kpiElOwnUseLabel')" :before="kpiBefore.el_ownuse / kpiBefore.energy_area" :after="kpiAfter.el_ownuse / kpiAfter.energy_area" :units="'kWh/m²'" /> -->
  </div>
</template>

<script>
import KpiChangeRow from '@/components/building/measures/KpiChangeRow'

export default {
  props: {
    kpiBefore: {
      type: Object
    },
    kpiAfter: {
      type: Object
    }
  },
  components: {
    KpiChangeRow
  }
}
</script>

<style scoped>
.kpi-grid {
  display: grid;
  grid-template-columns: 150px 1fr 1fr 1fr;
  align-items: baseline;
  justify-content: start;
  gap: var(--spacing-m);
  column-gap: var(--spacing-l);
  margin-bottom: var(--spacing-m);
}

.kpi-grid-header {
  font-weight: 500;
  color: var(--secondary-highlight-color);
}

</style>
