<i18n>
{
  "de": {
    "header": {
      "ghg_s1": "Scope 1-Emissionen",
      "ghg": "Scope 2-Emissionen",
      "pe": "Primärenergie",
      "ee": "Endenergie"
    },
    "units": {
      "ghg_s1": "t",
      "ghg": "t",
      "pe": "MWh",
      "ee": "MWh"
    },
    "units_specific": {
      "ghg_s1": "kg/m\u00B2",
      "ghg": "kg/m\u00B2",
      "pe": "kWh/m\u00B2",
      "ee": "kWh/m\u00B2"
    }
  }
}
</i18n>

<template>
  <detail-list-item class="kpi-summary-row" :title="$t(`header.${kpiType}`)" :expandable="building.kpi[kpiType] !== 0" @expand="onModalOpen">
    <div class="two-columns">
      <span>{{ formatNumber(building.kpi[kpiType] / 1000, 0) }} {{ $t(`units.${kpiType}`) }}</span>
      <span>
        {{ formatNumber(building.kpi[kpiType] / building.kpi.energy_area, 0) }} {{ $t(`units_specific.${kpiType}`) }}
        <energy-label :value="peRatio" class="energy-label" v-if="kpiType === 'pe'" />
      </span>
    </div>
    <template #rightContent>
      <histogram-chart
          :key="`${kpiType}_histogram`"
          :group-by-key="kpiType"
          area-specific
          :buildings="portfolio.buildings"
          :highlight-value="building.kpi[kpiType] / building.kpi.energy_area"
          mini-chart
          class="histogram-chart"
        />
    </template>
    <template #modal v-if="building.kpi[kpiType] !== 0">
      <kpi-modal v-if="modalOpen" @close="onModalClose" :kpi-type="kpiType" :building="building" :portfolio="portfolio" />
    </template>
  </detail-list-item>
</template>

<script>
import EnergyLabel from '@/components/shared/EnergyLabel'
import DetailListItem from '@/components/shared/lists/DetailListItem'
import KpiModal from '@/components/building/kpi/KpiModal'
import HistogramChart from '@/components/portfolio/kpi/charts/HistogramChart'

export default {
  data: function () {
    return {
      modalOpen: false
    }
  },
  props: {
    portfolio: {
      type: Object
    },
    building: {
      type: Object,
      required: true
    },
    kpiType: {
      type: String, // ghg, ghg_s1, pe or ee
      required: true
    }
  },
  computed: {
    peRatio () {
      return (this.building.kpi.pe / this.building.kpi.energy_area) / this.building.kpi.pe_ref
    }
  },
  methods: {
    onModalOpen () {
      this.modalOpen = true
    },
    onModalClose () {
      this.modalOpen = false
    }
  },
  components: {
    DetailListItem,
    EnergyLabel,
    KpiModal,
    HistogramChart
  }
}
</script>
