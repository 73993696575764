<i18n>
{
  "de": {
    "modalTitle": "Verbrauchsdatenwerte erfassen",
    "noData": "Für diesen Datenpunkt wurden noch keine Verbrauchsdaten erfasst",
    "pendingMessage": "Daten werden gespeichert...",
    "yearTitle": "Jahr",
    "amountTitle": "Endenergie",
    "amountNormalizedTitle": "Endenergie (witterungsbereinigt)",
    "ghgEmissionsS1Title": "Scope 1-Emissionen",
    "ghgEmissionsTitle": "Scope 2-Emissionen",
    "peDemandTitle": "Primärenergie",
    "addButton": "Wert hinzufügen",
    "closeAddButton": "Abbrechen",
    "closeEditButton": "Abbrechen",
    "returnButton": "Zurück",
    "averageTitle": "Mittelwert"
  }
}
</i18n>
<template>
  <modal @close="$emit('close', $event)" wide class="meter-values-modal">
    <template v-slot:header>
      <h2>{{ $t('modalTitle') }}</h2>
    </template>
    <button-wrapper>
      <button class="button" v-if="!pending && edit === false" @click.prevent="$emit('close', $event)">{{ $t('returnButton') }}</button>
    </button-wrapper>
    <div v-if="error">
      <p>{{ error }}</p>
    </div>
    <div v-if="pending">
      <p>{{ $t('pendingMessage') }}</p>
    </div>
    <div v-else-if="meter.values.length === 0 && edit !== 'add'">
      {{ $t('noData') }}
    </div>
    <detail-list class="meter-values-table" v-else>
      <template #header>
        <span>{{ $t('yearTitle') }}</span>
        <span class="align-right">{{ $t('amountTitle') }}</span>
        <span class="align-right">{{ $t('amountNormalizedTitle') }}</span>
        <span class="align-right">{{ $t('ghgEmissionsS1Title') }}</span>
        <span class="align-right">{{ $t('ghgEmissionsTitle') }}</span>
        <span class="align-right">{{ $t('peDemandTitle') }}</span>
        <div></div>
      </template>
      <li v-for="(v, index) in meter.values" :key="index">
        <numeric-input noFormatting int v-if="edit === index" v-model="edit_year" :edit="true" />
        <div v-else>{{ v.year }}</div>
        <div class="align-right">
          <numeric-input v-if="edit === index" v-model="edit_amount" :edit="true" :units="'kWh'" />
          <div v-else>{{ formatNumber(v.amount) }} kWh</div>
        </div>
        <div class="align-right" v-if="meter.meter_type === 'END_ENERGY_HEATER' && edit !== index">{{ formatNumber(v.amount_normalized) }} kWh</div>
        <div class="align-right" v-else>-</div>
        <div class="align-right" v-if="edit !== index">{{ formatNumber(v.ghg_s1, 0) }} kg</div>
        <div class="align-right" v-else>-</div>
        <div class="align-right" v-if="edit !== index">{{ formatNumber(v.ghg, 0) }} kg</div>
        <div class="align-right" v-else>-</div>
        <div class="align-right" v-if="edit !== index">{{ formatNumber(v.pe) }} kWh</div>
        <div class="align-right" v-else>-</div>
        <list-button-wrapper>
          <list-save-button v-if="edit === index" @click="onSave(index)" />
          <list-edit-button v-if="edit === false && allowEdit" @click="onEdit(index)" />
          <list-delete-button v-if="edit === false && allowEdit" @click="onDelete(index)" />
          <list-button v-if="edit === index" @click="onCloseEdit" :img-src="'/icons/x-circle.svg'" :label="$t('closeEditButton')" />
        </list-button-wrapper>
      </li>
      <li v-if="edit === 'add'">
        <numeric-input noFormatting int v-model="edit_year" :edit="true" />
        <numeric-input v-model="edit_amount" :edit="true" :units="'kWh'" />
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <list-button-wrapper>
          <list-save-button @click="onAdd" />
          <list-button @click="onCloseEdit" :img-src="'/icons/x-circle.svg'" :label="$t('closeAddButton')" />
        </list-button-wrapper>
      </li>
      <template #footer v-if="meter.values.length > 0">
        <span>{{ $t('averageTitle') }}</span>
        <span class="align-right">{{ formatNumber(averageAmount, 0) }} kWh</span>
        <span class="align-right" v-if="meter.meter_type === 'END_ENERGY_HEATER'">{{ formatNumber(averageAmountNormalized, 0) }} kWh</span>
        <span v-else></span>
        <span class="align-right">{{ formatNumber(averageGhgS1, 0) }} kg</span>
        <span class="align-right">{{ formatNumber(averageGhg, 0) }} kg</span>
        <span class="align-right">{{ formatNumber(averagePe, 0) }} kWh</span>
        <div></div>
      </template>
    </detail-list>
    <button-wrapper>
      <button class="button" v-if="!pending && edit === false && allowEdit" @click.prevent="onShowAdd">{{ $t('addButton') }}</button>
    </button-wrapper>
  </modal>
</template>

<script>
import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper'
import ListButton from '@/components/shared/lists/ListButton'
import ListEditButton from '@/components/shared/lists/ListEditButton'
import ListSaveButton from '@/components/shared/lists/ListSaveButton'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton'

import ButtonWrapper from '@/components/shared/ButtonWrapper'
import DetailList from '@/components/shared/lists/DetailList'
import Modal from '@/components/shared/Modal'
import NumericInput from '@/components/shared/forms/NumericInput'

import { mapActions } from 'vuex'

export default {
  props: {
    allowEdit: Boolean,
    meter: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      edit: false,
      error: null,
      pending: false,
      edit_year: null,
      edit_amount: null
    }
  },
  computed: {
    averageAmount () {
      const sum = this.meter.values.reduce((pv, cv) => pv + cv.amount, 0)
      return sum / this.meter.values.length
    },
    averageAmountNormalized () {
      const sum = this.meter.values.reduce((pv, cv) => pv + cv.amount_normalized, 0)
      return sum / this.meter.values.length
    },
    averageGhgS1 () {
      const sum = this.meter.values.reduce((pv, cv) => pv + cv.ghg_s1, 0)
      return sum / this.meter.values.length
    },
    averageGhg () {
      const sum = this.meter.values.reduce((pv, cv) => pv + cv.ghg, 0)
      return sum / this.meter.values.length
    },
    averagePe () {
      const sum = this.meter.values.reduce((pv, cv) => pv + cv.pe, 0)
      return sum / this.meter.values.length
    }
  },
  methods: {
    ...mapActions({
      addMeterValue: 'building/addMeterValue',
      updateMeterValue: 'building/updateMeterValue',
      deleteMeterValue: 'building/deleteMeterValue'
    }),
    onShowAdd () {
      this.edit_year = new Date().getFullYear() - 1
      this.edit_amount = ''
      this.edit = 'add'
    },
    onCloseEdit () {
      this.edit = false
    },
    onEdit (index) {
      this.edit_year = this.meter.values[index].year
      this.edit_amount = this.meter.values[index].amount
      this.edit = index
    },
    async onDelete (index) {
      this.pending = true
      try {
        await this.deleteMeterValue({
          buildingId: this.meter.building_id,
          value: {
            'id': this.meter.values[index].id,
            'meter_id': this.meter.id
          }
        })
      } catch (error) {
        this.error = error
      }
      this.pending = false
    },
    async onSave (index) {
      this.pending = true
      try {
        await this.updateMeterValue({
          buildingId: this.meter.building_id,
          value: {
            'meter_id': this.meter.id,
            'id': this.meter.values[index].id,
            'year': this.edit_year,
            'amount': this.edit_amount
          }
        })
      } catch (error) {
        this.error = error
      }
      this.edit = false
      this.pending = false
    },
    async onAdd () {
      this.pending = true
      try {
        await this.addMeterValue({
          buildingId: this.meter.building_id,
          value: {
            'meter_id': this.meter.id,
            'year': this.edit_year,
            'amount': this.edit_amount
          }
        })
      } catch (error) {
        this.error = error
      }
      this.edit = false
      this.pending = false
    }
  },
  components: {
    ListButton,
    ListButtonWrapper,
    ListEditButton,
    ListSaveButton,
    ListDeleteButton,
    ButtonWrapper,
    DetailList,
    NumericInput,
    Modal
  }
}
</script>
<style>
.meter-values-modal .meter-values-table.detail-list > ul > li {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 120px;
}
.meter-values-modal .align-right {
  text-align: right;
}
</style>
