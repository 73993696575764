<i18n>
{
  "de": {
    "roofTileTitle": "Dachfläche",
    "roofTileArea": "Fläche",
    "roofTileIrradiation": "Einstrahlung",
    "roofTileSlope": "Neigung",
    "roofTileOrientation": "Orientierung",
    "roofTileClass": "Eignung für PV",
    "roofTileClass_5": "Top",
    "roofTileClass_4": "Sehr gut",
    "roofTileClass_3": "Gut",
    "roofTileClass_2": "Mittel",
    "roofTileClass_1": "Gering",
    "roofTileClass_small": "Fläche zu klein",
    "circFactor": "Formfaktor",
    "pvModules": "Max. Anzahl PV-Module",
    "pvCapacity": "Max. PV-Leistung",
    "pvProduction": "Stromertrag",
    "nModulesLabel": "Module",
    "pendingMessage": "Wird gespeichert, bitte warten..."
  }
}
</i18n>
<template>
  <mapbox-popup
    :coordinates="coordinates"
    :showed="showed"
    anchor="top"
    @close="onPopupClose"
  >
    <div class="roof-tile-popup">
      <template v-if="feature !== undefined">
        <div class="title">{{ $t('roofTileTitle') }} {{ feature.properties.id }}</div>
        <div class="two-cols">
          <span>{{ $t('roofTileArea') }} ({{ $t('circFactor') }})</span>
          <span>
            <span>{{ formatNumber(feature.properties.area, 0) }} m<sup>2</sup> ({{ formatNumber(feature.properties.circ_area_factor, 1) }})</span>
          </span>
          <span>{{ $t('roofTileClass') }}</span>
          <span>
            <span :class="[`ranking-${feature.properties.solar_class}`]">{{ $t(`roofTileClass_${feature.properties.solar_class}`) }} ({{ formatNumber(feature.properties.irradiation, 0) }} kWh/m<sup>2</sup>)</span>
          </span>
          <span>{{ $t('roofTileSlope') }} / {{ $t('roofTileOrientation') }}</span>
          <span>{{ formatNumber(feature.properties.slope, 0) }}° / {{ formatNumber(feature.properties.orientation, 0) }}°</span>
        </div>
        <div class="two-cols" v-if="!feature.properties.pv_n_modules">
          <span>{{ $t('pvCapacity') }}</span><span>{{ $t('roofTileClass_small') }}</span>
        </div>
        <div class="two-cols" v-else>
          <span>{{ $t('pvCapacity') }}</span><span>{{ feature.properties.pv_capacity }} kWp ({{ feature.properties.pv_n_modules }} {{ $t('nModulesLabel') }})</span>
          <span>{{ $t('pvProduction') }}</span><span>{{ formatNumber(feature.properties.pv_specific_production, 0) }} kWh/kWp</span>
        </div>
        <edit-roof-tile
          :allow-edit="building.permissions.EDIT_FEASIBILITY"
          :building="building"
          :feature="feature"
        />
      </template>
    </div>
  </mapbox-popup>
</template>

<script>
import EditRoofTile from '@/components/building/edit/EditRoofTile'
import MapboxPopup from '@/components/shared/MapboxPopup'

export default {
  props: {
    feature: {
      type: Object
    },
    building: {
      type: Object
    },
    coordinates: {
      type: Array,
      required: true
    },
    showed: {
      type: Boolean,
      required: true
    }
  },
  components: {
    MapboxPopup,
    EditRoofTile
  },
  methods: {
    onPopupClose (event) {
      this.$emit('close', event)
    }
  }
}
</script>

<style>
.roof-tile-popup {
  min-width:400px;
}
.roof-tile-popup .two-cols {
  display: grid;
  grid-template-columns: 150px 1fr;
}

.roof-tile-popup .edit-roof-tile .two-column-row {
  margin-bottom: 0;
  gap: 0;
  column-gap: 0;
}

.roof-tile-popup .title {
  font-weight: 600;
  margin-bottom: var(--spacing-m);
}

.roof-tile-popup .ranking-1::before,
.roof-tile-popup .ranking-2::before,
.roof-tile-popup .ranking-3::before,
.roof-tile-popup .ranking-4::before,
.roof-tile-popup .ranking-5::before,
.roof-tile-popup .ranking-small::before {
  content: "";
  display: inline-block;
  margin-right: 0.3rem;
  place-self: center;
  width: 1.3rem;
  height: .8rem;
  border: var(--box-border);
  vertical-align: text-top;
}

.roof-tile-popup .ranking-1::before {
  background-color: var(--ranking-5-color);
}

.roof-tile-popup .ranking-2::before {
  background-color: var(--ranking-4-color);
}

.roof-tile-popup .ranking-3::before {
  background-color: var(--ranking-3-color);
}

.roof-tile-popup .ranking-4::before {
  background-color: var(--ranking-2-color);
}

.roof-tile-popup .ranking-5::before {
  background-color: var(--ranking-1-color);
}

</style>
