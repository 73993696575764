<i18n>
{
  "de": {
    "metersHeader": "Verbrauchsdaten",
    "datapointsLabel": "Datenpunkt(e)",
    "noDatapoints": "Keine Datenpunkte"
  }
}
</i18n>

<template>
  <detail-list-item :title="$t('metersHeader')" expandable @expand="onModalOpen">
    <span v-if="building.meters.length">{{ building.meters.length }} {{ $t('datapointsLabel') }}</span>
    <span v-else>{{ $t('noDatapoints') }}</span>
    <template #modal>
      <meters-modal v-if="modalOpen" @close="onModalClose" :building="building" :portfolio="portfolio" />
    </template>
  </detail-list-item>
</template>

<script>
import DetailListItem from '@/components/shared/lists/DetailListItem'
import MetersModal from '@/components/building/overview/MetersModal'

export default {
  data: function () {
    return {
      modalOpen: false
    }
  },
  props: {
    building: {
      type: Object,
      required: true
    },
    portfolio: {
      type: Object,
      required: true
    }
  },
  methods: {
    onModalOpen () {
      this.modalOpen = true
    },
    onModalClose () {
      this.modalOpen = false
    }
  },
  components: {
    DetailListItem,
    MetersModal
  }
}
</script>
