<template>
  <edit-container :bind-form-state="formState" @container="onFormEvent">
    <tag-picker :portfolio="portfolio" :tag-group="tagGroup" v-model="model.tagId" :edit="isEditing" />
  </edit-container>
</template>
<script>
import { mapActions } from 'vuex'

import EditContainer from '@/components/shared/forms/EditContainer'
import ApiFormMixin from '@/components/shared/forms/ApiFormMixin'
import TagPicker from '@/components/building/edit/TagPicker'

export default {
  mixins: [
    ApiFormMixin
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation */
  ],
  components: {
    EditContainer,
    TagPicker
  },
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    building: {
      type: Object,
      required: true
    },
    tagGroup: {
      type: Object,
      required: true
    }
  },
  watch: {
    building () {
      this.resetModel()
    },
    tagGroup () {
      this.resetModel()
    }
  },
  methods: {
    ...mapActions({
      setTag: 'building/setTag'
    }),
    resetModel () {
      const tag = this.building.tags.find(t => t.gid === this.tagGroup.id)
      this.model = {
        tagId: tag ? tag.id : null
      }
    },
    async saveHandler (model) {
      await this.setTag({
        buildingId: this.building.id,
        groupId: this.tagGroup.id,
        tagId: this.model.tagId
      })
    }
  }
}
</script>
