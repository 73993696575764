<template>
  <edit-container :bind-form-state="formState" @container="onFormEvent" class="edit-zones">
    <edit-zones-table
      v-model="model.zones"
      :zone-results="zoneResults"
      :edit="isEditing"
      @input="onZonesChange"
      @validation="onValidation($event, 'zones-table')"
    />
  </edit-container>
</template>
<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin'
import EditContainer from '@/components/shared/forms/EditContainer'

import EditZonesTable from '@/components/building/edit/EditZonesTable'

export default {
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  mixins: [
    ApiFormMixin
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation */
  ],
  computed: {
    zoneResults () {
      return this.building.initial_state.result && this.building.initial_state.result.zones
    }
  },
  methods: {
    ...mapActions({
      updateBuilding: 'building/updateBuilding'
    }),
    onZonesChange (zones) {
      this.model.zones = zones
    },
    resetModel () {
      this.model = {
        zones: []
      }
      for (let i = 0; i < this.building.initial_state.zones.zones.length; i++) {
        const zone = this.building.initial_state.zones.zones[i]
        this.model.zones.push({ ...zone })
      }
    },
    async saveHandler () {
      await this.updateBuilding({
        'id': this.building.id,
        'initial_state': {
          'zones': this.model
        }
      })
    }
  },
  components: {
    EditContainer,
    EditZonesTable
  }
}
</script>
