<i18n>
{
  "de": {
    "pvTitle": "Photovoltaik-Potenzial",
    "reloadLabel": "Dachflächen neu abfragen",
    "pendingMessage": "Daten werden abgefragt und Gebäude neu simuliert...",
    "lastUpdateLabel": "Quelle: Bundesamt für Energie (BFE). Aktualisiert am"
  }
}
</i18n>

<template>
    <modal @close="$emit('close', $event)" wide>
        <template v-slot:header>
            <h2>{{ $t('pvTitle') }}</h2>
        </template>
        <div v-if="error">
          <p>{{ error }}</p>
        </div>
        <div v-if="pending">
          <p>{{ $t('pendingMessage') }}</p>
        </div>
        <div v-else>
          <pv-potential-table :building="building" v-if="building.roof.state === 'OK'" />
          <div class="missing-data" v-else>
            {{ $t(`_roofState.${building.roof.state}`) }}
          </div>
          <button-wrapper v-if="!pending">
            <p v-if="building.roof.state === 'OK'">{{ $t('lastUpdateLabel') }}: {{ lastUpdateDate }}</p>
            <button v-if="building.permissions.REFRESH_ROOF" class="button" @click="onReload">{{ $t('reloadLabel') }}</button>
          </button-wrapper>
        </div>
    </modal>
</template>

<script>
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import PvPotentialTable from '@/components/building/feasibility/PvPotentialTable'
import Modal from '@/components/shared/Modal'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      'pending': null,
      'error': null
    }
  },
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  computed: {
    lastUpdateDate () {
      return new Date(this.building.roof.last_update).toLocaleDateString('de-DE')
    }
  },
  methods: {
    ...mapActions({
      queryRoofTiles: 'building/queryRoofTiles'
    }),
    async onReload () {
      this.pending = true
      try {
        await this.queryRoofTiles({
          'id': this.building.id
        })
      } catch (error) {
        this.error = error
      }
      this.pending = false
    }
  },
  components: {
    ButtonWrapper,
    PvPotentialTable,
    Modal
  }
}
</script>

<style scoped>
.missing-data {
  color:var(--warning-color);
}

</style>
