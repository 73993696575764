<i18n>
{
  "de": {
    "header": "Leistungsbedarf Wärme"
  }
}
</i18n>

<template>
  <detail-list-item :title="$t('header')">
    <div class="two-columns">
      <span>{{ formatNumber(building.kpi.heating_power, 1) }} kW</span>
      <span>{{ formatNumber(building.kpi.heating_power * 1000 / building.kpi.energy_area, 1) }} W/m<sup>2</sup></span>
    </div>
  </detail-list-item>
</template>

<script>
import DetailListItem from '@/components/shared/lists/DetailListItem'

export default {
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  components: {
    DetailListItem
  }
}
</script>
