<i18n>
{
  "de": {
    "egidTitle": "EGID",
    "gebnrTitle": "Amtl. Gebäudenr.",
    "buildingYearTitle": "Baujahr",
    "floorsTitle": "Anzahl Geschosse",
    "areaTitle": "Gebäudefläche",
    "buildingCategoryTitle": "Gebäudekategorie",
    "buildingClassTitle": "Gebäudeklasse",
    "flatsTitle": "Anzahl Wohnungen"
  }
}
</i18n>
<template>
  <MapboxPopup
    :coordinates="coordinates"
    :showed="showed"
    anchor="top"
    @close="onPopupClose"
  >
    <div class="egid-popup">
      <template v-if="feature !== undefined">
        <div class="title">{{ feature.properties.strname_deinr }}</div>
        <div class="two-cols">
          <span>{{ $t('egidTitle') }}</span><span>{{ feature.properties.egid }}</span>
          <span>{{ $t('gebnrTitle') }}</span><span>{{ feature.properties.gebnr }}</span>
          <span>{{ $t('buildingYearTitle') }}</span><span v-if="feature.properties.gbauj !== undefined && feature.properties.gbauj !== 'null'">{{ feature.properties.gbauj }}</span><span v-else>-</span>
          <span>{{ $t('buildingCategoryTitle') }}</span><span v-if="feature.properties.gkat !== undefined && feature.properties.gkat !== 'null'">{{ $t(`_gwrGKAT.${feature.properties.gkat}`) }}</span><span v-else>-</span>
          <span>{{ $t('buildingClassTitle') }}</span><span v-if="feature.properties.gklas !== undefined && feature.properties.gklas !== 'null'">{{ $t(`_gwrGKLAS.${feature.properties.gklas}`) }}</span><span v-else>-</span>
          <span>{{ $t('floorsTitle') }}</span><span v-if="feature.properties.gastw !== undefined && feature.properties.gastw !== 'null'">{{ feature.properties.gastw }}</span><span v-else>-</span>
          <span>{{ $t('flatsTitle') }}</span><span v-if="feature.properties.whgnr !== undefined && feature.properties.whgnr !== 'null'">{{ feature.properties.whgnr.length }}</span><span v-else>-</span>
          <span>{{ $t('areaTitle') }}</span><span v-if="feature.properties.garea !== undefined && feature.properties.garea !== 'null'">{{ feature.properties.garea }} m<sup>2</sup></span><span v-else>-</span>
        </div>
      </template>
    </div>
  </MapboxPopup>
</template>

<script>
import MapboxPopup from '@/components/shared/MapboxPopup'

export default {
  props: {
    feature: {
      type: Object
    },
    coordinates: {
      type: Array,
      required: true
    },
    showed: {
      type: Boolean,
      required: true
    }
  },
  components: {
    MapboxPopup
  },
  methods: {
    onPopupClose (event) {
      this.$emit('close', event)
    }
  }
}
</script>

<style>
.egid-popup .title {
  font-weight: 600;
  margin-bottom: var(--spacing-m);
}
.egid-popup .two-cols {
  display: grid;
  grid-template-columns: 130px 1fr;
}
</style>
