<i18n>
{
  "de": {
    "renovationHeader": "Gebäudehülle",
    "wallsAge": "Alter Fassade",
    "windowsAge": "Alter Fenster",
    "basementAge": "Alter Kellerdecke",
    "roofAge": "Alter Dach",
    "years": "Jahre",
    "lifecyclePopupTitle": "Zustand Gebäudehülle",
    "originalState": "nicht saniert",
    "renovationLabel": "Sanierung",
    "partialRenovationLabel": "Teilsanierung",
    "constructionYear": "Baujahr",
    "componentYearsUnknown": "Der Zustand der Gebäudehülle ist teilweise unbekannt",
    "withoutHeating": "unbeheizt",
    "manualRhDemand": "Wärmebedarf manuell",
    "missingBuildingYear": "Baujahr unbekannt"
  }
}
</i18n>

<template>
  <detail-list-item class="envelope-summary-row" :title="$t('renovationHeader')" expandable @expand="onModalOpen">
    <div v-if="building.initial_state.result && !building.initial_state.result.envelope.building_year.value">
      <missing-data-label>{{ $t('missingBuildingYear') }}</missing-data-label>
    </div>
    <div v-else-if="building.initial_state.envelope.without_heating && building.initial_state.result">
      {{ $t('constructionYear') }} {{ building.initial_state.result.envelope.building_year.value }}, {{ $t('withoutHeating')}}
    </div>
    <div v-else-if="building.initial_state.envelope.manual_rh_demand && building.initial_state.result">
      {{ $t('constructionYear') }} {{ building.initial_state.result.envelope.building_year.value }}, {{ $t('manualRhDemand')}}
    </div>
    <div v-else-if="building.initial_state.result">
      {{ $t('constructionYear') }} {{ building.initial_state.result.envelope.building_year.value }},
      <data-warning v-if="componentYearsUnknown">{{ $t('componentYearsUnknown' )}}</data-warning>
      <span v-if="renovationYears.length">
        <span v-if="fullRenovation">{{ $t('renovationLabel') }}</span>
        <span v-else>{{ $t('partialRenovationLabel') }}</span>
        {{ renovationYears.join(', ') }}
      </span>
      <span v-else>{{ $t('originalState') }}</span>
    </div>
    <div v-else>
      {{ building.initial_state.envelope.building_year }}
    </div>
    <template #rightContent v-if="building.initial_state.result && building.initial_state.result.envelope.building_year.value && !building.initial_state.envelope.without_heating && !building.initial_state.envelope.manual_rh_demand">
      <v-popover trigger="hover" placement="right" class="lifecycle-popover">
        <ring-indicator :year="building.walls_year" :minLifetime="building.walls_replacement_year" :maxLifetime="building.walls_replacement_year + 5" class="ring-indicator" />
        <ring-indicator :year="building.windows_year" :minLifetime="building.windows_replacement_year" :maxLifetime="building.windows_replacement_year + 5" class="ring-indicator" />
        <ring-indicator :year="building.roof_year" :minLifetime="building.roof_replacement_year" :maxLifetime="building.roof_replacement_year + 5" class="ring-indicator" />
        <ring-indicator :year="building.basement_year" :minLifetime="building.basement_replacement_year" :maxLifetime="building.basement_replacement_year + 5" class="ring-indicator" />
        <template slot="popover">
          <header>
            {{ $t('lifecyclePopupTitle') }}
          </header>
          <main class="envelope-summary-row-lifecycle">
            <ring-indicator :year="building.walls_year" :minLifetime="building.walls_replacement_year" :maxLifetime="building.walls_replacement_year + 5" />
            {{ $t('wallsAge') }}: {{ wallsAge }} {{ $t('years') }}
          </main>
          <main class="envelope-summary-row-lifecycle">
            <ring-indicator :year="building.windows_year" :minLifetime="building.windows_replacement_year" :maxLifetime="building.windows_replacement_year + 5" />
            {{ $t('windowsAge') }}: {{ windowsAge }} {{ $t('years') }}
          </main>
          <main class="envelope-summary-row-lifecycle">
            <ring-indicator :year="building.roof_year" :minLifetime="building.roof_replacement_year" :maxLifetime="building.roof_replacement_year + 5" />
            {{ $t('roofAge') }}: {{ roofAge }} {{ $t('years') }}
          </main>
          <main class="envelope-summary-row-lifecycle">
            <ring-indicator :year="building.basement_year" :minLifetime="building.basement_replacement_year" :maxLifetime="building.basement_replacement_year + 5" />
            {{ $t('basementAge') }}: {{ basementAge }} {{ $t('years') }}
          </main>
        </template>
      </v-popover>
    </template>
    <template #modal>
      <modal v-if="modalOpen" @close="onModalClose" wide>
        <template v-slot:header>
          <h2>{{ $t('renovationHeader') }}</h2>
        </template>
        <edit-envelope :allow-edit="building.permissions.EDIT_ENVELOPE" :building="building" />
      </modal>
    </template>
  </detail-list-item>
</template>

<script>
import Modal from '@/components/shared/Modal'
import ModalMixin from '@/components/shared/ModalMixin'

import DetailListItem from '@/components/shared/lists/DetailListItem'
import RingIndicator from '@/components/shared/RingIndicator'
import DataWarning from '@/components/shared/DataWarning'
import MissingDataLabel from '@/components/shared/MissingDataLabel'
import EditEnvelope from '@/components/building/edit/EditEnvelope'
import { VPopover } from 'v-tooltip'

export default {
  mixins: [ModalMixin],
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  computed: {
    componentYearsUnknown () {
      for (const c of ['walls', 'roof', 'basement', 'windows']) {
        if (this.building.initial_state.envelope[c].year_estimated) {
          return true
        }
      }
      return false
    },
    fullRenovation () {
      for (const c of ['walls', 'roof', 'basement', 'windows']) {
        const componentYear = this.building[`${c}_year`]
        if (componentYear === this.building.initial_state.result.envelope.building_year.value) {
          return false
        }
      }
      return true
    },
    renovationYears () {
      let renovations = []
      for (const c of ['walls', 'roof', 'basement', 'windows']) {
        const componentYear = this.building[`${c}_year`]
        if (componentYear !== this.building.initial_state.result.envelope.building_year.value) {
          if (renovations.indexOf(componentYear) === -1) {
            renovations.push(componentYear)
          }
        }
      }
      return renovations.sort()
    },
    wallsAge () {
      return (new Date().getFullYear()) - this.building.walls_year
    },
    windowsAge () {
      return (new Date().getFullYear()) - this.building.windows_year
    },
    basementAge () {
      return (new Date().getFullYear()) - this.building.basement_year
    },
    roofAge () {
      return (new Date().getFullYear()) - this.building.roof_year
    },
    heatingAge () {
      return (new Date().getFullYear()) - this.building.heating_year
    }
  },
  components: {
    Modal,
    EditEnvelope,
    DetailListItem,
    RingIndicator,
    VPopover,
    DataWarning,
    MissingDataLabel
  }
}
</script>

<style>
.envelope-summary-row {
  position: relative;
}

.envelope-summary-row .lifecycle-popover {
    display: inline;
}

.envelope-summary-row .ring-indicator {
  height:28px;
  margin-right:7px;
  vertical-align: middle;
}

.envelope-summary-row-lifecycle {
  display: grid;
  grid-template-columns: 32px 1fr;
}

.envelope-summary-row-lifecycle .ring-indicator {
  height:24px;
}
</style>
