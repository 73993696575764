<script>
export default {
  data: function () {
    return {
      modalOpen: false
    }
  },
  methods: {
    onModalOpen () {
      this.modalOpen = true
    },
    onModalClose () {
      this.modalOpen = false
    }
  }
}
</script>
