<i18n>
{
  "de": {
    "geothermalTitle": "Zulässigkeit Wärmenutzung",
    "ewsAdmissibility": "Erdwärmesonden",
    "gwAdmissibility": "Grundwasser"
  }
}
</i18n>

<template>
  <detail-list-item :title="$t('geothermalTitle')" expandable @expand="onModalOpen">
    <div class="missing-data" v-if="building.geothermal.state !== 'OK'">{{ $t(`_geothermalState.${building.geothermal.state}`) }}</div>
    <div v-else class="admissibilities">
      <text-with-color :color="ewsAdmissibilityColor">{{ $t('ewsAdmissibility') }}: {{ $t(`_admissibility.${building.geothermal.admissibilities.ews}`) }}</text-with-color>
      <text-with-color :color="gwAdmissibilityColor">{{ $t('gwAdmissibility') }}: {{ $t(`_admissibility.${building.geothermal.admissibilities.gw}`) }}</text-with-color>
    </div>
    <template #modal>
      <geothermal-modal v-if="modalOpen" @close="onModalClose" :building="building" />
    </template>
  </detail-list-item>
</template>

<script>
import TextWithColor from '@/components/shared/TextWithColor'
import DetailListItem from '@/components/shared/lists/DetailListItem'
import GeothermalModal from '@/components/building/feasibility/GeothermalModal'

export default {
  data: function () {
    return {
      modalOpen: false
    }
  },
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  methods: {
    onModalOpen () {
      this.modalOpen = true
    },
    onModalClose () {
      this.modalOpen = false
    }
  },
  computed: {
    ewsAdmissibilityColor () {
      let colors = {
        'ADMISSIBLE': 'green',
        'LIMITATIONS': 'yellow',
        'NON_ADMISSIBLE': 'red',
        'BAD_GEOLOGY': 'red',
        'ERROR': 'gray',
        'UNKNOWN': 'gray'
      }
      return colors[this.building.geothermal.admissibilities.ews]
    },
    gwAdmissibilityColor () {
      let colors = {
        'ADMISSIBLE': 'green',
        'LIMITATIONS': 'yellow',
        'NON_ADMISSIBLE': 'red',
        'BAD_GEOLOGY': 'red',
        'ERROR': 'gray',
        'UNKNOWN': 'gray'
      }
      return colors[this.building.geothermal.admissibilities.gw]
    }
  },
  components: {
    DetailListItem,
    GeothermalModal,
    TextWithColor
  }
}
</script>
<style scoped>
.missing-data {
  color:var(--warning-color);
  font-size: var(--font-xs);
}
.admissibilities div ~ div{
  margin-top: var(--spacing-s);
}
</style>
