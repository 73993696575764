<!--
TODO: move gwr and cadastre overview to separate components
-->
<i18n>
{
  "de": {
    "locationModalTitle": "Standort",
    "egidTitle": "EGID",
    "gebnrTitle": "Amtliche Gebäudenummer",
    "addressTitle": "Adresse",
    "zipTitle": "Postleitzahl",
    "streetTitle": "Strasse",
    "municipalityTitle": "Gemeinde",
    "parcelNrTitle": "Parzell Nr.",
    "parcelAreaTitle": "Grundstückfläche",
    "parcelTotal": "Total",
    "buildingYearTitle": "Baujahr",
    "floorsTitle": "Anzahl Geschosse",
    "areaTitle": "Gebäudefläche",
    "buildingCategoryTitle": "Gebäudekategorie",
    "buildingClassTitle": "Gebäudeklasse",
    "total": "Total",
    "flatsTitle": "Anzahl Wohnungen",
    "addressesTitle": "Gebäude- und Wohnungsregister (GWR)",
    "parcelsTitle": "Parzellen",
    "addressInputsTitle": "Adressen",
    "reloadLabel": "Daten neu abfragen",
    "gwrPendingMessage": "Daten werden abgefragt und Gebäude neu simuliert...",
    "lastUpdateLabel": "Daten aktualisiert am",
    "showGwrDetails": "GWR-Daten anzeigen",
    "hideGwrDetails": "GWR-Daten ausblenden",
    "showCadastreDetails": "Grundbuchdaten anzeigen",
    "hideCadastreDetails": "Grundbuchdaten ausblenden",
    "gwrFailed": "Eine oder mehrere Adressen konnten nicht im GWR gefunden werden. Bitte überprüfen Sie die Schreibweise oder vergleichen sie den GWR-Eintrag im GIS-Browser",
    "gwrFailedGis": "GIS-Browser öffnen"
  }
}
</i18n>

<template>
  <modal @close="$emit('close', $event)" class="overview-location-modal" wide>
    <template v-slot:header>
      <h2>{{ $t('locationModalTitle') }}</h2>
    </template>

    <div class="gwr-failed" v-if="building.gwr.state !== 'OK' && (building.addresses && building.addresses.length > 0 || building.egids && building.egids.length)">
      {{ $t('gwrFailed') }}
      (<a target="_blank" href="https://map.geo.admin.ch/?lang=de&topic=ech&bgLayer=ch.swisstopo.pixelkarte-farbe&layers=ch.swisstopo.zeitreihen,ch.bfs.gebaeude_wohnungs_register,ch.bav.haltestellen-oev,ch.swisstopo.swisstlm3d-wanderwege,ch.astra.wanderland-sperrungen_umleitungen&layers_opacity=1,1,1,0.8,0.8&layers_visibility=false,true,false,false,false&layers_timestamp=18641231,,,,&E=2669314.79&N=1210656.19&zoom=1">{{ $t('gwrFailedGis') }}</a>)
    </div>

    <edit-address
      :building="building"
      :allow-edit="building.permissions.EDIT_LOCATION"
    />

    <button-wrapper>
      <button class="button" v-if="!gwrDetailsOpen" @click="gwrDetailsOpen = true">{{ $t('showGwrDetails') }}</button>
      <button class="button" v-if="gwrDetailsOpen" @click="gwrDetailsOpen = false">{{ $t('hideGwrDetails') }}</button>
      <button class="button" v-if="!cadastreDetailsOpen" @click="cadastreDetailsOpen = true">{{ $t('showCadastreDetails') }}</button>
      <button class="button" v-if="cadastreDetailsOpen" @click="cadastreDetailsOpen = false">{{ $t('hideCadastreDetails') }}</button>
    </button-wrapper>

    <div v-if="gwrDetailsOpen">
      <h3>{{ $t('addressesTitle') }}</h3>
      <div v-if="gwrError">
        <p>{{ gwrError }}</p>
      </div>
      <div v-if="gwrPending">
        <p>{{ $t('gwrPendingMessage') }}</p>
      </div>
      <div class="missing-data" v-else-if="building.gwr.state !== 'OK'">
        {{ $t(`_gwrState.${building.gwr.state}`) }}
      </div>
      <div class="missing-data" v-if="building.gwr.state === 'ERROR'">
        <li v-for="(address, index) in building.gwr.failed_addresses" v-bind:key="index">{{ address }}</li>
      </div>
      <detail-list v-else class="addresses-list">
        <template #header>
          <span>{{ $t('egidTitle') }}</span>
          <span>{{ $t('gebnrTitle') }}</span>
          <span>{{ $t('addressTitle') }}</span>
          <span>{{ $t('buildingYearTitle') }}</span>
          <span>{{ $t('buildingCategoryTitle') }}</span>
          <span>{{ $t('buildingClassTitle') }}</span>
          <span>{{ $t('floorsTitle') }}</span>
          <span>{{ $t('flatsTitle') }}</span>
          <span>{{ $t('areaTitle') }}</span>
        </template>
        <li v-for="(entry, index) in building.gwr.entries" :key="index">
          <span>{{ entry.properties.egid }}</span>
          <span>{{ entry.properties.gebnr }}</span>
          <span>{{ entry.properties.strname_deinr }}</span>
          <span v-if="entry.properties.gbauj">{{ entry.properties.gbauj }}</span>
          <span v-else>-</span>
          <span v-if="entry.properties.gkat">{{ $t(`_gwrGKAT.${entry.properties.gkat}`) }}</span>
          <span v-else>-</span>
          <span v-if="entry.properties.gklas">{{ $t(`_gwrGKLAS.${entry.properties.gklas}`) }}</span>
          <span v-else>-</span>
          <span v-if="entry.properties.gastw">{{ entry.properties.gastw }}</span>
          <span v-else>-</span>
          <span v-if="entry.properties.whgnr">{{ entry.properties.whgnr.length }}</span>
          <span v-else>-</span>
          <span v-if="entry.properties.garea">{{ entry.properties.garea }} m<sup>2</sup></span>
          <span v-else>-</span>
        </li>
        <template #footer>
          <span>{{ $t('total') }}</span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span v-if="totalFlats">{{ totalFlats }}</span>
          <span v-else>-</span>
          <span v-if="totalBuildingArea">{{ formatNumber(totalBuildingArea, 0) }} m<sup>2</sup></span>
          <span v-else>-</span>
        </template>
      </detail-list>
      <button-wrapper v-if="!gwrPending">
        <p v-if="building.gwr.state === 'OK'">{{ $t('lastUpdateLabel') }}: {{ lastGwrUpdateDate }}</p>
        <button v-if="building.permissions.REFRESH_GWR" class="button" @click="onGwrReload">{{ $t('reloadLabel') }}</button>
      </button-wrapper>
    </div>

    <div v-if="cadastreDetailsOpen">
      <h3>{{ $t('parcelsTitle') }}</h3>
      <div v-if="cadastreError">
        <p>{{ cadastreError }}</p>
      </div>
      <div v-if="cadastrePending">
        <p>{{ $t('pendingMessage') }}</p>
      </div>
      <div class="missing-data" v-else-if="building.cadastre.state !== 'OK'">
        {{ $t(`_cadastreState.${building.cadastre.state}`) }}
      </div>
      <detail-list v-else class="parcels-list">
          <template #header>
              <span>{{ $t('parcelNrTitle') }}</span>
              <span>{{ $t('parcelAreaTitle') }}</span>
          </template>
          <li v-for="(parcel, index) in building.cadastre.parcels" :key="index">
              <span>{{ parcel.parcel_nr }}</span>
              <span>{{ formatNumber(parcel.area, 0) }} m<sup>2</sup></span>
          </li>
          <template #footer>
              <span>{{ $t('parcelTotal') }}</span>
              <span>{{ formatNumber(totalParcelArea, 0) }} m<sup>2</sup></span>
          </template>
      </detail-list>
      <button-wrapper v-if="!cadastrePending && building.gwr.state === 'OK'">
        <p v-if="building.cadastre.state === 'OK'">{{ $t('lastUpdateLabel') }}: {{ lastCadastreUpdateDate }}</p>
        <button v-if="building.permissions.REFRESH_PARCELS" class="button" @click="onCadastreReload">{{ $t('reloadLabel') }}</button>
      </button-wrapper>
    </div>
  </modal>
</template>

<script>
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import EditAddress from '@/components/building/edit/EditAddress'
import DetailList from '@/components/shared/lists/DetailList'
import Modal from '@/components/shared/Modal'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      gwrPending: null,
      gwrError: null,
      cadastrePending: null,
      cadastreError: null,
      gwrDetailsOpen: false,
      cadastreDetailsOpen: false
    }
  },
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      queryGwr: 'building/queryGwr',
      queryCadastre: 'building/queryCadastre'
    }),
    async onGwrReload () {
      this.gwrPending = true
      try {
        await this.queryGwr({
          'id': this.building.id
        })
      } catch (error) {
        this.gwrError = error
      }
      this.gwrPending = false
    },
    async onCadastreReload () {
      this.cadastrePending = true
      try {
        await this.queryCadastre({
          'id': this.building.id
        })
      } catch (error) {
        this.cadastreError = error
      }
      this.cadastrePending = false
    }
  },
  computed: {
    lastGwrUpdateDate () {
      return new Date(this.building.gwr.last_update).toLocaleDateString('de-DE')
    },
    lastCadastreUpdateDate () {
      return new Date(this.building.cadastre.last_update).toLocaleDateString('de-DE')
    },
    totalParcelArea () {
      return this.building.cadastre.parcels.reduce((a, p) => a + p.area, 0)
    },
    totalBuildingArea () {
      return this.building.gwr.entries.reduce((a, p) => a + p.properties.garea, 0)
    },
    totalFlats () {
      return this.building.gwr.entries.reduce((a, p) => a + (p.properties.whgnr ? p.properties.whgnr.length : 0), 0)
    }
  },
  components: {
    ButtonWrapper,
    Modal,
    EditAddress,
    DetailList
  }
}
</script>

<style>
.overview-location-modal .addresses-list > ul > li {
    grid-template-columns: 1fr 1fr 3fr 1fr 3fr 3fr 1fr 1fr 1fr;
}
.overview-location-modal .parcels-list > ul > li {
    grid-template-columns: 1fr 4fr;
}
.overview-location-modal .missing-data {
  color:var(--warning-color);
}
.overview-location-modal .gwr-failed {
  padding: var(--spacing-m);
  margin-top: var(--spacing-s);
  background-color: var(--warning-color);
  border: var(--box-border);
  border-radius: var(--box-radius);
  margin-bottom: var(--spacing-m);
}
</style>
