<!--
Edit form for PvSectionSchema (used for measures and currently existing pv)
-->
<i18n>
{
  "de": {
    "ownUseRatio": "Eigenverbrauch",
    "addPvButton": "Photovoltaikanlage hinzufügen",
    "pvCapacityTitle": "Installierte Leistung",
    "pvAngleTitle": "Neigung",
    "pvOrientationTitle": "Orientierung",
    "pvLossTitle": "Verluste",
    "noPvLabel": "Keine Photovoltaikanlage vorhanden",
    "pvProductionTitle": "Ertrag",
    "sourcePvGis": "Quelle: PVGIS",
    "sourceRoofTile": "Quelle: Sonnendach/BFE, PVGIS",
    "sourceFassadeTile": "Quelle: Sonnenfassade/BFE, PVGIS",
    "capacityInfoBoxTitle": "Installierte Peak-Leistung",
    "capacityInfoBox": "Norm-Leistung der Photovoltaikmodule",
    "angleInfoBoxTitle": "Neigung der Anlage",
    "angleInfoBox": "Winkel zur Horizontalen. 0° = horizontal",
    "orientationInfoBoxTitle": "Orientierung der Anlage (Azimuth)",
    "orientationInfoBox": "Himmelsrichtung in Grad, relativ zu Süden. 0° = Süden / 90° = Westen / -90° = Osten / 180° = Norden",
    "lossInfoBoxTitle": "Systemverluste",
    "lossInfoBox": "Verluste durch Kabel, Wechselrichter, Verschmutzung der Module, etc. Wenn dieses Feld leergelassen wird, wird ein Standardwert von 14% angenommen.",
    "productionInfoBoxTitle": "Stromertrag",
    "productionInfoBox": "Erzeugte elektrische Energie nach Abzug von System-Verlusten. Wenn dieses Feld leergelassen wird und die Orientierung, Neigung und Verluste der Anlage angegeben werden, wird der Ertrag über die PVGIS-Datenbank ermittelt.",
    "ownUseRatioInfoBoxTitle": "Eigenverbrauchsanteil",
    "ownUseRatioInfoBox": "Anteil Strom, der vor Ort verbraucht wird. Wenn dieses Feld leergelassen wird, wird der Eigenverbrauchsanteil mittels monatlicher Strombilanzierung abgeschätzt."
  }
}
</i18n>

<template>
  <div class="edit-pv-sections">
    <detail-list class="pv-table" v-if="model.length > 0">
      <template #header>
        <span class="align-right">{{ $t('pvCapacityTitle') }} <info-box :title="$t('capacityInfoBoxTitle')">{{ $t('capacityInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('pvAngleTitle') }} <info-box :title="$t('angleInfoBoxTitle')">{{ $t('angleInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('pvOrientationTitle') }} <info-box :title="$t('orientationInfoBoxTitle')">{{ $t('orientationInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('pvLossTitle') }} <info-box :title="$t('lossInfoBoxTitle')">{{ $t('lossInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('pvProductionTitle') }} <info-box :title="$t('productionInfoBoxTitle')">{{ $t('productionInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('ownUseRatio') }} <info-box :title="$t('ownUseRatioInfoBoxTitle')">{{ $t('ownUseRatioInfoBox') }}</info-box></span>
        <span v-if="edit"></span>
      </template>
      <li v-for="(p, sectionIndex) in model" :key="sectionIndex">
        <div class="align-right">
          <numeric-input @input="onInput" v-model="p.capacity" :min="0" :edit="isEditing" :units="'kWp'" />
        </div>
        <div class="align-right">
          <span v-if="p.production_manual !== null">-</span>
          <numeric-input @input="onInput" v-else v-model="p.angle" :min="0" :max="89" :edit="isEditing" :units="'°'" />
        </div>
        <div class="align-right">
          <span v-if="p.production_manual !== null">-</span>
          <numeric-input @input="onInput" v-else v-model="p.orientation" :min="-180" :max="180" :edit="isEditing" :units="'°'" />
        </div>
        <div class="align-right">
          <span v-if="p.production_manual !== null">-</span>
          <numeric-input @input="onInput" v-else v-model="p.loss" :min="0" :max="100" :edit="isEditing" :units="'%'" />
        </div>
        <div class="align-right">
          <numeric-input @input="onInput" v-model="p.production_manual" optional :min="0" :max="2000" :edit="isEditing" :units="'kWh/kWp'" v-if="edit" />
          <div v-else-if="pvResults && pvResults[sectionIndex]">
            {{ formatNumber(pvResults[sectionIndex].production.value, -1) }} kWh
            ({{ formatNumber(pvResults[sectionIndex].production.value / p.capacity, 0) }} kWh/m²)
            <div class="source-info" v-if="pvResults[sectionIndex].production.source === 'ROOF_TILE'">{{ $t('sourceRoofTile')}}</div>
            <div class="source-info" v-if="pvResults[sectionIndex].production.source === 'FASSADE_TILE'">{{ $t('sourceFassadeTile')}}</div>
            <div class="source-info" v-if="pvResults[sectionIndex].production.source === 'PVGIS'">{{ $t('sourcePvGis')}}</div>
          </div>
          <span v-else-if="!edit && p.production_manual">{{ p.production_manual }} kWh/m²</span>
        </div>
        <div class="align-right">
          <numeric-input @input="onInput" v-model="p.ownuse_ratio_manual" optional :min="0" :max="100" :edit="isEditing" :units="'%'" />
        </div>
        <list-button-wrapper>
          <list-delete-button v-if="edit" @click="onDeletePv(sectionIndex)" />
        </list-button-wrapper>
      </li>
    </detail-list>
    <div v-else>{{ $t('noPvLabel') }}</div>
    <button-wrapper>
      <button class="button" v-if="edit" @click.prevent="onAddPv">{{ $t('addPvButton') }}</button>
    </button-wrapper>
  </div>
</template>
<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton'
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import DetailList from '@/components/shared/lists/DetailList'
import NumericInput from '@/components/shared/forms/NumericInput'
import InfoBox from '@/components/shared/InfoBox'

export default {
  props: {
    pvResults: {
      type: Array,
      required: false
    }
  },
  mixins: [ComplexFormMixin],
  methods: {
    onAddPv () {
      this.model.push({
        capacity: null,
        loss: 14,
        orientation: 0,
        angle: 10,
        production_manual: null,
        ownuse_ratio_manual: null
      })
      this.onInput()
    },
    onDeletePv (index) {
      this.model.splice(index, 1)
      this.onInput()
    }
  },
  components: {
    ListButtonWrapper,
    ListDeleteButton,
    ButtonWrapper,
    NumericInput,
    DetailList,
    InfoBox
  }
}
</script>
<style>
.edit-pv-sections .detail-list.pv-table > ul > li {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 50px
}
.edit-pv-sections .source-info {
  color: var(--secondary-text-color);
  font-size: var(--font-xs);
}
.edit-pv-sections .align-right {
  text-align: right;
}
</style>
