<i18n>
{
  "de": {
    "sourceDefault": "Standardwert",
    "sourceSonnendachExtrapolation": "Extrapolation GIS-Gebäudedaten",
    "sourceGwrExtrapolation": "Extrapolation GWR",
    "sourceRentalArea": "Umrechnung HNF",
    "sourceFloorArea": "Umrechnung BGF",
    "sourceEnergyArea": "Umrechnung EBF",
    "rentalAreaTitle": "HNF",
    "floorAreaTitle": "BGF",
    "energyAreaTitle": "EBF",
    "usageTitle": "Nutzung",
    "addZoneButton": "Zone hinzufügen",
    "remarksTitle": "Bemerkungen",
    "autoCalculationEnabled": "**",
    "autoCalculationCheckboxLabel": "Automatisch",
    "usageEstimatedLabel": "Nutzung unsicher",
    "areasEstimatedLabel": "Flächen unsicher",
    "featureEnabled": "Vorhanden",
    "hasDhwTitle": "Warmwasser",
    "dhwTitle": "Warmwasser",
    "appliancesTitle": "Geräte",
    "lightningTitle": "Beleuchtung",
    "hasVentilationTitle": "Lüftung",
    "ventilationTitle": "Lüftung",
    "generalTitle": "Allgemein",
    "total": "Total",
    "noZonesDefined": "Keine Zonen definiert",
    "floorAreaInfoBoxTitle": "Bruttogeschossfläche (BGF)",
    "floorAreaInfoBox": "Summe aller Grundflächen inklusive Konstruktionsflächen. Falls keine Hauptnutz- und Energiebezugsflächen bekannt sind, werden diese mit einem Umrechnungsfaktor über die Bruttogeschossfläche abgeschätzt.",
    "rentalAreaInfoBoxTitle": "Hauptnutzflächen (HNF)",
    "rentalAreaInfoBox": "Hauptnutzfläche nach SIA 416. Falls die Energiebezugsfläche nicht bekannt ist, wird diese mit einem Umrechnungsfaktor über die Hauptnutzfläche abgeschätzt.",
    "energyAreaInfoBoxTitle": "Energiebezugfläche (EBF)",
    "energyAreaInfoBox": "Die EBF dient als Basis für alle spezifischen Energie- und CO2-Kennzahlen. Definition: Summe aller ober- und unterirdischen Geschossflächen, die innerhalb der thermischen Gebäudehülle liegen und für die ein Beheizen oder Klimatisieren notwendig ist (siehe SIA 380/1).",
    "dhwInfoBoxTitle": "Spezifischer Nutzenergiebedarf für Warmwasser (Q_W)",
    "dhwInfoBox": "Wenn dieses Feld leergelassen wird, werden Standardswerte für diese Nutzung verwendet (siehe Standardwerte SIA 2024:2015 Anhang E Tabelle 16).",
    "appliancesInfoBoxTitle": "Spezifischer Elektrizitätsbedarf Geräte (E_A)",
    "appliancesInfoBox": "Wenn dieses Feld leergelassen wird, werden Standardswerte für diese Nutzung verwendet (siehe Standardwerte SIA 2024:2015 Anhang E Tabelle 16).",
    "lightningInfoBoxTitle": "Spezifischer Elektrizitätsbedarf Beleuchtung (E_L)",
    "lightningInfoBox": "Wenn dieses Feld leergelassen wird, werden Standardswerte für diese Nutzung verwendet (siehe Standardwerte SIA 2024:2015 Anhang E Tabelle 16).",
    "ventilationInfoBoxTitle": "Spezifischer Elektrizitätsbedarf Lüftung (E_V)",
    "ventilationInfoBox": "Wenn dieses Feld leergelassen wird, werden Standardswerte für diese Nutzung verwendet (siehe Standardwerte SIA 2024:2015 Anhang E Tabelle 16).",
    "generalInfoBoxTitle": "Spezifischer Elektrizitätsbedarf allgemeine Gebäudetechnik",
    "generalInfoBox": "Wenn dieses Feld leergelassen wird, werden Standardswerte für diese Nutzung verwendet (siehe SIA 2056:2019 Anhang E.3)."
  }
}
</i18n>

<template>
  <div class="edit-zones-table">
    <detail-list class="zone-table" v-if="model.length > 0">
      <template #header>
        <span>{{ $t('usageTitle') }}</span>
        <span class="align-right">{{ $t('floorAreaTitle') }} <info-box :title="$t('floorAreaInfoBoxTitle')">{{ $t('floorAreaInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('rentalAreaTitle') }} <info-box :title="$t('rentalAreaInfoBoxTitle')">{{ $t('rentalAreaInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('energyAreaTitle') }} <info-box :title="$t('energyAreaInfoBoxTitle')">{{ $t('energyAreaInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('dhwTitle') }} <info-box :title="$t('dhwInfoBoxTitle')">{{ $t('dhwInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('appliancesTitle') }} <info-box :title="$t('appliancesInfoBoxTitle')">{{ $t('appliancesInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('lightningTitle') }} <info-box :title="$t('lightningInfoBoxTitle')">{{ $t('lightningInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('ventilationTitle') }} <info-box :title="$t('ventilationInfoBoxTitle')">{{ $t('ventilationInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('generalTitle') }} <info-box :title="$t('generalInfoBoxTitle')">{{ $t('generalInfoBox') }}</info-box></span>
        <span class="align-right"></span>
      </template>
      <li v-for="(zone, zoneIdx) in model" :key="zoneIdx">
        <div>
          <div v-if="isEditing">
            <zone-usage-picker v-model="zone.usage" @input="onInput" />
          </div>
          <div v-else>
            {{ $t(`_usageTypes.${zone.usage}`) }}
          </div>
        </div>
        <div class="align-right">
          <numeric-input v-model="zone.floor_area" :min="0" optional :edit="isEditing" :units="'m²'" v-if="isEditing" @input="onInput" />
          <div v-if="!isEditing && zoneResults && zoneResults[zoneIdx] && zoneResults[zoneIdx].floor_area.value">
            {{ zoneResults[zoneIdx].floor_area.value }} m²
            <div class="source-info" v-if="zoneResults[zoneIdx].floor_area.source === 'RENTAL_AREA'">{{ $t('sourceRentalArea')}}</div>
            <div class="source-info" v-if="zoneResults[zoneIdx].floor_area.source === 'ENERGY_AREA'">{{ $t('sourceEnergyArea')}}</div>
            <div class="source-info" v-if="zoneResults[zoneIdx].floor_area.source === 'GWR_EXTRAPOLATION'">{{ $t('sourceGwrExtrapolation')}}</div>
            <div class="source-info" v-if="zoneResults[zoneIdx].floor_area.source === 'SONNENDACH_EXTRAPOLATION'">{{ $t('sourceSonnendachExtrapolation')}}</div>
          </div>
          <span v-else-if="!isEditing && zone.floor_area">{{ formatNumber(zone.floor_area, 0) }} m²</span>
        </div>
        <div class="align-right">
          <numeric-input v-model="zone.rental_area" :min="0" optional :edit="isEditing" :units="'m²'" @input="onInput" />
        </div>
        <div class="align-right">
          <numeric-input v-model="zone.energy_area" :min="0" optional :edit="isEditing" :units="'m²'" v-if="isEditing" @input="onInput" />
          <div v-if="!isEditing && zoneResults && zoneResults[zoneIdx] && zoneResults[zoneIdx].energy_area.value">
            {{ zoneResults[zoneIdx].energy_area.value }} m²
            <div class="source-info" v-if="zoneResults[zoneIdx].energy_area.source === 'RENTAL_AREA'">{{ $t('sourceRentalArea')}}</div>
            <div class="source-info" v-if="zoneResults[zoneIdx].energy_area.source === 'FLOOR_AREA'">{{ $t('sourceFloorArea')}}</div>
            <div class="source-info" v-if="zoneResults[zoneIdx].energy_area.source === 'GWR_EXTRAPOLATION'">{{ $t('sourceGwrExtrapolation')}}</div>
            <div class="source-info" v-if="zoneResults[zoneIdx].energy_area.source === 'SONNENDACH_EXTRAPOLATION'">{{ $t('sourceSonnendachExtrapolation')}}</div>
          </div>
          <span v-else-if="!isEditing && zone.energy_area">{{ formatNumber(zone.energy_area, 0) }} m²</span>
        </div>
        <div class="align-right">
          <numeric-input v-model="zone.dhw_demand" :min="0" optional :edit="isEditing" :units="'kWh/m²'" v-if="zone.has_dhw && isEditing" @input="onInput" />
          <div v-if="!isEditing && zone.has_dhw && zoneResults && zoneResults[zoneIdx]">
            {{ zoneResults[zoneIdx].dhw_demand.value }} kWh/m²
            <div class="source-info" v-if="zoneResults[zoneIdx].dhw_demand.source === 'DEFAULT'">{{ $t('sourceDefault')}}</div>
          </div>
          <span v-else-if="!isEditing && zone.dhw_demand">{{ zone.dhw_demand }} kWh/m²</span>
          <check-input v-model="zone.has_dhw" v-if="!zone.has_dhw || isEditing" :edit="isEditing" @input="onInput" :label="$t('featureEnabled')" :active-label="''" :inactive-label="'-'" />
        </div>
        <div class="align-right">
          <numeric-input v-model="zone.el_appliances" :min="0" optional :edit="isEditing" :units="'kWh/m²'" v-if="isEditing" @input="onInput" />
          <div v-else-if="zoneResults && zoneResults[zoneIdx]">
            {{ zoneResults[zoneIdx].el_appliances.value }} kWh/m²
            <div class="source-info" v-if="zoneResults[zoneIdx].el_appliances.source === 'DEFAULT'">{{ $t('sourceDefault')}}</div>
          </div>
          <span v-else-if="!isEditing && zone.el_appliances">{{ zone.el_appliances }}</span>
        </div>
        <div class="align-right">
          <numeric-input v-model="zone.el_lightning" :min="0" optional :edit="isEditing" :units="'kWh/m²'" v-if="isEditing" @input="onInput" />
          <div v-else-if="zoneResults && zoneResults[zoneIdx]">
            {{ zoneResults[zoneIdx].el_lightning.value }} kWh/m²
            <div class="source-info" v-if="zoneResults[zoneIdx].el_lightning.source === 'DEFAULT'">{{ $t('sourceDefault')}}</div>
          </div>
          <span v-else-if="!isEditing && zone.el_lightning">{{ zone.el_lightning }} kWh/m²</span>
        </div>
        <div class="align-right">
          <numeric-input v-model="zone.el_ventilation" :min="0" optional :edit="isEditing" :units="'kWh/m²'" v-if="zone.has_ventilation && isEditing" @input="onInput" />
          <div v-if="!isEditing && zone.has_ventilation && zoneResults && zoneResults[zoneIdx]">
            {{ zoneResults[zoneIdx].el_ventilation.value }} kWh/m²
            <div class="source-info" v-if="zoneResults[zoneIdx].el_ventilation.source === 'DEFAULT'">{{ $t('sourceDefault')}}</div>
          </div>
          <span v-else-if="!isEditing && zone.el_ventilation">{{ zone.el_ventilation }} kWh/m²</span>
          <check-input v-if="!zone.has_ventilation || isEditing" v-model="zone.has_ventilation" :edit="isEditing" :label="$t('featureEnabled')" @input="onInput" :active-label="''" :inactive-label="'-'" />
        </div>
        <div class="align-right">
          <numeric-input v-model="zone.el_general" :min="0" optional :edit="isEditing" :units="'kWh/m²'" v-if="isEditing" @input="onInput" />
          <div v-else-if="zoneResults && zoneResults[zoneIdx]">
            {{ zoneResults[zoneIdx].el_general.value }} kWh/m²
            <div class="source-info" v-if="zoneResults[zoneIdx].el_general.source === 'DEFAULT'">{{ $t('sourceDefault')}}</div>
          </div>
          <span v-else-if="!isEditing && zone.el_general">{{ zone.el_general }} kWh/m²</span>
        </div>
        <list-button-wrapper>
          <list-delete-button v-if="isEditing" @click="onDeleteZone(zoneIdx)" />
        </list-button-wrapper>
      </li>
      <template #footer>
        <span>{{ $t('total') }}</span>
        <span class="align-right" v-if="totalFloorArea !== undefined">{{ formatNumber(totalFloorArea, 0) }} m<sup>2</sup></span>
        <span class="align-right" v-else>-</span>
        <span class="align-right" v-if="totalRentalArea !== undefined">{{ formatNumber(totalRentalArea, 0) }} m<sup>2</sup></span>
        <span class="align-right" v-else>-</span>
        <span class="align-right" v-if="totalEnergyArea !== undefined">
          <span>{{ formatNumber(totalEnergyArea, 0) }} m<sup>2</sup></span>
        </span>
        <span class="align-right" v-else>-</span>
      </template>
    </detail-list>
    <div v-else>{{ $t('noZonesDefined') }}</div>

    <button-wrapper>
      <button class="button" v-if="isEditing" @click.prevent="onAddZone">{{ $t('addZoneButton') }}</button>
    </button-wrapper>
  </div>
</template>
<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton'
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import DetailList from '@/components/shared/lists/DetailList'
import InfoBox from '@/components/shared/InfoBox'
import ZoneUsagePicker from '@/components/building/edit/ZoneUsagePicker'
import NumericInput from '@/components/shared/forms/NumericInput'
import CheckInput from '@/components/shared/forms/CheckInput'

export default {
  props: {
    zoneResults: {
      type: Array,
      required: false
    }
  },
  mixins: [ComplexFormMixin],
  computed: {
    totalEnergyArea () {
      let sum
      if (this.edit) {
        sum = this.model.reduce((pv, cv) => pv + parseFloat(cv.energy_area), 0)
      } else if (this.zoneResults) {
        sum = this.zoneResults.reduce((pv, cv) => pv + parseFloat(cv.energy_area.value), 0)
      } else {
        return undefined
      }
      if (Number.isNaN(sum)) {
        return undefined
      }
      return sum
    },
    totalRentalArea () {
      const sum = this.model.reduce((pv, cv) => pv + parseFloat(cv.rental_area), 0)
      if (Number.isNaN(sum)) {
        return undefined
      }
      return sum
    },
    totalFloorArea () {
      let sum
      if (this.edit) {
        sum = this.model.reduce((pv, cv) => pv + parseFloat(cv.floor_area), 0)
      } else if (this.zoneResults) {
        sum = this.zoneResults.reduce((pv, cv) => pv + parseFloat(cv.floor_area.value), 0)
      } else {
        return undefined
      }
      if (Number.isNaN(sum)) {
        return undefined
      }
      return sum
    }
  },
  methods: {
    onAddZone () {
      this.model.push({
        'usage': 'UNKNOWN',
        'energy_area': null,
        'rental_area': null,
        'floor_area': null,
        'has_dhw': true
      })
      this.onInput()
    },
    onDeleteZone (index) {
      this.model.splice(index, 1)
      this.onInput()
    }
  },
  components: {
    ListDeleteButton,
    ListButtonWrapper,
    ButtonWrapper,
    NumericInput,
    InfoBox,
    ZoneUsagePicker,
    DetailList,
    CheckInput
  }
}
</script>
<style>
.edit-zones-table .detail-list.zone-table > ul > li {
  grid-template-columns: 2.5fr 95px 85px 95px 1fr 1fr 1fr 1fr 1fr 50px;
  align-items: flex-start;
}
.edit-zones-table .hint {
  font-size: var(--font-xs);
  padding: var(--box-padding);
}
.edit-zones-table .source-info {
  color: var(--secondary-text-color);
  font-size: var(--font-xs);
}
.edit-zones-table .align-right {
  text-align: right;
}
</style>
