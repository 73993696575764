<!--
Edit form for PvMeasureSchema
-->
<i18n>
{
  "de": {
    "heatingTypeLabel": "Typ",
    "costsLabel": "Kosten *",
    "autoCostsHint": "Kosten leer lassen für automatische Kostenschätzung"
  }
}
</i18n>

<template>
  <div class="edit-heating-measure">
    <edit-heaters-table
      :grid-options="gridOptions"
      :heaters-results="heatersResults"
      v-model="model.heaters"
      :edit="isEditing"
      @input="onHeatersChange"
    />
    <form-row :label="$t('costsLabel')" class="costs-row">
      <numeric-input @input="onInput" :edit="isEditing" v-model="model.costs" :min="0" optional :units="'CHF'" class="limit-width" />
    </form-row>
    <div class="hint">* {{ $t('autoCostsHint') }}</div>
  </div>
</template>
<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin'

import EditHeatersTable from '@/components/building/edit/EditHeatersTable'
import FormRow from '@/components/shared/forms/FormRow'
import NumericInput from '@/components/shared/forms/NumericInput'

export default {
  props: {
    heatersResults: {
      type: Array
    },
    portfolio: {
      type: Object,
      required: true
    }
  },
  mixins: [ComplexFormMixin],
  computed: {
    gridOptions () {
      return this.portfolio.heat_grids.map(g => {
        return {
          id: g.identifier,
          label: g.name
        }
      })
    }
  },
  methods: {
    onHeatersChange (heaters) {
      this.model.heaters = heaters
      this.onInput()
    }
  },
  components: {
    FormRow,
    EditHeatersTable,
    NumericInput
  }
}
</script>
<style>
.edit-heating-measure .costs-row {
  margin-top: var(--spacing-m);
}
.edit-heating-measure .limit-width {
  width: 200px;
}
</style>
