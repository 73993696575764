<template>
  <div class="building-map">
    <mgl-map
      :accessToken="accessToken"
      :mapStyle.sync="mapStyle"
      class="map-container"
      :scrollZoom="false"
      :center="coordinates"
      :zoom="initialZoom"
      @load="onMapLoaded"
    >
      <!-- Adding navigation control -->
      <mgl-navigation-control position="top-left" />
      <!-- Adding GeoJSON layer -->
      <energy-grid-polygon-layer
        :building="building"
        sourceId="energyGridPolygonSource"
        layerId="energyGridPolygonLayer"
        @selectfeature="gridSelect"
        v-if="mapLoaded && building.energy_grids"
      />
      <energy-grid-point-layer
        :building="building"
        sourceId="energyGridPointSource"
        layerId="energyGridPointLayer"
        @selectfeature="gridSelect"
        v-if="mapLoaded && building.energy_grids"
      />
      <roof-tile-layer
        :building="building"
        sourceId="roofTilesSource"
        layerId="roofTilesLayer"
        @selectfeature="roofTileSelect"
        v-if="mapLoaded"
      />
      <parcel-layer
        :building="building"
        sourceId="parcelsSource"
        layerId="parcelsLayer"
        @selectfeature="parcelSelect"
        v-if="mapLoaded"
      />
      <fassade-tile-layer
        :building="building"
        sourceId="fassadeTilesSource"
        layerId="fassadeTilesLayer"
        @selectfeature="fassadeTileSelect"
        v-if="mapLoaded"
      />
      <egid-layer
        :building="building"
        sourceId="egidSource"
        layerId="egidLayer"
        @selectfeature="egidSelect"
        v-if="mapLoaded"
      />
      <roof-tile-popup :building="building" :feature="activeFeature" :coordinates="popupCoordinates" :showed="activeLayer === 'roofTiles'" @close="onPopupClose" />
      <fassade-tile-popup :feature="activeFeature" :coordinates="popupCoordinates" :showed="activeLayer === 'fassadeTiles'" @close="onPopupClose" />
      <parcel-popup :feature="activeFeature" :coordinates="popupCoordinates" :showed="activeLayer === 'parcels'" @close="onPopupClose" />
      <egid-popup :feature="activeFeature" :coordinates="popupCoordinates" :showed="activeLayer === 'egid'" @close="onPopupClose" />
      <energy-grid-popup :feature="activeFeature" :coordinates="popupCoordinates" :showed="activeLayer === 'energyGrids'" @close="onPopupClose" v-if="building.energy_grids" />
    </mgl-map>
  </div>
</template>

<script>
import { MglMap, MglNavigationControl } from 'vue-mapbox'
import FassadeTileLayer from '@/components/building/map/FassadeTileLayer'
import FassadeTilePopup from '@/components/building/map/FassadeTilePopup'
import RoofTileLayer from '@/components/building/map/RoofTileLayer'
import RoofTilePopup from '@/components/building/map/RoofTilePopup'
import ParcelLayer from '@/components/building/map/ParcelLayer'
import ParcelPopup from '@/components/building/map/ParcelPopup'
import EgidLayer from '@/components/building/map/EgidLayer'
import EgidPopup from '@/components/building/map/EgidPopup'
import EnergyGridPolygonLayer from '@/components/building/map/EnergyGridPolygonLayer'
import EnergyGridPointLayer from '@/components/building/map/EnergyGridPointLayer'
import EnergyGridPopup from '@/components/building/map/EnergyGridPopup'

export default {
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  name: 'building-map',
  created () {
    this.map = null
  },
  components: {
    MglMap,
    MglNavigationControl,
    FassadeTileLayer,
    FassadeTilePopup,
    RoofTileLayer,
    RoofTilePopup,
    ParcelLayer,
    ParcelPopup,
    EgidLayer,
    EgidPopup,
    EnergyGridPolygonLayer,
    EnergyGridPointLayer,
    EnergyGridPopup
  },
  data () {
    return {
      mapLoaded: false,
      accessToken: 'pk.eyJ1IjoicnVnZ2xlIiwiYSI6ImNrNjllYnhpcTBkdDAzbXBtZXd4OTlhazAifQ.94w7D1gcvHtzyGlM58VFog',
      mapStyle: 'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte.vt/style.json',
      activeLayer: undefined,
      activeFeature: undefined,
      popupCoordinates: [8, 48] // this can't be blank!  it won't be shown but pick something
    }
  },
  methods: {
    onMapLoaded (event) {
      this.map = event.map
      // console.log(this.map.listImages())
      /*
      example to load custom image (don't remove)
      this.map.loadImage('https://docs.mapbox.com/mapbox-gl-js/assets/cat.png', (error, image) => {
        if (error) {
          throw error
        }
        this.map.addImage('cat', image)
        this.mapLoaded = true
      }) */
      this.mapLoaded = true
    },
    onPopupClose (event) {
      this.activeLayer = undefined
      this.activeFeature = undefined
    },
    egidSelect (data) {
      this.activeLayer = 'egid'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    },
    parcelSelect (data) {
      this.activeLayer = 'parcels'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    },
    roofTileSelect (data) {
      this.activeLayer = 'roofTiles'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    },
    fassadeTileSelect (data) {
      this.activeLayer = 'fassadeTiles'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    },
    gridSelect (data) {
      this.activeLayer = 'energyGrids'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    }
  },
  computed: {
    coordinates () {
      return [(this.building.bbox[0] + this.building.bbox[2]) / 2, (this.building.bbox[1] + this.building.bbox[3]) / 2]
    },
    initialZoom () {
      // https://wiki.openstreetmap.org/wiki/Zoom_levels
      return Math.min(18, Math.floor(Math.log2(360 / (this.building.bbox[2] - this.building.bbox[0]))))
    }
  }
}
</script>

<style>
.building-map {
  position: relative;
}

.building-map .info-panel {
  position: absolute;
  top:1rem;
  right:1rem;
  width:300px;
}

.building-map .map-container {
  height: calc(8rem + 18vw);
}
</style>
