<i18n>
{
  "de": {
    "usageSummaryHeader": "Energiebezugsfläche und Nutzung",
    "missingZones": "Keine Zonen definiert",
    "hasUnknownZoneUsages": "Nutzung fehlt",
    "missingEnergyArea": "Fläche fehlt",
    "usageEstimatedLabel": "Nutzung unsicher",
    "areaEstimatedLabel": "Flächen unsicher",
    "areaGisLabel": "Flächen aus GIS-Daten abgeleitet"
  }
}
</i18n>

<template>
  <detail-list-item :title="$t('usageSummaryHeader')" expandable @expand="onModalOpen">
    <span v-if="totalEnergyArea">
      {{ formatNumber(totalEnergyArea, 0) }} m<sup>2</sup>
    </span>
    <span> {{ usageSummary }}</span>
    <missing-data-label v-if="areaFromGis">{{ $t('areaGisLabel') }}</missing-data-label>
    <missing-data-label v-if="totalEnergyArea === undefined">{{ $t('missingEnergyArea') }}</missing-data-label>
    <!-- <missing-data-label v-if="hasUnknownZoneUsages">{{ $t('hasUnknownZoneUsages')}}</missing-data-label> -->
    <missing-data-label v-if="building.initial_state.zones.zones.length === 0">{{ $t('missingZones')}}</missing-data-label>
    <template #modal>
      <zones-modal v-if="modalOpen" @close="onModalClose" :building="building" />
    </template>
  </detail-list-item>
</template>

<script>
import ModalMixin from '@/components/shared/ModalMixin'

import DetailListItem from '@/components/shared/lists/DetailListItem'
import MissingDataLabel from '@/components/shared/MissingDataLabel'
import ZonesModal from '@/components/building/overview/ZonesModal'

export default {
  mixins: [ModalMixin],
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  computed: {
    totalEnergyArea () {
      if (this.building.initial_state.result === null) {
        // No simulation, try to get area from energy_area input
        const ebfSum = this.building.initial_state.zones.zones.reduce((pv, cv) => pv + parseFloat(cv.energy_area), 0)
        if (!Number.isNaN(ebfSum)) {
          return ebfSum
        }
        return undefined
      }
      const sum = this.building.initial_state.result.zones.reduce((pv, cv) => pv + parseFloat(cv.energy_area.value), 0)
      if (Number.isNaN(sum)) {
        return undefined
      }
      return sum
    },
    areaFromGis () {
      if (this.building.initial_state.result !== null) {
        const fromGisCount = this.building.initial_state.result.zones.reduce((pv, cv) => pv + (cv.energy_area.source === 'GWR_EXTRAPOLATION' || cv.energy_area.source === 'SONNENDACH_EXTRAPOLATION') ? 1 : 0, 0)
        return fromGisCount
      }
      return undefined
    },
    hasUnknownZoneUsages () {
      return this.building.initial_state.zones.zones.find(z => z.usage === 'UNKNOWN')
    },
    usageSummary () {
      let zonesText = []
      for (let zoneIdx = 0; zoneIdx < this.building.initial_state.zones.zones.length; zoneIdx++) {
        const zone = this.building.initial_state.zones.zones[zoneIdx]
        const zoneName = this.$t(`_usageTypes.${zone.usage}`)
        const zoneShare = this.building.initial_state.result !== null && this.formatNumber(this.building.initial_state.result.zones[zoneIdx].energy_area.value / this.totalEnergyArea * 100, 0)
        if (Number.isNaN(zoneShare) || zoneShare === false || this.building.initial_state.zones.zones.length === 1) {
          zonesText.push(zoneName)
        } else {
          zonesText.push(`${zoneShare} % ${zoneName}`)
        }
      }
      return zonesText.join(', ')
    }
  },
  components: {
    DetailListItem,
    MissingDataLabel,
    ZonesModal
  }
}
</script>
