<i18n>
{
  "de": {
    "confirmDeleteMeasure": "Wollen Sie diese Massnahme wirklich löschen?",
    "scenarioMeasure": "Szenario",
    "plannedMeasure": "Geplant",
    "completedMeasure": "Umgesetzt"
  }
}
</i18n>
<template>
  <li class="measure-row">
    <section class="measure-table">
      <span class="measure-phase" v-if="measure.phase === 'SCENARIO'">
        <scenario-icon :color="scenario.color" :title="$t('_measurePhases.SCENARIO')" />
        {{ $t('scenarioMeasure') }}
      </span>
      <span class="measure-phase" v-if="measure.phase === 'PLANNED'">
        <img src="/icons/calendar.svg" :title="$t('_measurePhases.PLANNED')">
        {{ $t('plannedMeasure') }}
      </span>
      <span class="measure-phase" v-if="measure.phase === 'COMPLETED'">
        <img src="/icons/check.svg" :title="$t('_measurePhases.COMPLETED')">
        {{ $t('completedMeasure') }}
      </span>
      <span>{{ measure.year }}</span>
      <measure-summary :measure="measure" />
      <span v-if="measure.kpi && measure.kpi.ghg">
        {{ formatNumber(measure.kpi.ghg / 1000, 1) }} t
        (<span v-if="measure.kpi.ghg === lastKpi.ghg">-0 %</span>
        <span v-else-if="measure.kpi.ghg > lastKpi.ghg">+{{ formatNumber((measure.kpi.ghg - lastKpi.ghg) / lastKpi.ghg * 100, 0) }} %</span>
        <span v-else>{{ formatNumber((measure.kpi.ghg - lastKpi.ghg) / lastKpi.ghg * 100, 0) }} %</span>)
      </span>
      <span v-else>-</span>
      <energy-label :value="peRatio" v-if="peRatio !== null" />
      <span v-else>-</span>
      <energy-label :value="rhRatio" v-if="rhRatio !== null" />
      <span v-else>-</span>
      <list-button-wrapper>
        <list-details-button @click="openViewModal" />
        <list-edit-button @click="openEditModal" v-if="building.permissions.EDIT_MEASURES" />
        <list-delete-button @click="onDeleteMeasure" v-if="measure.phase !== 'SCENARIO' && building.permissions.EDIT_MEASURES" />
      </list-button-wrapper>
    </section>
    <view-measure-modal
      v-if="viewModalOpen"
      :measure="measure"
      :last-kpi="lastKpi"
      @close="closeViewModal"
    />
    <edit-measure-modal
      v-if="editModalOpen"
      :building="building"
      :portfolio="portfolio"
      :measure="measure"
      @close="closeEditModal"
    />
  </li>
</template>

<script>
import { mapActions } from 'vuex'
import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper'
import ListEditButton from '@/components/shared/lists/ListEditButton'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton'
import ListDetailsButton from '@/components/shared/lists/ListDetailsButton'
import EditMeasureModal from '@/components/building/measures/edit/EditMeasureModal'
import ViewMeasureModal from '@/components/building/measures/ViewMeasureModal'
import MeasureSummary from '@/components/building/measures/MeasureSummary'
import ScenarioIcon from '@/components/shared/ScenarioIcon'
import EnergyLabel from '@/components/shared/EnergyLabel'

export default {
  props: {
    portfolio: {
      type: Object
    },
    building: {
      type: Object,
      required: true
    },
    measure: {
      type: Object,
      required: true
    },
    lastKpi: {
      type: Object
    }
  },
  data: function () {
    return {
      editModalOpen: false,
      viewModalOpen: false
    }
  },
  computed: {
    scenario () {
      return this.portfolio.scenarios.find(s => s.id === this.measure.scenario_id)
    },
    rhRatio () {
      return this.measure.kpi && this.measure.kpi.rh_demand / this.measure.kpi.energy_area / this.measure.kpi.rh_limit
    },
    peRatio () {
      return this.measure.kpi && this.measure.kpi.pe / this.measure.kpi.energy_area / this.measure.kpi.pe_ref
    }
  },
  methods: {
    ...mapActions({
      deleteMeasure: 'building/deleteMeasure'
    }),
    openEditModal () {
      this.editModalOpen = true
    },
    closeEditModal () {
      this.editModalOpen = false
    },
    openViewModal () {
      this.viewModalOpen = true
    },
    closeViewModal () {
      this.viewModalOpen = false
    },
    async onDeleteMeasure () {
      if (confirm(this.$t('confirmDeleteMeasure'))) {
        try {
          await this.deleteMeasure(this.measure)
        } catch (error) {
          this.error = error
        }
      }
    }
  },
  components: {
    ListButtonWrapper,
    ListEditButton,
    ListDetailsButton,
    ListDeleteButton,
    EditMeasureModal,
    ViewMeasureModal,
    MeasureSummary,
    ScenarioIcon,
    EnergyLabel
  }
}
</script>

<style>
.measure-row .measure-phase img {
  vertical-align: middle;
  opacity: 0.7;
}
.measure-row .measure-table {
  display: contents;
}
</style>
