<i18n>
{
  "de": {
    "districtAvailabilityHeader": "Wärmenetze und Wärmequellen",
    "nearSuffix": "entfernt"
  }
}
</i18n>

<template>
  <detail-list-item :title="$t('districtAvailabilityHeader')" expandable @expand="onModalOpen">
    <div class="missing-data" v-if="building.energy_grids.state !== 'OK'">{{ $t(`_energyGridsState.${building.energy_grids.state}`) }}</div>
    <text-with-color v-else :color="districtAvailabilityColor">{{ districtAvailabilityText }}</text-with-color>
    <template #modal>
      <energy-grids-modal v-if="modalOpen" @close="onModalClose" :building="building" />
    </template>
  </detail-list-item>
</template>

<script>
import TextWithColor from '@/components/shared/TextWithColor'
import DetailListItem from '@/components/shared/lists/DetailListItem'
import EnergyGridsModal from '@/components/building/feasibility/EnergyGridsModal'

export default {
  data: function () {
    return {
      modalOpen: false
    }
  },
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  methods: {
    onModalOpen () {
      this.modalOpen = true
    },
    onModalClose () {
      this.modalOpen = false
    }
  },
  computed: {
    districtAvailability () {
      return this.building.energy_grids.energy_grids_simplified
    },
    districtAvailabilityColor () {
      let colors = {
        'HEAT_POTENTIAL_NEAR': 'yellow',
        'EXISTING': 'green',
        'EXISTING_NEAR': 'yellow',
        'PLANNED': 'yellow',
        'PLANNED_NEAR': 'yellow',
        'EVALUATION': 'yellow',
        'EVALUATION_NEAR': 'yellow',
        'NONE': 'red',
        'ERROR': 'gray',
        'UNKNOWN': 'gray'
      }
      return colors[this.districtAvailability]
    },
    districtAvailabilityText () {
      if (this.building.energy_grids.state !== 'OK') {
        return this.$t('noGisData')
      }
      if (this.building.energy_grids.energy_grids.length > 0) {
        let gridName = ''
        if (this.building.energy_grids.energy_grids[0].name) {
          gridName = ` "${this.building.energy_grids.energy_grids[0].name}"`
        }
        if (this.building.energy_grids.energy_grids[0].distance > 0) {
          let gridDistance = (Math.ceil(this.building.energy_grids.energy_grids[0].distance / 10) * 10) + ' m'
          return this.$t(`_gridAvailability.${this.districtAvailability}`) + gridName + ' (' + gridDistance + ' ' + this.$t('nearSuffix') + ')'
        } else {
          return this.$t(`_gridAvailability.${this.districtAvailability}`) + gridName
        }
      } else {
        return this.$t(`_gridAvailability.${this.districtAvailability}`)
      }
    }
  },
  components: {
    DetailListItem,
    TextWithColor,
    EnergyGridsModal
  }
}
</script>
<style scoped>
.missing-data {
  color:var(--warning-color);
  font-size: var(--font-xs);
}
</style>
