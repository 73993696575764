<template>
  <detail-list-item class="tag-group-summary-row" :title="tagGroup.name" expandable @expand="onModalOpen">
    <span v-if="selectedTag">{{ selectedTag.name }}</span>
    <span v-else>-</span>
    <template #modal>
      <modal v-if="modalOpen" @close="onModalClose">
        <template v-slot:header>
          <h2>{{ tagGroup.name }}</h2>
        </template>
        <edit-tag-group
          :building="building"
          :allow-edit="building.permissions.EDIT_HEATING"
          :portfolio="portfolio"
          :tag-group="tagGroup"
        />
      </modal>
    </template>
  </detail-list-item>
</template>
<script>
import Modal from '@/components/shared/Modal'
import ModalMixin from '@/components/shared/ModalMixin'

import EditTagGroup from '@/components/building/edit/EditTagGroup'
import DetailListItem from '@/components/shared/lists/DetailListItem'

export default {
  mixins: [ModalMixin],
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    building: {
      type: Object,
      required: true
    },
    tagGroup: {
      type: Object,
      required: true
    }
  },
  computed: {
    selectedTag () {
      return this.building.tags.find(t => t.gname === this.tagGroup.name)
    }
  },
  components: {
    Modal,
    DetailListItem,
    EditTagGroup
  }
}
</script>
