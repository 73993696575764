<i18n>
{
  "de": {
    "geothermalTitle": "Zulässigkeit Wärmenutzung",
    "reloadLabel": "Zulässigkeiten neu abfragen",
    "pendingMessage": "Daten werden abgefragt und Gebäude neu simuliert...",
    "lastUpdateLabel": "Daten aktualisiert am",
    "ewsAdmissibility": "Erdwärmesonden",
    "gwAdmissibility": "Wärmenutzung Grundwasser",
    "thermoElementsAdmissibility": "Thermoaktive Elemente",
    "liquidRegistersAdmissibility": "Erdregister/Energiekörbe mit flüssigen Wärmeträgern",
    "airRegistersAdmissibility": "Erdregister/Energiekörbe mit Luft betrieben",
    "sourceLabel": "Quelle",
    "sourceCantonLabel": "GIS Kanton",
    "openGisBrowser": "GIS-Browser öffnen",
    "maxEwsDepthLabel": "Tiefenbeschränkung"
  }
}
</i18n>

<template>
    <modal @close="$emit('close', $event)" class="geothermal-modal">
        <template v-slot:header>
            <h2>{{ $t('geothermalTitle') }}</h2>
        </template>
        <div v-if="error">
          <p>{{ error }}</p>
        </div>
        <div v-if="pending">
          <p>{{ $t('pendingMessage') }}</p>
        </div>
        <div class="missing-data" v-else-if="building.geothermal.state !== 'OK'">
          {{ $t(`_geothermalState.${building.geothermal.state}`) }}
        </div>
        <div v-else class="admissibilities">
          <detail-list>
            <detail-list-item :title="$t('ewsAdmissibility')">
              <text-with-color :color="ewsAdmissibilityColor">
                <span>
                  {{ $t(`_admissibility.${building.geothermal.admissibilities.ews}`) }}
                  <span v-if="building.geothermal.admissibilities.ews_max_depth">({{ $t('maxEwsDepthLabel') }}: {{ building.geothermal.admissibilities.ews_max_depth }} m)</span>
                </span>
              </text-with-color>
              <template #rightContent v-if="building.geothermal.admissibilities.ews_remarks">
                {{ building.geothermal.admissibilities.ews_remarks }}
              </template>
            </detail-list-item>
            <detail-list-item :title="$t('gwAdmissibility')">
              <text-with-color :color="gwAdmissibilityColor">{{ $t(`_admissibility.${building.geothermal.admissibilities.gw}`) }}</text-with-color>
              <template #rightContent v-if="building.geothermal.admissibilities.gw_remarks">
                {{ building.geothermal.admissibilities.gw_remarks }}
              </template>
            </detail-list-item>
            <detail-list-item :title="$t('thermoElementsAdmissibility')" v-if="building.geothermal.admissibilities.thermo_elements !== 'UNKNOWN'">
              <text-with-color :color="thermoElementsAdmissibilityColor">{{ $t(`_admissibility.${building.geothermal.admissibilities.thermo_elements}`) }}</text-with-color>
              <template #rightContent v-if="building.geothermal.admissibilities.thermo_elements_remarks">
                {{ building.geothermal.admissibilities.thermo_elements_remarks }}
              </template>
            </detail-list-item>
            <detail-list-item :title="$t('liquidRegistersAdmissibility')" v-if="building.geothermal.admissibilities.liquid_registers !== 'UNKNOWN'">
              <text-with-color :color="liquidRegistersAdmissibilityColor">{{ $t(`_admissibility.${building.geothermal.admissibilities.liquid_registers}`) }}</text-with-color>
              <template #rightContent v-if="building.geothermal.admissibilities.liquid_registers_remarks">
                {{ building.geothermal.admissibilities.liquid_registers_remarks }}
              </template>
            </detail-list-item>
            <detail-list-item :title="$t('airRegistersAdmissibility')" v-if="building.geothermal.admissibilities.air_registers !== 'UNKNOWN'">
              <text-with-color :color="airRegistersAdmissibilityColor">{{ $t(`_admissibility.${building.geothermal.admissibilities.air_registers}`) }}</text-with-color>
              <template #rightContent v-if="building.geothermal.admissibilities.air_registers_remarks">
                {{ building.geothermal.admissibilities.air_registers_remarks }}
              </template>
            </detail-list-item>
          </detail-list>
        </div>
        <button-wrapper v-if="!pending">
          <p v-if="building.geothermal.state === 'OK'">{{ $t('lastUpdateLabel') }}: {{ lastUpdateDate }}</p>
          <p v-if="building.geothermal.state === 'OK'">
            {{ $t('sourceLabel') }}: {{ $t('sourceCantonLabel') }} {{ building.location_info.data.canton }}
            <span v-if="building.geothermal.gis_url">(<a :href="building.geothermal.gis_url" target="_blank">{{ $t('openGisBrowser') }}</a>)</span>
          </p>
          <button class="button" v-if="building.permissions.REFRESH_GEOTHERMAL" @click="onReload">{{ $t('reloadLabel') }}</button>
        </button-wrapper>
    </modal>
</template>

<script>
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import Modal from '@/components/shared/Modal'
import TextWithColor from '@/components/shared/TextWithColor'
import DetailList from '@/components/shared/lists/DetailList'
import DetailListItem from '@/components/shared/lists/DetailListItem'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      'pending': null,
      'error': null
    }
  },
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  computed: {
    lastUpdateDate () {
      return new Date(this.building.geothermal.last_update).toLocaleDateString('de-DE')
    },
    ewsAdmissibilityColor () {
      let colors = {
        'ADMISSIBLE': 'green',
        'LIMITATIONS': 'yellow',
        'NON_ADMISSIBLE': 'red',
        'BAD_GEOLOGY': 'red',
        'ERROR': 'gray',
        'UNKNOWN': 'gray'
      }
      return colors[this.building.geothermal.admissibilities.ews]
    },
    gwAdmissibilityColor () {
      let colors = {
        'ADMISSIBLE': 'green',
        'LIMITATIONS': 'yellow',
        'NON_ADMISSIBLE': 'red',
        'BAD_GEOLOGY': 'red',
        'ERROR': 'gray',
        'UNKNOWN': 'gray'
      }
      return colors[this.building.geothermal.admissibilities.gw]
    },
    thermoElementsAdmissibilityColor () {
      let colors = {
        'ADMISSIBLE': 'green',
        'LIMITATIONS': 'yellow',
        'NON_ADMISSIBLE': 'red',
        'ERROR': 'gray',
        'UNKNOWN': 'gray'
      }
      return colors[this.building.geothermal.admissibilities.thermo_elements]
    },
    liquidRegistersAdmissibilityColor () {
      let colors = {
        'ADMISSIBLE': 'green',
        'LIMITATIONS': 'yellow',
        'NON_ADMISSIBLE': 'red',
        'ERROR': 'gray',
        'UNKNOWN': 'gray'
      }
      return colors[this.building.geothermal.admissibilities.liquid_registers]
    },
    airRegistersAdmissibilityColor () {
      let colors = {
        'ADMISSIBLE': 'green',
        'LIMITATIONS': 'yellow',
        'NON_ADMISSIBLE': 'red',
        'ERROR': 'gray',
        'UNKNOWN': 'gray'
      }
      return colors[this.building.geothermal.admissibilities.air_registers]
    }
  },
  methods: {
    ...mapActions({
      queryGeothermal: 'building/queryGeothermal'
    }),
    async onReload () {
      this.pending = true
      try {
        await this.queryGeothermal({
          'id': this.building.id
        })
      } catch (error) {
        this.error = error
      }
      this.pending = false
    }
  },
  components: {
    ButtonWrapper,
    TextWithColor,
    DetailList,
    DetailListItem,
    Modal
  }
}
</script>

<style scoped>
.missing-data {
  color:var(--warning-color);
}
.admissibilities div ~ div{
  margin-top: var(--spacing-s);
}
</style>
<style>
.geothermal-modal .detail-list .detail-list-item.has-right-content {
  grid-template-columns: 1fr 2fr;
}
</style>
