<i18n>
{
  "de": {
      "measuresTitle": "Massnahmen",
      "addNewLabel": "Massnahme hinzufügen",
      "addMeasureTitle": "Massnahme hinzufügen",
      "yearTitle": "Jahr",
      "actionsTitle": "Massnahmen",
      "costsTitle": "Kosten",
      "ghgTitle": "GHG-Emissionen",
      "rhTitle": "Raumwärme",
      "peTitle": "Primärenergie",
      "noMeasures": "Keine Massnahmen vorhanden"
  }
}
</i18n>
<template>
  <div class="measures-list">
    <scenario-picker v-model="selectedScenario" :portfolio="portfolio" class="scenario-picker" />
    <div v-if="selectedScenario && !measures.length">{{ $t('noMeasures') }}</div>
    <detail-list v-if="selectedScenario && measures.length">
      <template #header>
        <span></span>
        <span>{{ $t('yearTitle') }}</span>
        <span>{{ $t('actionsTitle') }}</span>
        <span>{{ $t('ghgTitle') }}</span>
        <span>{{ $t('peTitle') }}</span>
        <span>{{ $t('rhTitle') }}</span>
        <span></span>
      </template>
      <measure-row
        v-for="(measure, index) in measures"
        :key="index"
        :measure="measure"
        :last-kpi="index > 0 ? measures[index-1].kpi : building.kpi"
        :building="building"
        :portfolio="portfolio"
      />
    </detail-list>
    <button-wrapper v-if="building.permissions.EDIT_MEASURES">
      <button class="button" @click.prevent="onAdd">{{ $t('addNewLabel') }}</button>
    </button-wrapper>
    <edit-measure-modal :grid-options="gridOptions" :building="building" v-if="addNew" @close="onAddClose" :portfolio="portfolio" />
  </div>
</template>

<script>
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import ScenarioPicker from '@/components/shared/ScenarioPicker'
import DetailList from '@/components/shared/lists/DetailList'
import MeasureRow from '@/components/building/measures/MeasureRow'
import EditMeasureModal from '@/components/building/measures/edit/EditMeasureModal'

export default {
  data: function () {
    return {
      addNew: false,
      selectedScenario: null
    }
  },
  props: {
    portfolio: {
      type: Object
    },
    building: {
      type: Object,
      required: true
    }
  },
  computed: {
    measures () {
      return this.selectedScenario && this.building.measures.filter(m => !m.scenario_id || m.scenario_id === this.selectedScenario.id)
    },
    gridOptions () {
      return this.portfolio.heat_grids.map(g => {
        return {
          id: g.identifier,
          label: g.name
        }
      })
    }
  },
  methods: {
    onAdd: function () {
      this.addNew = true
    },
    onAddClose: function () {
      this.addNew = false
    }
  },
  components: {
    ButtonWrapper,
    DetailList,
    MeasureRow,
    ScenarioPicker,
    EditMeasureModal
  }
}
</script>
<style>
.measures-list .scenario-picker {
  margin-bottom: var(--spacing-s);
}

.measures-list > .detail-list > ul > li {
  grid-template-columns: 120px 40px 1fr 120px 90px 90px 180px;
  align-items: start;
}

@media screen and (min-width: 52.9rem) {
  .measures-list {
    grid-column: 1 / -1;
  }
}
</style>
