<template>
  <div class="pv-minimap">
    <mgl-map :accessToken="accessToken" :mapStyle.sync="mapStyle" class="map-container" :scrollZoom="false" :center="coordinates" :zoom="initialZoom">
      <!-- Adding navigation control -->
      <mgl-navigation-control position="top-left" />
      <!-- Adding GeoJSON layer -->
      <pv-measure-layer
        :building="building"
        :measure="measure"
        sourceId="measureTilesSource"
        layerId="measureTilesLayer"
        @selectfeature="measureTileSelect"
      />
      <pv-measure-popup :feature="activeFeature" :coordinates="popupCoordinates" :showed="activeLayer === 'measureTiles'" @close="onPopupClose" />
    </mgl-map>
  </div>
</template>

<script>
import { MglMap, MglNavigationControl } from 'vue-mapbox'
import PvMeasureLayer from '@/components/building/map/PvMeasureLayer'
import PvMeasurePopup from '@/components/building/map/PvMeasurePopup'

export default {
  props: {
    building: {
      type: Object,
      required: true
    },
    measure: {
      type: Object,
      required: true
    }
  },
  name: 'pv-minimap',
  components: {
    MglMap,
    MglNavigationControl,
    PvMeasureLayer,
    PvMeasurePopup
  },
  data () {
    return {
      accessToken: 'pk.eyJ1IjoicnVnZ2xlIiwiYSI6ImNrNjllYnhpcTBkdDAzbXBtZXd4OTlhazAifQ.94w7D1gcvHtzyGlM58VFog',
      mapStyle: 'mapbox://styles/ruggle/ckaxzpq0l0f5f1ipctig4ncfk',
      activeLayer: undefined,
      activeFeature: undefined,
      popupCoordinates: [8, 48] // this can't be blank!  it won't be shown but pick something
    }
  },
  methods: {
    onPopupClose (event) {
      this.activeLayer = undefined
      this.activeFeature = undefined
    },
    measureTileSelect (data) {
      this.activeLayer = 'measureTiles'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    }
  },
  computed: {
    coordinates () {
      return [(this.building.bbox[0] + this.building.bbox[2]) / 2, (this.building.bbox[1] + this.building.bbox[3]) / 2]
    },
    initialZoom () {
      // https://wiki.openstreetmap.org/wiki/Zoom_levels
      return Math.min(18, Math.floor(Math.log2(360 / (this.building.bbox[2] - this.building.bbox[0]))))
    }
  }
}
</script>

<style>
.building-map {
  position: relative;
}

.building-map .info-panel {
  position: absolute;
  top:1rem;
  right:1rem;
  width:300px;
}

.building-map .map-container {
  height: calc(8rem + 18vw);
}
</style>
