<i18n>
{
  "de": {
    "fassadeTileTitle": "Fassadenfläche",
    "fassadeTileArea": "Fläche",
    "fassadeTileIrradiation": "Jährliche Einstrahlung",
    "fassadeTileOrientation": "Orientierung",
    "fassadeTileClass": "Eignung für PV",
    "fassadeTileClass_5": "Top",
    "fassadeTileClass_4": "Sehr gut",
    "fassadeTileClass_3": "Gut",
    "fassadeTileClass_2": "Mittel",
    "fassadeTileClass_1": "Gering",
    "fassadeTileClass_small": "Zu Klein"
  }
}
</i18n>
<template>
  <MapboxPopup
    :coordinates="coordinates"
    :showed="showed"
    anchor="top"
    @close="onPopupClose"
  >
    <div class="fassade-tile-popup">
      <template v-if="feature !== undefined">
        <div class="title">{{ $t('fassadeTileTitle') }} {{ feature.properties.id }}</div>
        <div class="two-cols">
          <span>{{ $t('fassadeTileArea') }}</span><span>{{ formatNumber(feature.properties.area, 0) }} m<sup>2</sup></span>
          <span>{{ $t('fassadeTileClass') }}</span><span><span :class="[`ranking-${feature.properties.solar_class}`]">{{ $t(`fassadeTileClass_${feature.properties.solar_class}`) }}</span></span>
          <span>{{ $t('fassadeTileIrradiation') }}</span><span>{{ formatNumber(feature.properties.irradiation, 0) }} kWh/m<sup>2</sup></span>
          <span>{{ $t('fassadeTileOrientation') }}</span><span>{{ formatNumber(feature.properties.orientation, 0) }}°</span>
        </div>
      </template>
    </div>
  </MapboxPopup>
</template>

<script>
import MapboxPopup from '@/components/shared/MapboxPopup'

export default {
  props: {
    feature: {
      type: Object
    },
    coordinates: {
      type: Array,
      required: true
    },
    showed: {
      type: Boolean,
      required: true
    }
  },
  components: {
    MapboxPopup
  },
  computed: {
  },
  methods: {
    onPopupClose (event) {
      this.$emit('close', event)
    }
  }
}
</script>

<style>
.fassade-tile-popup {
  min-width:400px;
}
.fassade-tile-popup .two-cols {
  display: grid;
  grid-template-columns: 130px 1fr;
}

.fassade-tile-popup .title {
  font-weight: 600;
  margin-bottom: var(--spacing-m);
}

.fassade-tile-popup .ranking-1::before,
.fassade-tile-popup .ranking-2::before,
.fassade-tile-popup .ranking-3::before,
.fassade-tile-popup .ranking-4::before,
.fassade-tile-popup .ranking-5::before,
.fassade-tile-popup .ranking-small::before {
  content: "";
  display: inline-block;
  margin-right: 0.3rem;
  place-self: center;
  width: 1.3rem;
  height: .8rem;
  border: var(--box-border);
  vertical-align: text-top;
}

.fassade-tile-popup .ranking-1::before {
  background-color: var(--ranking-5-color);
}

.fassade-tile-popup .ranking-2::before {
  background-color: var(--ranking-4-color);
}

.fassade-tile-popup .ranking-3::before {
  background-color: var(--ranking-3-color);
}

.fassade-tile-popup .ranking-4::before {
  background-color: var(--ranking-2-color);
}

.fassade-tile-popup .ranking-5::before {
  background-color: var(--ranking-1-color);
}

</style>
