<template>
  <detail-list-item class="bubble-summary-row">
    <bubble-chart-wrapper key="buildingbubble" :buildings="buildings" :highlight-building-id="highlightBuildingId" @click="onBubbleClick" />
  </detail-list-item>
</template>

<script>
import DetailListItem from '@/components/shared/lists/DetailListItem'
import BubbleChartWrapper from '@/components/portfolio/kpi/charts/BubbleChartWrapper'

export default {
  data: function () {
    return {
      modalOpen: false
    }
  },
  props: {
    buildings: {
      type: Array,
      required: true
    },
    highlightBuildingId: {
      type: Number
    }
  },
  methods: {
    onBubbleClick (building) {
      this.$router.push({ name: 'building', params: { portfolio_id: building.portfolio_id, building_id: building.id } })
    }
  },
  components: {
    DetailListItem,
    BubbleChartWrapper
  }
}
</script>
<style>
.bubble-summary-row .bubble-chart {
  height:250px;
}
</style>
