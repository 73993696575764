<i18n>
{
  "de": {
    "usableFractionTitle": "Anteil nutzbar",
    "remarksTitle": "Bemerkungen"
  }
}
</i18n>

<template>
  <edit-container :bind-form-state="formState" @container="onFormEvent" class="edit-roof-tile">
    <form-row :label="$t('usableFractionTitle')">
      <numeric-input optional v-model="model.usable_fraction" :min="0" :max="1" :edit="isEditing" />
    </form-row>
    <form-row :label="$t('remarksTitle')" class="remarks-field">
      <text-input v-model="model.remarks" :edit="isEditing" />
    </form-row>
  </edit-container>
</template>
<script>
import { mapActions } from 'vuex'
import ApiFormMixin from '@/components/shared/forms/ApiFormMixin'
import EditContainer from '@/components/shared/forms/EditContainer'

import FormRow from '@/components/shared/forms/FormRow'
import NumericInput from '@/components/shared/forms/NumericInput'
import TextInput from '@/components/shared/forms/TextInput'

export default {
  props: {
    building: {
      type: Object,
      required: true
    },
    feature: {
      type: Object,
      required: true
    }
  },
  mixins: [
    ApiFormMixin
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation */
  ],
  components: {
    EditContainer,
    FormRow,
    NumericInput,
    TextInput
  },
  methods: {
    ...mapActions({
      updateBuilding: 'building/updateBuilding'
    }),
    resetModel () {
      const tile = this.building.feasibility.roof_tiles.find(t => t.id === this.feature.properties.id)
      if (tile !== undefined) {
        this.model = { ...tile }
      } else {
        this.model = {
          'usable_fraction': null,
          'remarks': null
        }
      }
    },
    async saveHandler () {
      let newFeasibility = { ...this.building.feasibility }
      newFeasibility.roof_tiles = newFeasibility.roof_tiles.filter(t => t.id !== this.feature.properties.id)
      newFeasibility.roof_tiles.push({
        'id': this.feature.properties.id,
        ...this.model
      })
      await this.updateBuilding({
        'id': this.building.id,
        'feasibility': newFeasibility
      })
    }
  }
}
</script>
<style>
.edit-roof-tile .remarks-field input {
  width: 200px;
}
</style>
