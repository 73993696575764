<i18n>
{
  "de": {
    "pvModules": "Anzahl PV-Module",
    "pvCapacity": "PV-Leistung",
    "pvProduction": "Spezifischer Stromertrag",
    "pvCoverage": "Belegung",
    "pvCoverageFull": "Vollständige Nutzung",
    "pvCoveragePartial": "Teilweise Nutzung"
  }
}
</i18n>
<template>
  <MapboxPopup
    :coordinates="coordinates"
    :showed="showed"
    anchor="top"
    @close="onPopupClose"
  >
    <div class="measure-tile-popup">
      <template v-if="feature !== undefined">
        <div class="two-cols">
          <span>{{ $t('pvCoverage') }}</span><span v-if="feature.properties.color_category === 'full'">{{ $t('pvCoverageFull') }}</span><span v-else>{{ $t('pvCoveragePartial') }}</span>
          <span>{{ $t('pvModules') }}</span><span>{{ feature.properties.n_modules }}</span>
          <span>{{ $t('pvCapacity') }}</span><span>{{ feature.properties.capacity }} kWp</span>
        </div>
      </template>
    </div>
  </MapboxPopup>
</template>

<script>
import MapboxPopup from '@/components/shared/MapboxPopup'

export default {
  props: {
    feature: {
      type: Object
    },
    coordinates: {
      type: Array,
      required: true
    },
    showed: {
      type: Boolean,
      required: true
    }
  },
  components: {
    MapboxPopup
  },
  computed: {
  },
  methods: {
    onPopupClose (event) {
      this.$emit('close', event)
    }
  }
}
</script>

<style>
.measure-tile-popup .two-cols {
  display: grid;
  grid-template-columns: 130px 1fr;
}

.measure-tile-popup .title {
  font-weight: 600;
  margin-bottom: var(--spacing-m);
}

</style>
