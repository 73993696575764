<i18n>
{
  "de": {
  }
}
</i18n>
<template>
  <div class="kpi-change-row">
    <div class="form-label">{{ label }}</div>
    <div v-if="before !== null">
      {{ formatNumber(before, 0) }} {{ units }}
      <energy-label class="measure-energy-label" :value="energyLabelBeforeRatio" v-if="energyLabelBeforeRatio !== undefined" />
    </div>
    <div v-else>-</div>
    <div v-if="after !== null">
      {{ formatNumber(after, 0) }} {{ units }}
      <energy-label class="measure-energy-label" :value="energyLabelAfterRatio" v-if="energyLabelAfterRatio !== undefined" />
    </div>
    <div v-else>-</div>
    <div v-if="before === 0">-</div>
    <div v-else-if="after === before">-</div>
    <div v-else-if="after > before">+{{ formatNumber((after - before) / before * 100, 0) }} %</div>
    <div v-else>{{ formatNumber((after - before) / before * 100, 0) }} %</div>
  </div>
</template>

<script>
import EnergyLabel from '@/components/shared/EnergyLabel'

export default {
  props: {
    label: {
      type: String
    },
    before: {
      type: Number,
      required: true
    },
    after: {
      type: Number,
      required: true
    },
    units: {
      type: String,
      required: true
    },
    energyLabelBeforeRatio: {
      type: Number
    },
    energyLabelAfterRatio: {
      type: Number
    }
  },
  computed: {
  },
  methods: {
  },
  components: {
    EnergyLabel
  }
}
</script>

<style>
.kpi-change-row {
  display: contents;
}
.kpi-change-row .measure-energy-label {
  display: inline-block;
  vertical-align: bottom;
  margin-left: var(--spacing-s);
}
</style>
