<template>
  <MglGeojsonLayer
    :sourceId="sourceId"
    :source="sourceConfig"
    :layerId="layerId"
    :layer="layerConfig"
    @click="layerClick"
    @mousemove="mouseEvent"
    @mouseleave="mouseEvent"
  />
</template>
<script>
import { MglGeojsonLayer } from 'vue-mapbox'

export default {
  props: {
    building: {
      type: Object,
      required: true
    },
    measure: {
      type: Object,
      required: false
    },
    sourceId: {
      type: String,
      required: true
    },
    layerId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      hoverFeature: undefined
    }
  },
  components: {
    MglGeojsonLayer
  },
  methods: {
    layerClick (event) {
      let features = event.map.queryRenderedFeatures(event.mapboxEvent.point, {
        layers: [this.layerId]
      })
      if (features.length > 0) {
        this.$emit('selectfeature', {
          'coordinates': [event.mapboxEvent.lngLat.lng, event.mapboxEvent.lngLat.lat],
          'feature': features[0]
        })
      }
    },
    mouseEvent (event) {
      let features = event.map.queryRenderedFeatures(event.mapboxEvent.point, {
        layers: [this.layerId]
      })
      if (this.hoverFeature !== undefined) {
        event.map.setFeatureState(
          { source: this.sourceId, id: this.hoverFeature.id },
          { hover: false }
        )
      }
      if (features.length > 0) {
        this.hoverFeature = features[0]
        event.map.setFeatureState(
          { source: this.sourceId, id: this.hoverFeature.id },
          { hover: true }
        )
      } else {
        this.hoverFeature = undefined
      }
    }
  },
  computed: {
    sourceConfig () {
      return {}
    },
    layerConfig () {
      return {}
    }
  }
}
</script>
