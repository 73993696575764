<i18n>
{
  "de": {
    "buttonLabel": "Details anzeigen"
  }
}
</i18n>
<template>
  <list-button @click="onClick" :img-src="'/icons/bar-chart-2.svg'" :label="$t('buttonLabel')" />
</template>
<script>
import ListButton from '@/components/shared/lists/ListButton'
export default {
  components: {
    ListButton
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>
