<template>
  <div class="text-with-color" :class="colorClasses"><slot /></div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true
    }
  },
  computed: {
    colorClasses () {
      return {
        red: this.color === 'red',
        yellow: this.color === 'yellow',
        green: this.color === 'green',
        gray: this.color === 'gray'
      }
    }
  }
}
</script>

<style>
.text-with-color {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--box-padding-h);
}

.text-with-color::before {
  content: "";
  place-self: center;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  border: var(--box-border);
}

.text-with-color.red::before,
.text-with-color.yellow::before,
.text-with-color.green::before,
.text-with-color.gray::before {
  border: none;
}

.text-with-color.red::before {
  background-color: #c65051;
}

.text-with-color.yellow::before {
  background-color: #f1c757;
}

.text-with-color.green::before {
  background-color: #aaff99;
}

.text-with-color.gray::before {
  background-color: #999999;
}
</style>
