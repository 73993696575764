<i18n>
{
  "de": {
    "parcelTitle": "Parzelle",
    "parcelArea": "Grundstückfläche",
    "parcelNr": "Nr"
  }
}
</i18n>
<template>
  <MapboxPopup
    :coordinates="coordinates"
    :showed="showed"
    anchor="top"
    @close="onPopupClose"
  >
    <div class="parcel-popup">
      <template v-if="feature !== undefined">
        <div class="title">{{ $t('parcelTitle') }}</div>
        <div v-if="feature.properties.parcel_nr !== 'null'">{{ $t('parcelNr') }}: {{ feature.properties.parcel_nr }} </div>
        <div>{{ $t('parcelArea') }}: {{ formatNumber(feature.properties.area, 0) }} m<sup>2</sup></div>
      </template>
    </div>
  </MapboxPopup>
</template>

<script>
import MapboxPopup from '@/components/shared/MapboxPopup'

export default {
  props: {
    feature: {
      type: Object
    },
    coordinates: {
      type: Array,
      required: true
    },
    showed: {
      type: Boolean,
      required: true
    }
  },
  components: {
    MapboxPopup
  },
  computed: {
  },
  methods: {
    onPopupClose (event) {
      this.$emit('close', event)
    }
  }
}
</script>

<style>
.parcel-popup .title {
  font-weight: 600;
  margin-bottom: var(--spacing-m);
}
</style>
