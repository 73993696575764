<i18n>
{
  "de": {
    "title": "Kennzahlen",
    "ghgLabel": "GHG -Emissionen",
    "peLabel": "Primärenergie",
    "rhLabel": "Raumwärme",
    "electricityLabel": "Strombedarf",
    "pvLabel": "Stromproduktion"
  }
}
</i18n>

<template>
  <detail-list :title="$t('title')" class="kpi-overview">
    <bubble-summary-row :buildings="portfolio.buildings" :highlight-building-id="building.id" v-if="portfolio.buildings && building" />
    <kpi-summary-row :kpi-type="'ghg_s1'" :building="building" :portfolio="portfolio" />
    <kpi-summary-row :kpi-type="'ghg'" :building="building" :portfolio="portfolio" />
    <kpi-summary-row :kpi-type="'pe'" :building="building" :portfolio="portfolio" />
    <kpi-summary-row :kpi-type="'ee'" :building="building" :portfolio="portfolio" />
    <rh-summary-row :building="building" :portfolio="portfolio" />
    <dhw-summary-row :building="building" />
    <heating-power-summary-row :building="building" />
    <electricity-demand-summary-row :building="building" :portfolio="portfolio" />
    <electricity-production-summary-row :building="building" :portfolio="portfolio" />
  </detail-list>
</template>

<script>
import DetailList from '@/components/shared/lists/DetailList'
import BubbleSummaryRow from '@/components/building/kpi/BubbleSummaryRow'
import KpiSummaryRow from '@/components/building/kpi/KpiSummaryRow'
import RhSummaryRow from '@/components/building/kpi/RhSummaryRow'
import DhwSummaryRow from '@/components/building/kpi/DhwSummaryRow'
import HeatingPowerSummaryRow from '@/components/building/kpi/HeatingPowerSummaryRow'
import ElectricityDemandSummaryRow from '@/components/building/kpi/ElectricityDemandSummaryRow'
import ElectricityProductionSummaryRow from '@/components/building/kpi/ElectricityProductionSummaryRow'

export default {
  props: {
    portfolio: {
      type: Object
    },
    building: {
      type: Object,
      required: true
    }
  },
  components: {
    DetailList,
    BubbleSummaryRow,
    KpiSummaryRow,
    RhSummaryRow,
    DhwSummaryRow,
    HeatingPowerSummaryRow,
    ElectricityDemandSummaryRow,
    ElectricityProductionSummaryRow
  }
}
</script>

<style>
.kpi-overview .with-energy-label {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: var(--box-padding-h);
}
</style>
