<i18n>
{
  "de": {
    "modalTitle": "Strombedarf",
    "demandTotalLabel": "Modellierter Strombedarf",
    "measuredDemandTotalLabel": "Gemessener Strombedarf (Mittelwert)",
    "demandByUsage": "Nach Verwendungszweck",
    "currentTitle": "Aktuelle Kennzahlen",
    "measuredTitle": "Messwerte",
    "histogramTitle": "Im Vergleich mit anderen Liegenschaften",
    "projectionTitle": "Absenkpfad"
  }
}
</i18n>

<template>
  <modal @close="$emit('close', $event)" class="electricity-demand-modal" wide>
    <template v-slot:header>
      <h2>{{ $t('modalTitle') }}</h2>
      <h3>{{ building.identifier }} / {{ building.description }}</h3>
    </template>
    <tabs>
      <tab :title="$t('currentTitle')">
        <div class="current-kpis">
          <detail-list>
            <detail-list-item :title="$t('demandTotalLabel')">
              <div class="two-columns">
                <span>{{ formatNumber(building.kpi.el_demand / 1000, 1) }} MWh</span>
                <span>{{ formatNumber(building.kpi.el_demand / building.kpi.energy_area, 1) }} kWh/m<sup>2</sup></span>
              </div>
            </detail-list-item>
          </detail-list>
          <div>
            <h3>{{ $t('histogramTitle') }}</h3>
            <histogram-chart
              :key="'rh_histogram'"
              :group-by-key="'el_demand'"
              area-specific
              :buildings="portfolio.buildings"
              :highlight-value="building.kpi.el_demand / building.kpi.energy_area"
              class="histogram-chart"
            />
          </div>
        </div>
      </tab>
      <tab :title="$t('measuredTitle')">
        <measured-kpi-chart :kpi-type="'ee'" class="demand-chart" :portfolio="portfolio" :query="{ building_id: this.building.id }" />
      </tab>
      <tab :title="$t('projectionTitle')">
        <scenario-picker v-model="selectedScenario" :portfolio="portfolio" />
        <h4>{{ $t('demandByUsage') }}</h4>
        <kpi-by-usage-chart :kpi-type="'el_demand'" class="demand-chart" v-if="selectedScenario" :scenario="selectedScenario" :portfolio="portfolio" :query="{ building_id: this.building.id }" />
      </tab>
    </tabs>
  </modal>
</template>

<script>
import Tabs from '@/components/shared/Tabs'
import Tab from '@/components/shared/Tab'

import ScenarioPicker from '@/components/shared/ScenarioPicker'
import Modal from '@/components/shared/Modal'
import DetailList from '@/components/shared/lists/DetailList'
import DetailListItem from '@/components/shared/lists/DetailListItem'
import KpiByUsageChart from '@/components/report/charts/KpiByUsageChart'

import MeasuredKpiChart from '@/components/report/charts/MeasuredKpiChart'
import HistogramChart from '@/components/portfolio/kpi/charts/HistogramChart'

export default {
  data () {
    return {
      selectedScenario: null
    }
  },
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    building: {
      type: Object,
      required: true
    }
  },
  components: {
    Modal,
    ScenarioPicker,
    DetailList,
    DetailListItem,
    MeasuredKpiChart,
    HistogramChart,
    KpiByUsageChart,
    Tabs,
    Tab
  }
}
</script>
<style>
.electricity-demand-modal .demand-chart .chart-container {
  min-width:1000px;
  height:300px;
}
.electricity-demand-modal .current-kpis {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: var(--spacing-m);
}
.electricity-demand-modal .histogram-chart {
  height: 220px;
}
</style>
