<i18n>
{
  "de": {
    "capacityLabel": "Installierte Kapazität",
    "investmentLabel": "Investitionskosten",
    "yearsLabel": "Jahre",
    "npvLabel": "Barwert (NPV)",
    "npvChartLabel": "Entwicklung Barwert",
    "yieldRateLabel": "Rendite",
    "breakEvenLabel": "Rückzahldauer",
    "lcoeLabel": "Stromgestehungskosten",
    "subsidiesLabel": "Einmalvergütung",
    "productionLabel": "Produzierte Energie",
    "ownUseLabel": "Eigenverbrauch",
    "demandLabel": "Strombedarf (Deckungsgrad)",
    "gridTarifs": "Rückliefertarif / Bezugstarif H4",
    "nRoofModulesLabel": "Dachmodule",
    "nFassadeModulesLabel": "Fassadenmodule",
    "interestRateLabel": "Zinssatz",
    "amortizationDurationLabel": "Wirtschaftliche Lebensdauer",
    "priceInflationLabel": "Strompreisteuerung",
    "yearsLabel": "Jahre",
    "operationCostsLabel": "Betriebskosten",
    "noMeasuresMessage": "Keine Massnahmen vorhanden"
  }
}
</i18n>
<template>
  <div class="pv-list">
    <detail-list v-if="measures.length > 0">
      <template #header>
        <span></span>
        <span v-for="measure in measures" v-bind:key="measure.id">{{ measureTitle(measure) }}</span>
      </template>
      <li>
        <div></div>
        <div v-for="measure in measures" v-bind:key="measure.id">
          <pv-mini-map :building="building" :measure="measure" v-if="building.roof.state === 'OK'" />
        </div>
      <li>
        <div>{{ $t('capacityLabel') }}</div>
        <div v-for="measure in measures" v-bind:key="measure.id">
          {{ formatNumber(totalCapacity(measure), 1) }} kWp ({{ totalRoofModules(measure) }} {{ $t('nRoofModulesLabel') }} <span v-if="totalFassadeModules(measure)">+{{ totalFassadeModules(measure) }} {{ $t('nFassadeModulesLabel') }}</span>)
        </div>
      </li>
      <li>
        <div>{{ $t('productionLabel') }}</div>
        <div v-for="measure in measures" v-bind:key="measure.id">
          {{ formatNumber(measure.kpi.el_production / 1000, 0) }} MWh
          ({{ formatNumber(measure.kpi.el_production / totalCapacity(measure), 0) }} kWh/kWp)
        </div>
      </li>
      <li>
        <div>{{ $t('demandLabel') }}</div>
        <div v-for="measure in measures" v-bind:key="measure.id">
          {{ formatNumber(measure.kpi.el_demand / 1000, 0) }} MWh ({{ formatNumber(measure.kpi.el_production / measure.kpi.el_demand * 100, 0) }} %)
        </div>
      </li>
      <li>
        <div>{{ $t('ownUseLabel') }}</div>
        <div v-for="measure in measures" v-bind:key="measure.id">
          <span v-if="measure.pv_npv_details">
            {{ formatNumber(measure.kpi.el_ownuse / measure.kpi.el_production * 100, 0) }} %
          </span>
        </div>
      </li>
      <li>
        <div>{{ $t('investmentLabel') }}</div>
        <div v-for="measure in measures" v-bind:key="measure.id">
          {{ formatNumber(measure.costs.pv.costs, -2) }} CHF
        </div>
      </li>
      <li>
        <div>{{ $t('subsidiesLabel') }}</div>
        <div v-for="measure in measures" v-bind:key="measure.id">
          {{ formatNumber(measure.costs.pv.subsidies) }} CHF
        </div>
      </li>
      <li>
        <div>{{ $t('gridTarifs') }}</div>
        <div v-for="measure in measures" v-bind:key="measure.id">
          {{ formatNumber(measure.pv_npv_details.feedin_tarif * 100, 1) }} Rp/kWh / {{ formatNumber(measure.pv_npv_details.grid_tarif * 100, 1) }} Rp/kWh
        </div>
      </li>
      <li>
        <div>{{ $t('lcoeLabel') }} (1,2,3)</div>
        <div v-for="measure in measures" v-bind:key="measure.id">
          {{ formatNumber(measure.pv_lcoe, 2) }} CHF/kWh
        </div>
      </li>
      <li>
        <div>{{ $t('yieldRateLabel') }} (2,3,4)</div>
        <div v-for="measure in measures" v-bind:key="measure.id">
          {{ formatNumber(measure.pv_yield_rate, 1) }} %
        </div>
      </li>
      <li>
        <div>{{ $t('breakEvenLabel') }} (1,3,4)</div>
        <div v-for="measure in measures" v-bind:key="measure.id">
          <span v-if="measure.pv_break_even !== null">{{ measure.pv_break_even }} {{ $t('yearsLabel') }}</span>
          <span v-else>-</span>
        </div>
      </li>
      <li>
        <div>{{ $t('npvLabel') }} (1,2,3,4)</div>
        <div v-for="measure in measures" v-bind:key="measure.id">
          {{ formatNumber(measure.pv_npv, 0) }} CHF
        </div>
      </li>
      <li>
        <div>{{ $t('npvChartLabel') }}</div>
        <div v-for="measure in measures" v-bind:key="measure.id">
          <npv-chart :data="npvData(measure)" v-if="measure.pv_npv_details" />
        </div>
      </li>
    </detail-list>
    <div v-else>{{ $t('noMeasuresMessage') }}</div>
    <div v-if="measures.length > 0">
      (1) {{ $t('interestRateLabel') }}: {{ portfolio.interest_rate }} %<br />
      (2) {{ $t('amortizationDurationLabel') }}: {{ portfolio.amortization_durations.pv }} {{ $t('yearsLabel') }}<br />
      (3) {{ $t('operationCostsLabel') }}: {{ portfolio.operation_costs.pv }} CHF/kWh<br />
      (4) {{ $t('priceInflationLabel') }}: {{ portfolio.energy_prices.electricity.inflation }} %
    </div>
  </div>
</template>

<script>
import DetailList from '@/components/shared/lists/DetailList'
import PvMiniMap from '@/components/building/map/PvMiniMap'
import NpvChart from '@/components/building/measures/NpvChart'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    building: {
      type: Object,
      required: true
    }
  },
  computed: {
    measures () {
      return this.building.measures.filter(m => m.actions['pv'] !== null && m.pv_npv).sort((a, b) => this.measureTitle(a) > this.measureTitle(b))
    }
  },
  methods: {
    npvData (measure) {
      return {
        'cashflow': measure.pv_npv_details.cashflow_pv,
        'npv': measure.pv_npv_details.cashflow_pv_cumulative,
        'years': measure.pv_npv_details.cashflow_pv_cumulative.map((v, idx) => idx)
      }
    },
    measureTitle (measure) {
      if (!this.portfolio) {
        return null
      }
      if (measure.scenario_id === null) {
        if (measure.remarks) {
          return measure.remarks
        } else {
          return null
        }
      } else {
        const scenario = this.portfolio.scenarios.find(s => s.id === measure.scenario_id)
        return scenario.description
      }
    },
    totalCapacity (measure) {
      return measure.pv_sections.reduce((pv, cv) => pv + cv.capacity, 0)
    },
    totalRoofModules (measure) {
      return measure.pv_sections.reduce((pv, cv) => pv + (!cv.fassade_tile_id ? cv.n_modules : 0), 0)
    },
    totalFassadeModules (measure) {
      return measure.pv_sections.reduce((pv, cv) => pv + (cv.fassade_tile_id ? cv.n_modules : 0), 0)
    }
  },
  components: {
    DetailList,
    NpvChart,
    PvMiniMap
  }
}
</script>
<style>
.pv-list .pv-minimap {
  height: 350px;
}
.pv-list .npv-chart {
  height: 350px;
}
</style>
