<template>
  <li :class="classes">
    <div class="item-main" @click="onClick">
      <h5 v-if="title">
        {{ title }}
        <info-box v-if="infoText" :title="infoTitle ? infoTitle : label">{{ infoText }}</info-box>
      </h5>
      <span><slot /></span>
    </div>
    <div class="item-right" v-if="hasRightContent || expandable" @click="onClick">
      <slot name="rightContent" />
      <span class="expand-icon" v-if="expandable"><img src="/icons/chevron-right.svg"></span>
    </div>
    <div class="modal"><slot name="modal" /></div>
  </li>
</template>

<script>
import InfoBox from '@/components/shared/InfoBox'

export default {
  props: {
    title: {
      type: String
    },
    expandable: {
      type: Boolean,
      default: false
    },
    infoTitle: {
      type: String
    },
    infoText: {
      type: String
    }
  },
  methods: {
    onClick () {
      if (this.expandable) {
        this.$emit('expand')
      }
    }
  },
  computed: {
    classes () {
      return {
        'detail-list-item': true,
        'has-right-content': this.hasRightContent || this.expandable,
        'expandable': this.expandable
      }
    },
    hasRightContent () {
      return !!this.$slots.rightContent
    }
  },
  components: {
    InfoBox
  }
}
</script>

<style>
.detail-list-item {
  display: grid;
  grid-auto-columns: 1fr;
  column-gap: var(--box-padding-h);
  row-gap: var(--spacing-xxs);
  align-items: center;
}

.detail-list-item .two-columns {
  display: grid;
  grid-template-columns: 140px 140px;
}

.detail-list-item .three-columns {
  display: grid;
  grid-template-columns: 140px 140px 1fr;
}

.detail-list-item.expandable {
  cursor: pointer;
}

.detail-list-item.expandable:hover {
  background-color: #f8f8f8;
}

.detail-list-item .modal {
  cursor: auto;
}

.detail-list-item > .item-main {
  display: grid;
  column-gap: var(--box-padding-h);
  row-gap: var(--spacing-xxs);
  padding: var(--box-padding);
}

.detail-list-item > .item-right {
  padding: var(--box-padding);
}

.detail-list-item.expandable .expand-icon {
  opacity: 0.6;
}

.detail-list-item.expandable:hover .expand-icon {
  opacity: 1.0;
}

.detail-list-item .expand-icon > img {
  vertical-align: top;
}

.detail-list-item > .item-main > h5 {
  font-size: var(--font-xs);
  font-weight: 600;
  color: var(--secondary-highlight-color);
  grid-column: 1 / -1;
}

.detail-list-item.has-right-content {
  grid-template-columns: auto max-content;
}

</style>
