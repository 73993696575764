<!--
TODO: Refactor and maybe break into multiple components
-->
<i18n>
{
  "de": {
    "sourceDefaultValue": "Quelle: Standardannahme, effektiver Wert unbekannt",
    "sourceAreaAuto": "Schätzung",
    "buildingYearLabel": "Baujahr Gebäude",
    "sourceGwr": "Quelle: GWR",
    "buildingYearInfoBox": "Wenn dieses Feld leergelassen wird, wird das durchschnittliche Baujahr aus den GWR-Daten übernommen (falls vorhanden).",
    "floorsNumberLabel": "Anzahl Geschosse",
    "floorsNumberInfoBox": "Wird für Berechnung der Flächen und Wärmeverluste verwendet. Wenn dieses Feld leergelassen wird, wird die durchschnittliche Geschosszahl aus den GWR-Daten übernommen (falls vorhanden).",
    "roofTypeSourceGis": "Quelle: GIS-Gebäudedaten",
    "roofTypeLabel": "Dach-Typ",
    "unknownData": "Unbekannt",
    "roofTypeFlat": "Flachdach",
    "roofTypeGable": "Schrägdach",
    "atticLabel": "Estrich",
    "atticHeatedLabel": "Estrich ist ausgebaut und beheizt",
    "atticNotHeatedLabel": "Estrich unbeheizt",
    "wallsInsulationLabel": "Fassade",
    "windowsInsulationLabel": "Fenster",
    "basementInsulationLabel": "Kellerdecke",
    "roofInsulationLabel": "Dach",
    "atticInsulationLabel": "Oberste Geschossdecke",
    "typicalInsulation": "Benchmark Bauteilalter",
    "customInsulation": "Vorgabe U-Wert",
    "yearTitle": "Baujahr",
    "replacementYearTitle": "Planung",
    "replacementCostsTitle": "Kosten",
    "componentTitle": "Komponente",
    "areaTitle": "Fläche",
    "insulationStandardTitle": "Wärmedämmung",
    "uvalueTitle": "U-Wert",
    "qtTitle": "Wärmeverluste",
    "remarksTitle": "Bemerkungen",
    "hasHeatingLabel": "Heizwärmebedarf",
    "hasHeatingCheckLabel": "Gebäude beheizt",
    "hasNoHeatingCheckLabel": "Gebäude nicht beheizt",
    "envelopeModelLabel": "Wärmebedarf automatisch berechnen",
    "envelopeNoModelLabel": "Wärmebedarf manuell vorgeben",
    "manualRhDemandLabel": "Vorgabe Heizwärmebedarf",
    "roofTypeInfoBox": "Wird für die Berechnung der Wärmeverluste sowie für die Sanierungsplanung verwendet. Je nach Dachtyp werden unterschiedliche Sanierungskosten und Lebensdauern angenommen. Kann leergelassen werden für eine automatische Bestimmung aus GIS-Daten",
    "componentYearInfoBoxTitle": "Baujahr oder letzte Sanierung von Bauteilen",
    "componentYearInfoBox": "Wird für die Massnahmenplanung und (falls keine genauen Angaben vorhanden) für die Einschätzung der Wärmedämmung verwendet.",
    "componentAreaInfoBoxTitle": "Bauteilfläche",
    "componentAreaInfoBox": "Wird für die Berechnung der Wärmeverluste sowie für die Schätzung von Sanierungskosten verwendet. Kann leergelassen werden für eine automatische Schätzung auf Basis der Energiebezugsfläche und Geschosszahl.",
    "replacementCostsInfoBoxTitle": "Sanierungskosten aus Instandsetzungsplanung",
    "replacementCostsInfoBox": "Wenn dieses Feld leergelassen wird, werden die Kosten anhand von flächenabhängigen Kennzahlen geschätzt.",
    "nextReplacementInfoBoxTitle": "Jahr nächster Eingriff aus Instandsetzungsplanung",
    "nextReplacementInfoBox": "Wenn dieses Feld leergelassen wird, werden Standardlebensdauer pro Bauteil angenommen.",
    "qtInfoBoxTitle": "Modellierte Wärmeverluste",
    "qtInfoBox": "Modellierte Transmissionswärmeverluste dieses Bauteils, ohne Berücksichtigung der Kalibrierung.",
    "heritageProtection": "Denkmalschutz",
    "heritageProtectionType": "Inventar",
    "heritageProtectionOptions": {
      "UNKNOWN": "Unbekannt",
      "NONE": "Nein",
      "CANTON": "Kantonal",
      "REGION": "Regional"
    },
    "heritageProtectionRemarks": "Bemerkungen",
    "volumeLabel": "Gebäudevolumen",
    "componentStateEstimated": "Flag: Baujahr / letzte Sanierung unbekannt oder geschätzt"
  }
}
</i18n>

<template>
  <edit-container :bind-form-state="formState" @container="onFormEvent" class="edit-envelope-insulation">
    <form-row :label="$t('remarksTitle')" v-if="isEditing || model.remarks">
      <text-input v-model="model.remarks" :edit="isEditing" />
    </form-row>
    <form-row :label="$t('hasHeatingLabel')" v-if="isEditing || model.without_heating || enable_manual_rh_demand">
      <check-input v-model="model.without_heating" :edit="isEditing && !hasManualMeasures" :label="$t('hasNoHeatingCheckLabel')" :active-label="$t('hasNoHeatingCheckLabel')" :inactive-label="$t('hasHeatingCheckLabel')" />
      <div v-if="!model.without_heating"><check-input v-model="enable_manual_rh_demand" :edit="isEditing && !hasManualMeasures" :label="$t('envelopeNoModelLabel')" :active-label="$t('envelopeNoModelLabel')" :inactive-label="$t('envelopeModelLabel')" /></div>
    </form-row>
    <form-row :label="$t('manualRhDemandLabel')" v-if="!model.without_heating && enable_manual_rh_demand">
      <numeric-input v-model="model.manual_rh_demand" :min="0" :edit="isEditing" :units="'kWh/m²'" class="limit-width" />
    </form-row>
    <form-row :label="$t('buildingYearLabel')" :info-text="$t('buildingYearInfoBox')">
      <numeric-input noFormatting int optional v-model="model.building_year" :min="0" :max="2100" :edit="isEditing" class="limit-width" v-if="isEditing" />
      <div v-else-if="envelopeResults">
        {{ envelopeResults.building_year.value }}
        <div class="source-info" v-if="envelopeResults.building_year.source === 'GIS'">{{ $t('sourceGwr')}}</div>
        <div class="source-info" v-if="envelopeResults.building_year.source === 'DEFAULT'">{{ $t('sourceDefaultValue')}}</div>
      </div>
      <span v-else-if="model.building_year">{{ model.building_year }}</span>
      <span v-else>{{ $t('unknownData') }}</span>
    </form-row>
    <form-row :label="$t('floorsNumberLabel')" :info-text="$t('floorsNumberInfoBox')">
      <numeric-input int optional v-model="model.floors_number" :min="1" :max="100" :edit="isEditing" class="limit-width" v-if="isEditing" />
      <div v-else-if="envelopeResults">
        {{ envelopeResults.floors_number.value }}
        <div class="source-info" v-if="envelopeResults.floors_number.source === 'GIS'">{{ $t('sourceGwr')}}</div>
        <div class="source-info" v-if="envelopeResults.floors_number.source === 'DEFAULT'">{{ $t('sourceDefaultValue')}}</div>
      </div>
      <span v-else-if="model.floors_number">{{ model.floors_number }}</span>
      <span v-else>{{ $t('unknownData') }}</span>
    </form-row>
    <form-row :label="$t('volumeLabel')" v-if="isEditing || model.volume">
      <numeric-input float optional v-model="model.volume" :min="0" :edit="isEditing" :units="'m³'" class="limit-width" />
    </form-row>
    <form-row :label="$t('roofTypeLabel')" :info-text="$t('roofTypeInfoBox')">
      <span v-if="isEditing">
        <v-select id="roof-type-options" v-model="model.roof_type" :options="roofTypeOptions" :reduce="t => t.id" class="select-width" />
      </span>
      <div v-else-if="envelopeResults">
        {{ roofTypeText }}
        <div class="source-info" v-if="envelopeResults.roof_type.source === 'GIS'">{{ $t('roofTypeSourceGis')}}</div>
        <div class="source-info" v-if="envelopeResults.roof_type.source === 'DEFAULT'">{{ $t('sourceDefaultValue')}}</div>
      </div>
      <span v-else>{{ $t('unknownData') }}</span>
    </form-row>
    <form-row :label="$t('atticLabel')" v-if="model.roof_type === 'GABLE' && !model.without_heating && !enable_manual_rh_demand">
      <check-input v-model="model.attic_heated" :edit="isEditing" :label="$t('atticHeatedLabel')" :active-label="$t('atticHeatedLabel')" :inactive-label="$t('atticNotHeatedLabel')" />
    </form-row>
    <detail-list :class="listClasses" v-if="!model.without_heating && !enable_manual_rh_demand">
      <template #header>
          <span>{{ $t('componentTitle') }}</span>
          <span class="align-right">{{ $t('yearTitle') }} <info-box :title="$t('componentYearInfoBoxTitle')">{{ $t('componentYearInfoBox') }}</info-box></span>
          <span class="align-right">{{ $t('areaTitle') }} <info-box :title="$t('componentAreaInfoBoxTitle')">{{ $t('componentAreaInfoBox') }}</info-box></span>
          <span>{{ $t('insulationStandardTitle') }}</span>
          <span class="align-right">{{ $t('uvalueTitle') }}</span>
          <span class="align-right">{{ $t('qtTitle') }} <info-box :title="$t('qtInfoBoxTitle')">{{ $t('qtInfoBox') }}</info-box></span>
          <span>{{ $t('remarksTitle') }}</span>
          <span class="align-right">{{ $t('replacementYearTitle') }} <info-box :title="$t('nextReplacementInfoBoxTitle')">{{ $t('nextReplacementInfoBox') }}</info-box></span>
          <span class="align-right">{{ $t('replacementCostsTitle') }} <info-box :title="$t('replacementCostsInfoBoxTitle')">{{ $t('replacementCostsInfoBox') }}</info-box></span>
      </template>
      <!--
        Walls
      -->
      <li>
        <span>{{ $t('wallsInsulationLabel') }}</span>
        <div class="year-and-estimate align-right">
          <numeric-input noFormatting optional int v-model="model.walls.year" :min="0" :max="2100" :edit="isEditing" />
          <estimate-input v-model="model.walls.year_estimated" :edit="isEditing" :tooltip="$t('componentStateEstimated')" />
        </div>
        <div class="align-right">
          <numeric-input optional v-model="model.walls.area" :min="0" :edit="isEditing" :units="'m²'" v-if="isEditing" />
          <div v-else-if="envelopeResults">
            {{ formatNumber(envelopeResults.walls.area.value) }} m²
            <div class="source-info" v-if="envelopeResults.walls.area.source === 'AUTO'">{{ $t('sourceAreaAuto')}}</div>
          </div>
          <span v-else-if="model.walls.area">{{ formatNumber(model.walls.area) }} m²</span>
          <span v-else>{{ $t('unknownData') }}</span>
        </div>
        <div>
          <v-select v-if="isEditing" id="walls-insulation-options" v-model="model.walls.standard" :options="renovateOptions" :clearable="false" :reduce="t => t.id" />
          <span v-else class="insulation-label">{{ selectedWallsLabel }}</span>
        </div>
        <div class="align-right">
          <div v-if="model.walls.standard === 'CUSTOM'">
            <numeric-input v-model="model.walls.uvalue" :min="0" :max="10" :edit="isEditing" :units="'W/m²K'" />
          </div>
          <div v-else-if="!isEditing && envelopeResults">
            <span>{{ formatNumber(envelopeResults.walls.uvalue.value, 2) }} W/m²K</span>
          </div>
        </div>
        <div class="align-right">
          <div v-if="envelopeResults">
            {{ formatNumber(envelopeResults.walls.Q_t / 1000, 1) }} MWh
          </div>
        </div>
        <div>
          <text-input v-model="model.walls.remarks" :edit="isEditing" />
        </div>
        <div class="align-right">
          <numeric-input noFormatting optional int v-model="model.walls.replacement_year" :min="0" :max="2100" :edit="isEditing" />
        </div>
        <div class="align-right">
          <numeric-input optional v-model="model.walls.replacement_costs" :units="'CHF'" :min="0" :edit="isEditing" />
        </div>
      </li>
      <!--
        Windows
      -->
      <li>
        <span>{{ $t('windowsInsulationLabel') }}</span>
        <div class="year-and-estimate align-right">
          <numeric-input noFormatting optional int v-model="model.windows.year" :min="0" :max="2100" :edit="isEditing" />
          <estimate-input v-model="model.windows.year_estimated" :edit="isEditing" :tooltip="$t('componentStateEstimated')" />
        </div>
        <div class="align-right">
          <numeric-input optional v-model="model.windows.area" :min="0" :edit="isEditing" :units="'m²'" v-if="isEditing" />
          <div v-else-if="envelopeResults">
            {{ formatNumber(envelopeResults.windows.area.value) }} m²
            <div class="source-info" v-if="envelopeResults.windows.area.source === 'AUTO'">{{ $t('sourceAreaAuto')}}</div>
          </div>
          <span v-else-if="model.windows.area">{{ formatNumber(model.windows.area) }} m²</span>
          <span v-else>{{ $t('unknownData') }}</span>
        </div>
        <div>
          <v-select v-if="isEditing" id="windows-insulation-options" v-model="model.windows.standard" :options="replaceWindowsOptions" :clearable="false" :reduce="t => t.id" />
          <span v-else class="insulation-label">{{ selectedWindowsLabel }}</span>
        </div>
        <div class="align-right">
          <div v-if="model.windows.standard === 'CUSTOM'">
            <numeric-input v-model="model.windows.uvalue" :min="0" :max="10" :edit="isEditing" :units="'W/m²K'" />
          </div>
          <div v-else-if="!isEditing && envelopeResults">
            <span>{{ formatNumber(envelopeResults.windows.uvalue.value, 2) }} W/m²K</span>
          </div>
        </div>
        <div class="align-right">
          <div v-if="envelopeResults">
            {{ formatNumber(envelopeResults.windows.Q_t / 1000, 1) }} MWh
          </div>
        </div>
        <div>
          <text-input v-model="model.windows.remarks" :edit="isEditing" />
        </div>
        <div class="align-right">
          <numeric-input noFormatting optional int v-model="model.windows.replacement_year" :min="0" :max="2100" :edit="isEditing" />
        </div>
        <div class="align-right">
          <numeric-input optional v-model="model.windows.replacement_costs" :units="'CHF'" :min="0" :edit="isEditing" />
        </div>
      </li>
      <!--
        Roof
      -->
      <li>
        <span v-if="model.roof_type === 'GABLE' && !model.attic_heated">{{ $t('atticInsulationLabel') }}</span>
        <span v-else>{{ $t('roofInsulationLabel') }}</span>
        <div class="year-and-estimate align-right">
          <numeric-input noFormatting optional int v-model="model.roof.year" :min="0" :max="2100" :edit="isEditing" />
          <estimate-input v-model="model.roof.year_estimated" :edit="isEditing" :tooltip="$t('componentStateEstimated')" />
        </div>
        <div class="align-right">
          <numeric-input optional v-model="model.roof.area" :min="0" :edit="isEditing" :units="'m²'" v-if="isEditing" />
          <div v-else-if="envelopeResults">
            {{ formatNumber(envelopeResults.roof.area.value) }} m²
            <div class="source-info" v-if="envelopeResults.roof.area.source === 'AUTO'">{{ $t('sourceAreaAuto')}}</div>
          </div>
          <span v-else-if="model.roof.area">{{ formatNumber(model.roof.area) }} m²</span>
          <span v-else>{{ $t('unknownData') }}</span>
        </div>
        <div>
          <v-select v-if="isEditing" id="roof-insulation-options" v-model="model.roof.standard" :options="renovateOptions" :clearable="false" :reduce="t => t.id" />
          <span v-else class="insulation-label">{{ selectedRoofLabel }}</span>
        </div>
        <div class="align-right">
          <div v-if="model.roof.standard === 'CUSTOM'">
            <numeric-input v-model="model.roof.uvalue" :min="0" :max="10" :edit="isEditing" :units="'W/m²K'" />
          </div>
          <div v-else-if="!isEditing && envelopeResults">
            <span>{{ formatNumber(envelopeResults.roof.uvalue.value, 2) }} W/m²K</span>
          </div>
        </div>
        <div class="align-right">
          <div v-if="envelopeResults">
            {{ formatNumber(envelopeResults.roof.Q_t / 1000, 1) }} MWh
          </div>
        </div>
        <div>
          <text-input v-model="model.roof.remarks" :edit="isEditing" />
        </div>
        <div class="align-right">
          <numeric-input noFormatting optional int v-model="model.roof.replacement_year" :min="0" :max="2100" :edit="isEditing" />
        </div>
        <div class="align-right">
          <numeric-input optional v-model="model.roof.replacement_costs" :units="'CHF'" :min="0" :edit="isEditing" />
        </div>
      </li>
      <!--
        Basement
      -->
      <li>
        <span>{{ $t('basementInsulationLabel') }}</span>
        <div class="year-and-estimate align-right">
          <numeric-input noFormatting optional int v-model="model.basement.year" :min="0" :max="2100" :edit="isEditing" />
          <estimate-input v-model="model.basement.year_estimated" :edit="isEditing" :tooltip="$t('componentStateEstimated')" />
        </div>
        <div class="align-right">
          <numeric-input optional v-model="model.basement.area" :min="0" :edit="isEditing" :units="'m²'" v-if="isEditing" />
          <div v-else-if="envelopeResults">
            {{ formatNumber(envelopeResults.basement.area.value) }} m²
            <div class="source-info" v-if="envelopeResults.basement.area.source === 'AUTO'">{{ $t('sourceAreaAuto')}}</div>
          </div>
          <span v-else-if="model.basement.area">{{ formatNumber(model.basement.area) }} m²</span>
          <span v-else>{{ $t('unknownData') }}</span>
        </div>
        <div>
          <v-select v-if="isEditing" id="basement-insulation-options" v-model="model.basement.standard" :options="renovateOptions" :clearable="false" :reduce="t => t.id" />
          <span v-else class="insulation-label">{{ selectedBasementLabel }}</span>
        </div>
        <div class="align-right">
          <div v-if="model.basement.standard === 'CUSTOM'">
            <numeric-input v-model="model.basement.uvalue" :min="0" :max="10" :edit="isEditing" :units="'W/m²K'" />
          </div>
          <div v-else-if="!isEditing && envelopeResults">
            <span>{{ formatNumber(envelopeResults.basement.uvalue.value, 2) }} W/m²K</span>
          </div>
        </div>
        <div class="align-right">
          <div v-if="envelopeResults">
            {{ formatNumber(envelopeResults.basement.Q_t / 1000, 1) }} MWh
          </div>
        </div>
        <div>
          <text-input v-model="model.basement.remarks" :edit="isEditing" />
        </div>
        <div class="align-right">
          <numeric-input noFormatting optional int v-model="model.basement.replacement_year" :min="0" :max="2100" :edit="isEditing" />
        </div>
        <div class="align-right">
          <numeric-input optional v-model="model.basement.replacement_costs" :units="'CHF'" :min="0" :edit="isEditing" />
        </div>
      </li>
    </detail-list>
    <div v-if="isEditing || model.heritage_protection !== 'UNKNOWN' || model.heritage_protection_remarks">
      <h3>{{ $t('heritageProtection') }}</h3>
      <form-row :label="$t('heritageProtectionType')">
        <div v-if="isEditing">
          <v-select id="heritage-options" v-model="model.heritage_protection" :options="heritageProtectionOptions" :clearable="false" :reduce="t => t.id" class="select-width" />
        </div>
        <div v-else>
          {{ $t(`heritageProtectionOptions.${model.heritage_protection}`) }}
        </div>
      </form-row>
      <form-row :label="$t('heritageProtectionRemarks')">
        <text-input v-model="model.heritage_protection_remarks" :edit="isEditing" />
      </form-row>
    </div>
  </edit-container>
</template>
<script>
import { mapActions } from 'vuex'

import EditContainer from '@/components/shared/forms/EditContainer'
import ApiFormMixin from '@/components/shared/forms/ApiFormMixin'

import DetailList from '@/components/shared/lists/DetailList'
import NumericInput from '@/components/shared/forms/NumericInput'
import InfoBox from '@/components/shared/InfoBox'
import TextInput from '@/components/shared/forms/TextInput'
import CheckInput from '@/components/shared/forms/CheckInput'
import FormRow from '@/components/shared/forms/FormRow'
import EstimateInput from '@/components/shared/forms/EstimateInput'

export default {
  mixins: [
    ApiFormMixin
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation */
  ],
  data () {
    return {
      enable_manual_rh_demand: null,
      roofTypeOptions: [
        { id: 'FLAT', label: this.$t('roofTypeFlat') },
        { id: 'GABLE', label: this.$t('roofTypeGable') }
      ],
      heritageProtectionOptions: [
        { id: 'UNKNOWN', label: this.$t('heritageProtectionOptions.UNKNOWN') },
        { id: 'NONE', label: this.$t('heritageProtectionOptions.NONE') },
        { id: 'REGION', label: this.$t('heritageProtectionOptions.REGION') },
        { id: 'CANTON', label: this.$t('heritageProtectionOptions.CANTON') }
      ],
      replaceWindowsOptions: [
        { id: 'AUTO', label: this.$t('typicalInsulation') },
        { id: 'CUSTOM', label: this.$t('customInsulation') },
        { id: 'MINERGIE_2014', label: this.$t('_windowTypes.MINERGIE_2014') },
        { id: 'MuKEn_2014', label: this.$t('_windowTypes.MuKEn_2014') },
        { id: 'MuKEn_2008', label: this.$t('_windowTypes.MuKEn_2008') }
      ],
      renovateOptions: [
        { id: 'AUTO', label: this.$t('typicalInsulation') },
        { id: 'CUSTOM', label: this.$t('customInsulation') },
        { id: 'MINERGIE_2014', label: this.$t('_renovateTypes.MINERGIE_2014') },
        { id: 'MuKEn_2014', label: this.$t('_renovateTypes.MuKEn_2014') },
        { id: 'MuKEn_2014_RENOVATION', label: this.$t('_renovateTypes.MuKEn_2014_RENOVATION') }
      ]
    }
  },
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  watch: {
    enable_manual_rh_demand () {
      if (!this.enable_manual_rh_demand) {
        this.model.manual_rh_demand = null
      }
    },
    building: function () {
      this.resetModel()
    }
  },
  mounted () {
    this.enable_manual_rh_demand = this.model.manual_rh_demand !== null && this.model.manual_rh_demand !== undefined
  },
  computed: {
    envelopeResults () {
      return this.building.initial_state.result && this.building.initial_state.result.envelope
    },
    hasManualMeasures () {
      return this.building.measures.find(m => m.actions.envelope !== undefined && m.phase !== 'SCENARIO') !== undefined
    },
    roofTypeText () {
      if (this.envelopeResults && this.envelopeResults.roof_type.value) {
        const type = this.roofTypeOptions.find(o => o.id === this.envelopeResults.roof_type.value)
        return type.label
      } else {
        return this.$t('unknownData')
      }
    },
    listClasses () {
      return {
        'edit': this.edit
      }
    },
    selectedRoofLabel () {
      const option = this.renovateOptions.find(o => o.id === this.model.roof.standard)
      return option && option.label
    },
    selectedWindowsLabel () {
      const option = this.replaceWindowsOptions.find(o => o.id === this.model.windows.standard)
      return option && option.label
    },
    selectedBasementLabel () {
      const option = this.renovateOptions.find(o => o.id === this.model.basement.standard)
      return option && option.label
    },
    selectedWallsLabel () {
      const option = this.renovateOptions.find(o => o.id === this.model.walls.standard)
      return option && option.label
    }
  },
  methods: {
    ...mapActions({
      updateBuilding: 'building/updateBuilding'
    }),
    resetModel () {
      this.model = {
        'building_year': this.building.initial_state.envelope.building_year,
        'floors_number': this.building.initial_state.envelope.floors_number,
        'roof_type': this.building.initial_state.envelope.roof_type,
        'attic_heated': this.building.initial_state.envelope.attic_heated,
        'remarks': this.building.initial_state.envelope.remarks,
        'without_heating': this.building.initial_state.envelope.without_heating,
        'manual_rh_demand': this.building.initial_state.envelope.manual_rh_demand,
        'heritage_protection': this.building.initial_state.envelope.heritage_protection,
        'heritage_protection_remarks': this.building.initial_state.envelope.heritage_protection_remarks,
        'volume': this.building.initial_state.envelope.volume
      }
      for (const c of ['walls', 'windows', 'roof', 'basement']) {
        this.model[c] = {
          'year': this.building.initial_state.envelope[c].year,
          'year_estimated': this.building.initial_state.envelope[c].year_estimated,
          'replacement_year': this.building.initial_state.envelope[c].replacement_year,
          'replacement_costs': this.building.initial_state.envelope[c].replacement_costs,
          'area': this.building.initial_state.envelope[c].area,
          'standard': this.building.initial_state.envelope[c].standard || 'CUSTOM',
          'uvalue': this.building.initial_state.envelope[c].uvalue !== null ? this.building.initial_state.envelope[c].uvalue.toFixed(2) : null,
          'remarks': this.building.initial_state.envelope[c].remarks
        }
      }
    },
    async saveHandler (model) {
      await this.updateBuilding({
        'id': this.building.id,
        'initial_state': {
          'envelope': model
        }
      })
    }
  },
  components: {
    EditContainer,
    NumericInput,
    InfoBox,
    TextInput,
    CheckInput,
    EstimateInput,
    FormRow,
    DetailList
  }
}
</script>
<style>
.edit-envelope-insulation .insulation-label {
  margin-right: var(--spacing-l);
}
.edit-envelope-insulation .detail-list > ul > li {
  grid-template-columns: 1fr 110px 90px 1fr 120px 120px 1fr 75px 100px;
}
.edit-envelope-insulation .limit-width {
  max-width: 150px;
}
.edit-envelope-insulation .select-width {
  display: inline-block;
  min-width: 150px;
  max-width: 300px;
}
.edit-envelope-insulation .year-and-estimate {
  display: grid;
  grid-template-columns: 1fr max-content;
}
.edit-envelope-insulation .source-info {
  color: var(--secondary-text-color);
  font-size: var(--font-xs);
}
.edit-envelope-insulation .align-right {
  text-align: right;
}
</style>
