<i18n>
{
  "de": {
    "modalTitle": "Raumwärmebedarf",
    "rhCalibrationLabel": "Kalibrierungsfaktor",
    "rhLimitLabel": "Grenzwert SIA 380/1",
    "rhTotalLabel": "Nutzenergie",
    "rhEndEnergyLabel": "Endenergie",
    "histogramTitle": "Im Vergleich mit anderen Liegenschaften",
    "rhInfoBoxTitle": "Nutzenergie Raumwärme",
    "rhInfoBox": "Nutzenergiebedarf Raumwärme unter Berücksichtigung des Kalibrierungsfaktors.",
    "rhEndEnergyInfoBoxTitle": "Endenergie Raumwärme",
    "rhEndEnergyInfoBox": "Endenergiebedarf Raumwärme (netto gelieferte Energie, z.B. Brennwert von Heizöl) unter Berücksichtigung des Kalibrierungsfaktors. Die Differenz zur Nutzenergie entsteht durch die Nutzungsziffer des Wärmeerzeugers.",
    "calibrationInfoBox": "Abweichung des effektiven Raumwärmebedarfs vom modellierten Raumwärmebedarf. Dieser Wert wird aus der Kalibrierung des Endenergiebedarfs der Wärmeerzeuger ermittelt",
    "calibrationInfoBoxTitle": "Kalibrierungsfaktor Nutzenergie Raumwärme",
    "highCalibrationFactorWarningTitle": "Effektiver Energieverbrauch höher als erwartet",
    "highCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich über dem modellierten Energiebedarf. Für mögliche Ursachen siehe Kalibrierung Wärmeerzeugung.",
    "lowCalibrationFactorWarningTitle": "Effektiver Energieverbrauch tiefer als erwartet",
    "lowCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich unter dem modellierten Energiebedarf. Für mögliche Ursachen siehe Kalibrierung Wärmeerzeugung."
  }
}
</i18n>

<template>
  <modal @close="$emit('close', $event)" class="rh-demand-modal" wide>
    <template v-slot:header>
      <h2>{{ $t('modalTitle') }}</h2>
      <h3>{{ building.identifier }} / {{ building.description }}</h3>
    </template>
    <div class="current-kpis">
      <detail-list>
        <detail-list-item :title="$t('rhTotalLabel')" :info-title="$t('rhInfoBoxTitle')" :info-text="$t('rhInfoBox')">
          <div class="three-columns">
            <span>{{ formatNumber(building.kpi.rh_demand / 1000, 1) }} MWh</span>
            <span>{{ formatNumber(building.kpi.rh_demand / building.kpi.energy_area, 0) }} kWh/m<sup>2</sup></span>
            <span>{{ $t('rhLimitLabel') }}: {{ formatNumber(building.kpi.rh_limit, 1) }} kWh/m<sup>2</sup></span>
          </div>
          <template #rightContent>
              <energy-label :value="rhRatio" class="energy-label" />
          </template>
        </detail-list-item>
        <detail-list-item :title="$t('rhEndEnergyLabel')" :info-title="$t('rhEndEnergyInfoBoxTitle')" :info-text="$t('rhEndEnergyInfoBox')">
          <div class="two-columns">
            <span>{{ formatNumber(building.kpi.rh_end_energy / 1000, 1) }} MWh</span>
            <span>{{ formatNumber(building.kpi.rh_end_energy / building.kpi.energy_area, 0) }} kWh/m<sup>2</sup></span>
          </div>
        </detail-list-item>
        <detail-list-item v-if="building.kpi.rh_calibration !== 1.0" :title="$t('rhCalibrationLabel')" :info-title="$t('calibrationInfoBoxTitle')" :info-text="$t('calibrationInfoBox')">
          <span v-if="building.kpi.rh_calibration >= 1.0">
            <data-warning v-if="building.kpi.rh_calibration > 1.667" :title="$t('highCalibrationFactorWarningTitle')"><span v-html="$t('highCalibrationFactorWarning')" /></data-warning>
            +{{ formatNumber(building.kpi.rh_calibration * 100 - 100, 1) }} %
          </span>
          <span v-else>
            <data-warning v-if="building.kpi.rh_calibration < 0.6" :title="$t('lowCalibrationFactorWarningTitle')"><span v-html="$t('lowCalibrationFactorWarning')" /></data-warning>
            {{ formatNumber(building.kpi.rh_calibration * 100 - 100, 1) }} %
          </span>
        </detail-list-item>
      </detail-list>
      <div>
        <h3>{{ $t('histogramTitle') }}</h3>
        <histogram-chart
          :key="'rh_histogram'"
          :group-by-key="'rh_demand'"
          area-specific
          :buildings="portfolio.buildings"
          :highlight-value="building.kpi.rh_demand / building.kpi.energy_area"
          class="histogram-chart"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/shared/Modal'
import EnergyLabel from '@/components/shared/EnergyLabel'
import DataWarning from '@/components/shared/DataWarning'
import DetailList from '@/components/shared/lists/DetailList'
import DetailListItem from '@/components/shared/lists/DetailListItem'
import HistogramChart from '@/components/portfolio/kpi/charts/HistogramChart'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    building: {
      type: Object,
      required: true
    }
  },
  computed: {
    rhRatio () {
      return (this.building.kpi.rh_demand / this.building.kpi.energy_area) / this.building.kpi.rh_limit
    }
  },
  components: {
    Modal,
    EnergyLabel,
    DataWarning,
    DetailList,
    DetailListItem,
    HistogramChart
  }
}
</script>
<style>
.rh-demand-modal .current-kpis {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: var(--spacing-m);
}
.rh-demand-modal .histogram-chart {
  height: 220px;
}
</style>
