<i18n>
{
  "de": {
    "modalTitle": {
      "ghg_s1": "Scope 1-Emissionen",
      "ghg": "Scope 2-Emissionen",
      "pe": "Primärenergie",
      "ee": "Endenergie"
    },
    "units": {
      "ghg_s1": "t",
      "ghg": "t",
      "pe": "MWh",
      "ee": "MWh"
    },
    "units_specific": {
      "ghg_s1": "kg/m\u00B2",
      "ghg": "kg/m\u00B2",
      "pe": "kWh/m\u00B2",
      "ee": "kWh/m\u00B2"
    },
    "totalLabel": "Modellwert",
    "measuredTitle": "Messwerte",
    "histogramTitle": "Im Vergleich mit anderen Liegenschaften",
    "byCarrier": "Nach Energieträger",
    "byUsage": "Nach Verwendungszweck",
    "currentTitle": "Aktuelle Kennzahlen",
    "projectionTitle": "Absenkpfad"
  }
}
</i18n>

<template>
  <modal @close="$emit('close', $event)" class="kpi-modal" wide>
    <template v-slot:header>
      <h2>{{ $t(`modalTitle.${kpiType}`) }}</h2>
      <h3>{{ building.identifier }} / {{ building.description }}</h3>
    </template>
    <tabs>
      <tab :title="$t('currentTitle')">
        <div class="current-kpis">
          <div>
            <h3>{{ $t('byCarrier') }}</h3>
            <kpi-pie-chart
              key="ghg_s1_by_carrier"
              :kpi-type="kpiType"
              :group-by-type="'carrier'"
              :buildings="portfolioBuilding"
              class="pie-chart"
            />
          </div>
          <div>
            <h3>{{ $t('byUsage') }}</h3>
            <kpi-pie-chart
              key="ghg_s1_by_usage"
              :kpi-type="kpiType"
              :group-by-type="'usage'"
              :buildings="portfolioBuilding"
              class="pie-chart"
            />
          </div>
          <div>
            <h3>{{ $t('histogramTitle') }}</h3>
            <histogram-chart
              :key="`${kpiType}_histogram`"
              :group-by-key="kpiType"
              area-specific
              :buildings="portfolio.buildings"
              :highlight-value="building.kpi[kpiType] / building.kpi.energy_area"
              class="histogram-chart"
            />
          </div>
        </div>
      </tab>
      <tab :title="$t('measuredTitle')">
        <measured-kpi-chart :kpi-type="kpiType" class="kpi-chart" :portfolio="portfolio" :query="{ building_id: this.building.id }" />
      </tab>
      <tab :title="$t('projectionTitle')">
        <scenario-picker v-model="selectedScenario" :portfolio="portfolio" />
        <h4>{{ $t('byCarrier') }}</h4>
        <kpi-by-carrier-chart :kpi-type="kpiType" class="kpi-chart" v-if="selectedScenario" :scenario="selectedScenario" :portfolio="portfolio" :query="{ building_id: this.building.id }" />
        <h4>{{ $t('byUsage') }}</h4>
        <kpi-by-usage-chart :kpi-type="kpiType" class="kpi-chart" v-if="selectedScenario" :scenario="selectedScenario" :portfolio="portfolio" :query="{ building_id: this.building.id }" />
      </tab>
    </tabs>
  </modal>
</template>

<script>
import Tabs from '@/components/shared/Tabs'
import Tab from '@/components/shared/Tab'

import ScenarioPicker from '@/components/shared/ScenarioPicker'
import Modal from '@/components/shared/Modal'
import KpiByUsageChart from '@/components/report/charts/KpiByUsageChart'
import KpiByCarrierChart from '@/components/report/charts/KpiByCarrierChart'

import KpiPieChart from '@/components/portfolio/kpi/charts/KpiPieChart'
import MeasuredKpiChart from '@/components/report/charts/MeasuredKpiChart'
import HistogramChart from '@/components/portfolio/kpi/charts/HistogramChart'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    building: {
      type: Object,
      required: true
    },
    kpiType: {
      type: String, // ghg, ghg_s1, pe or ee
      required: true
    }
  },
  data: () => {
    return {
      selectedScenario: null
    }
  },
  computed: {
    portfolioBuilding () {
      return this.portfolio.buildings.filter(b => b.id === this.building.id) // Workaround because we need compact format here...
    }
  },
  components: {
    ScenarioPicker,
    Modal,
    KpiByCarrierChart,
    KpiByUsageChart,
    HistogramChart,
    MeasuredKpiChart,
    KpiPieChart,
    Tab,
    Tabs
  }
}
</script>
<style>
.kpi-modal .current-kpis {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: var(--spacing-m);
}
.kpi-modal .kpi-chart .chart-container {
  height:270px;
}
.kpi-modal .histogram-chart {
  height: 220px;
}
.kpi-modal .pie-chart > div {
  height: 200px;
}
.kpi-modal .energy-label {
  display: inline;
}
</style>
