<template>
  <div class="energy-label">
    <div class="energy-label-arrow" :style="`border-right-color: ${color}`"></div>
    <span class="energy-label-block" :style="`background-color: ${color}`"><span>{{ label }}</span></span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    labels: {
      type: Array,
      default () {
        return ['+', 'A', 'B', 'C', 'D', 'E', 'F', 'G']
      }
    },
    limits: {
      type: Array,
      default () {
        return [0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0, Infinity]
      }
    },
    colors: {
      type: Array,
      default () {
        return ['rgba(0, 172, 96, 1.0)', 'rgba(0, 172, 96, 1.0)', 'rgba(79, 184, 89, 1.0)', 'rgba(195, 222, 67, 1.0)', 'rgba(232, 232, 0, 1.0)', 'rgba(248, 206, 32, 1.0)', 'rgba(240, 157, 15, 1.0)', 'rgba(226, 46, 49, 1.0)']
      }
    }
  },
  computed: {
    classIndex () {
      return this.limits.findIndex(limit => this.value < limit)
    },
    label () {
      return this.labels[this.classIndex]
    },
    color () {
      return this.colors[this.classIndex]
    }
  }
}
</script>

<style>
.energy-label {
  display: inline-block;
}
.energy-label .energy-label-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 10px solid transparent;
  border-right: 12px solid white;
  border-bottom: 10px solid transparent;
}

.energy-label .energy-label-block {
  display: inline-block;
  width: 1rem;
  height: 20px;
  vertical-align: middle;
  position: relative;
  padding-right: 1.0rem;
  padding-left: 1.0rem;
  overflow: hidden;
  font-size: 13px;
  font-weight: 700;
}

.energy-label .energy-label-block > span {
  color: #fff;
}

</style>
