<!--
Edit form for PvMeasureSchema
-->
<i18n>
{
  "de": {
    "noMeasure": "Keine Renovation",
    "componentNameTitle": "Bauteil",
    "insulationStandardTitle": "Wärmedämmung",
    "costsTitle": "Kosten *",
    "autoCostsHint": "Kosten leer lassen für automatische Kostenschätzung"
  }
}
</i18n>

<template>
  <div class="edit-envelope-measure">
    <detail-list class="components-table">
      <template #header>
        <span>{{ $t('componentNameTitle') }}</span>
        <span>{{ $t('insulationStandardTitle') }}</span>
        <span>{{ $t('costsTitle') }}</span>
      </template>
      <!--
        Windows
      !-->
      <li>
        <div>{{ $t('_envelope.windows') }}</div>
        <div v-if="edit">
          <v-select id="windows-options" @input="onStandardChange" v-model="selectedStandards.windows" :options="replaceWindowsOptions" :clearable="false" :reduce="t => t.id" />
        </div>
        <div v-else-if="model.windows">
          {{ $t(`_windowTypes.${model.windows.standard}`) }}
        </div>
        <div v-if="model.windows">
          <numeric-input @input="onInput" :edit="isEditing" v-model="model.windows.costs" :min="0" optional :units="'CHF'" />
        </div>
      </li>

      <!--
        Walls
      -->
      <li>
        <div>{{ $t('_envelope.walls') }}</div>
        <div v-if="edit">
          <v-select id="walls-options" @input="onStandardChange" v-model="selectedStandards.walls" :options="renovateOptions" :clearable="false" :reduce="t => t.id" />
        </div>
        <div v-else-if="model.walls">
          {{ $t(`_renovateTypes.${model.walls.standard}`) }}
        </div>
        <div v-if="model.walls">
          <numeric-input @input="onInput" :edit="isEditing" v-model="model.walls.costs" :min="0" optional :units="'CHF'" />
        </div>
      </li>

      <!--
        Roof
      -->
      <li>
        <div>{{ $t('_envelope.roof') }}</div>
        <div v-if="edit">
          <v-select id="roof-options" @input="onStandardChange" v-model="selectedStandards.roof" :options="renovateOptions" :clearable="false" :reduce="t => t.id" />
        </div>
        <div v-else-if="model.roof">
          {{ $t(`_renovateTypes.${model.roof.standard}`) }}
        </div>
        <div v-if="model.roof">
          <numeric-input @input="onInput" :edit="isEditing" v-model="model.roof.costs" :min="0" optional :units="'CHF'" />
        </div>
      </li>

      <!--
        Basement
      -->
      <li>
        <div>{{ $t('_envelope.basement') }}</div>
        <div v-if="edit">
          <v-select id="basement-options" @input="onStandardChange" v-model="selectedStandards.basement" :options="renovateOptions" :clearable="false" :reduce="t => t.id" />
        </div>
        <div v-else-if="model.basement">
          {{ $t(`_renovateTypes.${model.basement.standard}`) }}
        </div>
        <div v-if="model.basement">
          <numeric-input @input="onInput" :edit="isEditing" v-model="model.basement.costs" :min="0" optional :units="'CHF'" />
        </div>
      </li>
    </detail-list>
    <div class="hint">* {{ $t('autoCostsHint') }}</div>
  </div>
</template>
<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin'

import DetailList from '@/components/shared/lists/DetailList'
import NumericInput from '@/components/shared/forms/NumericInput'

export default {
  mixins: [ComplexFormMixin],
  methods: {
    onStandardChange () {
      for (const c of ['windows', 'walls', 'basement', 'roof']) {
        if (this.selectedStandards[c] === 'NONE') {
          this.model[c] = null
        } else if (this.model[c] === null) {
          this.model[c] = {
            'standard': this.selectedStandards[c],
            'costs': null
          }
        } else {
          this.model[c].standard = this.selectedStandards[c]
        }
      }
      this.onInput()
    },
    resetStandards () {
      for (const c of ['windows', 'walls', 'basement', 'roof']) {
        if (this.model[c] === null) {
          this.selectedStandards[c] = 'NONE'
        } else {
          this.selectedStandards[c] = this.model[c].standard
        }
      }
      this.onInput()
    }
  },
  created () {
    this.resetStandards()
  },
  data () {
    return {
      selectedStandards: {
        'windows': null,
        'walls': null,
        'basement': null,
        'roof': null
      },
      replaceWindowsOptions: [
        { id: 'NONE', label: this.$t('noMeasure') },
        // { id: 'CUSTOM', label: this.$t('customInsulation') },
        { id: 'MINERGIE_2014', label: this.$t('_windowTypes.MINERGIE_2014') },
        { id: 'MuKEn_2014', label: this.$t('_windowTypes.MuKEn_2014') },
        { id: 'MuKEn_2008', label: this.$t('_windowTypes.MuKEn_2008') }
      ],
      renovateOptions: [
        { id: 'NONE', label: this.$t('noMeasure') },
        // { id: 'CUSTOM', label: this.$t('customInsulation') },
        { id: 'MINERGIE_2014', label: this.$t('_renovateTypes.MINERGIE_2014') },
        { id: 'MuKEn_2014', label: this.$t('_renovateTypes.MuKEn_2014') },
        { id: 'MuKEn_2014_RENOVATION', label: this.$t('_renovateTypes.MuKEn_2014_RENOVATION') }
      ]
    }
  },
  components: {
    DetailList,
    NumericInput
  }
}
</script>
<style>
.edit-envelope-measure .detail-list > ul > li {
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
