<i18n>
{
  "de": {
    "feasibilitySectionTitle": "Machbarkeit\u00ads- und Potenzialabklärungen"
  }
}
</i18n>

<template>
  <detail-list :title="$t('feasibilitySectionTitle')">
    <geothermal-summary-row :building="building" v-if="building.geothermal" />
    <energy-grids-summary-row :building="building" v-if="building.energy_grids" />
    <electricity-utility-summary-row :building="building" />
    <pv-potential-row :building="building" />
  </detail-list>
</template>

<script>
import EnergyGridsSummaryRow from '@/components/building/feasibility/EnergyGridsSummaryRow'
import ElectricityUtilitySummaryRow from '@/components/building/feasibility/ElectricityUtilitySummaryRow'
import DetailList from '@/components/shared/lists/DetailList'
import PvPotentialRow from '@/components/building/feasibility/PvPotentialRow'
import GeothermalSummaryRow from '@/components/building/feasibility/GeothermalSummaryRow'

export default {
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  components: {
    EnergyGridsSummaryRow,
    ElectricityUtilitySummaryRow,
    GeothermalSummaryRow,
    DetailList,
    PvPotentialRow
  }
}
</script>
