<i18n>
{
  "de": {
    "header": "Nutzenergie Warmwasser",
    "highCalibrationFactorWarningTitle": "Effektiver Energieverbrauch höher als erwartet",
    "highCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich über dem modellierten Energiebedarf. Für mögliche Ursachen siehe Kalibrierung Wärmeerzeugung.",
    "lowCalibrationFactorWarningTitle": "Effektiver Energieverbrauch tiefer als erwartet",
    "lowCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich unter dem modellierten Energiebedarf. Für mögliche Ursachen siehe Kalibrierung Wärmeerzeugung."
  }
}
</i18n>

<template>
  <detail-list-item :title="$t('header')" expandable @expand="onModalOpen">
    <div class="two-columns">
      <span>
        {{ formatNumber(building.kpi.dhw_demand / 1000, 1) }} MWh
        <span v-if="building.kpi.dhw_calibration > 1.667">
          <data-warning :title="$t('highCalibrationFactorWarningTitle')"><span v-html="$t('highCalibrationFactorWarning')" /></data-warning>
        </span>
        <span v-else-if="building.kpi.dhw_calibration < 0.6">
          <data-warning :title="$t('lowCalibrationFactorWarningTitle')"><span v-html="$t('lowCalibrationFactorWarning')" /></data-warning>
        </span>
      </span>
      <span>{{ formatNumber(building.kpi.dhw_demand / building.kpi.energy_area, 1) }} kWh/m<sup>2</sup></span>
    </div>
    <template #modal>
      <dhw-modal v-if="modalOpen" @close="onModalClose" :building="building" />
    </template>
  </detail-list-item>
</template>

<script>
import DetailListItem from '@/components/shared/lists/DetailListItem'
import DataWarning from '@/components/shared/DataWarning'
import DhwModal from '@/components/building/kpi/DhwModal'

export default {
  data: function () {
    return {
      modalOpen: false
    }
  },
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  methods: {
    onModalOpen () {
      this.modalOpen = true
    },
    onModalClose () {
      this.modalOpen = false
    }
  },
  components: {
    DetailListItem,
    DataWarning,
    DhwModal
  }
}
</script>
