<i18n>
{
  "de": {
    "pvPotentialHeader": "Solarstrahlung",
    "noGisData": "Keine GIS-Daten verfügbar"
  }
}
</i18n>

<template>
  <detail-list-item class="pv-potential-row" :title="$t('pvPotentialHeader')" expandable @expand="onModalOpen">
    <div v-if="building.roof.state == 'OK'">
      {{ formatNumber(totalIrradiation, 0) }} MWh
    </div>
    <div class="missing-data" v-else>
      {{ $t(`_roofState.${building.roof.state}`) }}
    </div>
    <template #modal>
      <pv-potential-modal v-if="modalOpen" @close="onModalClose" :building="building" />
    </template>
  </detail-list-item>
</template>

<script>
import DetailListItem from '@/components/shared/lists/DetailListItem'
import PvPotentialModal from '@/components/building/feasibility/PvPotentialModal'

export default {
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      modalOpen: false
    }
  },
  computed: {
    totalIrradiation () {
      const roofIrradiation = this.building.roof.roof_tiles.reduce((pv, cv) => pv + cv.irradiation * cv.area, 0)
      const fassadeIrradiation = this.building.roof.fassade_tiles.reduce((pv, cv) => pv + cv.irradiation * cv.area, 0)
      return (roofIrradiation + fassadeIrradiation) / 1000
    }
  },
  components: {
    DetailListItem,
    PvPotentialModal
  },
  methods: {
    onModalOpen () {
      this.modalOpen = true
    },
    onModalClose () {
      this.modalOpen = false
    }
  }
}
</script>
<style scoped>
.missing-data {
  color:var(--warning-color);
  font-size: var(--font-xs);
}
</style>
