<!--
Edit form for PvSchema (used for currently existing pv)
-->
<i18n>
{
  "de": {
    "pvYear": "Baujahr",
    "remarksTitle": "Bemerkungen"
  }
}
</i18n>

<template>
  <edit-container :bind-form-state="formState" @container="onFormEvent" class="edit-pv">
    <form-row :label="$t('pvYear')">
      <numeric-input noFormatting int optional v-model="model.year" :min="0" :max="2100" :edit="isEditing" class="limit-width" />
      &nbsp;<estimate-input v-model="model.year_estimated" :edit="isEditing" />
    </form-row>
    <form-row :label="$t('remarksTitle')">
      <text-input v-model="model.remarks" :edit="isEditing" />
    </form-row>
    <edit-pv-sections
      v-model="model.sections"
      :pv-results="pvResults"
      :edit="isEditing"
      @input="onSectionsChange"
      @validation="onValidation($event, 'sections')"
    />
  </edit-container>
</template>
<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin'
import EditContainer from '@/components/shared/forms/EditContainer'

import EditPvSections from '@/components/building/edit/EditPvSections'
import NumericInput from '@/components/shared/forms/NumericInput'
import FormRow from '@/components/shared/forms/FormRow'
import TextInput from '@/components/shared/forms/TextInput'
import EstimateInput from '@/components/shared/forms/EstimateInput'

export default {
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  mixins: [
    ApiFormMixin
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation */
  ],
  watch: {
    building: function () {
      this.resetModel()
    }
  },
  computed: {
    pvResults () {
      return this.building.initial_state.result && this.building.initial_state.result.pv
    }
  },
  methods: {
    ...mapActions({
      updateBuilding: 'building/updateBuilding'
    }),
    onSectionsChange (sections) {
      this.model.sections = sections
    },
    resetModel () {
      this.model = {
        'sections': [],
        'year': this.building.initial_state.pv.year,
        'year_estimated': this.building.initial_state.pv.year_estimated,
        'remarks': this.building.initial_state.pv.remarks
      }
      for (let i = 0; i < this.building.initial_state.pv.sections.length; i++) {
        const section = this.building.initial_state.pv.sections[i]
        this.model.sections.push({
          'capacity': section.capacity,
          'angle': section.angle,
          'orientation': section.orientation,
          'loss': section.loss,
          'production_manual': section.production_manual,
          'ownuse_ratio_manual': section.ownuse_ratio_manual
        })
      }
    },
    async saveHandler () {
      await this.updateBuilding({
        'id': this.building.id,
        'initial_state': {
          'pv': this.model
        }
      })
    }
  },
  components: {
    EditContainer,
    NumericInput,
    FormRow,
    TextInput,
    EstimateInput,
    EditPvSections
  }
}
</script>
<style>
.edit-pv .limit-width {
  max-width: 150px;
  display: inline-block;
}
</style>
