<!--
Edit form for PvDimensioningSchema (used for measures and scenarios)
-->
<i18n>
{
  "de": {
    "coverageTargetTitle": "Deckungsziel",
    "roofMinYieldTitle": "Mindestertrag",
    "roofMinModulesTitle": "Mindestgrösse",
    "roofMaxShapeFactorTitle": "Max. Formfaktor",
    "fassadeFractionTitle": "Anteil nutzbar",
    "fassadeMinModulesTitle": "Mindestgrösse",
    "fassadeMinYieldTitle": "Mindestertrag",
    "roofTitle": "Dachflächen",
    "fassadeTitle": "Fassadenflächen",
    "coverageInfoTitle": "Ziel-Deckungsgrad Jahresstrombedarf",
    "coverageInfoText": "Dach- und Fassadenflächen, die die Kriternen erfüllen, werden geordnet nach dem grössten Stromertrag mit Photovoltaik-Modulen belegt, bis der Ziel-Deckungsgrad erreicht ist. Wenn dieses Feld leergelassen wird, werden alle Flächen belegt.",
    "roofMinYieldInfoTitle": "Minimalertrag Dachflächen",
    "roofMinYieldInfoText": "Es werden nur Flächen mit grösserem Stromertrag berücksichtigt. Kann leergelassen werden, wenn keine Untergrenze festgelegt werden soll.",
    "roofMinModulesInfoTitle": "Minimale Anzahl Module pro Dachfläche",
    "roofMinModulesInfoText": "Bezieht sich auf Standardmodule mit 100 x 160cm Grösse. Dachflächen, die diesen Grenzwert unterschreiten werden nicht berücksichtigt. Kann leergelassen werden, wenn keine Untergrenze festgelegt werden soll.",
    "roofMaxShapeFactorInfoTitle": "Maximaler Formfaktor",
    "roofMaxShapeFactorInfoText": "Kennwert für Form der Dachfläche. 1.0 entspricht einem perfekten Quadrat. Unförmige oder sehr schmale Dachflächen haben grosse Formfaktoren. Empfohlener Maximalwert: 5.0",
    "fassadeMinYieldInfoTitle": "Minimalertrag Fassadenflächen",
    "fassadeMinYieldInfoText": "Es werden nur Flächen mit grösserem Stromertrag berücksichtigt. Kann leergelassen werden, wenn keine Untergrenze festgelegt werden soll.",
    "fassadeMinModulesInfoTitle": "Minimale Anzahl Module pro Fassadenfläche",
    "fassadeMinModulesInfoText": "Nach Abzug des nutzbaren Anteils. Bezieht sich auf Standardmodule mit 100 x 160cm Grösse. Dachflächen, die diesen Grenzwert unterschreiten werden nicht berücksichtigt. Kann leergelassen werden, wenn keine Untergrenze festgelegt werden soll.",
    "fassadeFractionInfoTitle": "Fassadenanteil nutzbar für Photovoltaik",
    "fassadeFractionInfoText": "Anteil der Fassadenfläche (inklusive Fenster, Balkone, etc.), der für Photovoltaik genutzt werden kann."
  }
}
</i18n>

<template>
  <div class="edit-pv-dimensioning">
    <form-row :label="$t('coverageTargetTitle')" :info-title="$t('coverageInfoTitle')" :info-text="$t('coverageInfoText')">
      <numeric-input v-model="model.coverage_target" optional @input="onInput" :edit="isEditing" :units="'%'" />
    </form-row>
    <h3>{{ $t('roofTitle') }}</h3>
    <form-row :label="$t('roofMinYieldTitle')" :info-title="$t('roofMinYieldInfoTitle')" :info-text="$t('roofMinYieldInfoText')">
      <numeric-input v-model="model.roof_min_yield" optional @input="onInput" :edit="isEditing" :units="'kWh/kWp'" />
    </form-row>
    <form-row :label="$t('roofMinModulesTitle')" :info-title="$t('roofMinModulesInfoTitle')" :info-text="$t('roofMinModulesInfoText')">
      <numeric-input v-model="model.roof_min_modules" optional @input="onInput" :edit="isEditing" :units="'Module'" />
    </form-row>
    <form-row :label="$t('roofMaxShapeFactorTitle')" :info-title="$t('roofMaxShapeFactorInfoTitle')" :info-text="$t('roofMaxShapeFactorInfoText')">
      <numeric-input v-model="model.roof_max_shape_factor" optional @input="onInput" :edit="isEditing" :units="''" />
    </form-row>
    <h3>{{ $t('fassadeTitle') }}</h3>
    <form-row :label="$t('fassadeMinYieldTitle')" :info-title="$t('fassadeMinYieldInfoTitle')" :info-text="$t('fassadeMinYieldInfoText')">
      <numeric-input v-model="model.fassade_min_yield" optional @input="onInput" :edit="isEditing" :units="'kWh/kWp'" />
    </form-row>
    <form-row :label="$t('fassadeMinModulesTitle')" :info-title="$t('fassadeMinModulesInfoTitle')" :info-text="$t('fassadeMinModulesInfoText')">
      <numeric-input v-model="model.fassade_min_modules" optional @input="onInput" :edit="isEditing" :units="'Module'" />
    </form-row>
    <form-row :label="$t('fassadeFractionTitle')" :info-title="$t('fassadeFractionInfoTitle')" :info-text="$t('fassadeFractionInfoText')">
      <numeric-input v-model="model.fassade_fraction" optional @input="onInput" :edit="isEditing" :units="'%'" />
    </form-row>
  </div>
</template>
<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin'

import NumericInput from '@/components/shared/forms/NumericInput'
import FormRow from '@/components/shared/forms/FormRow'

export default {
  mixins: [ComplexFormMixin],
  watch: {
    model () {
      if (this.model === null) {
        this.setDefaults()
      }
    }
  },
  created () {
    if (this.model === null) {
      this.setDefaults()
    }
  },
  methods: {
    setDefaults () {
      this.model = {
        coverage_target: 100,
        roof_min_yield: 800,
        roof_min_modules: 10,
        roof_max_shape_factor: 5,
        fassade_fraction: null,
        fassade_min_modules: null,
        fassade_min_yield: null
      }
      this.onInput()
    }
  },
  components: {
    NumericInput,
    FormRow
  }
}
</script>
<style>
.edit-pv-dimensioning .numeric-input  {
  max-width: 150px;
}
</style>
