<script>
import BaseLayer from '@/components/building/map/BaseLayer'

export default {
  extends: BaseLayer,
  components: {
    BaseLayer
  },
  computed: {
    sourceConfig () {
      let features = []
      if (this.building.cadastre.state === 'OK') {
        for (let i = 0; i < this.building.cadastre.parcels.length; i++) {
          let parcel = this.building.cadastre.parcels[i]
          features.push({
            'id': i,
            'type': 'Feature',
            'geometry': parcel['geometry'],
            'properties': parcel
          })
        }
      }
      return {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': features
        }
      }
    },
    layerConfig () {
      return {
        type: 'line',
        source: this.sourceId,
        paint: {
          'line-color': '#000000',
          'line-width': 3,
          'line-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.7,
            0.4
          ]
        }
      }
    }
  }
}
</script>
