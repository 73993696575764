<!--
TODO: Move estimate stuff to different component
-->

<i18n>
{
  "de": {
    "zoneModalTitle": "Flächen und Nutzung",
    "pendingMessage": "Daten werden gespeichert und Gebäude neu simuliert...",
    "estimatesHeading": "Hochrechnung Bruttogeschossfläche (BGF)",
    "estimatesGwrTitle": "Gebäude- und Wohnungsregister (GWR)",
    "estimatesRoofTitle": "Dachflächen",
    "estimatesVolumeTitle": "Gebäudevolumen",
    "gwrHeading": "Hochrechnung über Gebäude- und Wohnungsregister (GWR)",
    "gwrEgidTitle": "EGID",
    "gwrAddressTitle": "Adresse",
    "gwrFloorsTitle": "Anzahl Geschosse",
    "gwrBuildingAreaTitle": "Gebäudefläche",
    "gwrFloorAreaTitle": "Bruttogeschossfläche (BGF)",
    "gwrTotalTitle": "Total",
    "roofHeading": "Hochrechnung über Dachflächen",
    "roofAreaTitle": "Gebäudefläche",
    "roofFloorsTitle": "Geschosszahl",
    "roofFloorAreaTitle": "Bruttogeschoffläche (BGF)",
    "volumeHeading": "Hochrechnung über Gebäudevolumen",
    "volumeTitle": "Gebäudevolumen",
    "volumeFloorHeightTitle": "Geschosshöhe",
    "volumeFloorAreaTitle": "Bruttogeschossfläche (BGF)",
    "showDetails": "Details zeigen",
    "hideDetails": "Details ausblenden",
    "showEstimates": "Hochrechnung BGF",
    "hideEstimates": "Hochrechnung BGF ausblenden"
  }
}
</i18n>
<template>
  <modal @close="$emit('close', $event)" wide class="zones-modal">
    <template v-slot:header>
      <h2>{{ $t('zoneModalTitle') }}</h2>
    </template>

    <edit-zones
      :allow-edit="building.permissions.EDIT_ZONES"
      :building="building"
    />

    <button-wrapper>
      <button class="button" v-if="!showEstimates" @click="showEstimates = true">{{ $t('showEstimates') }}</button>
      <button class="button" v-if="showEstimates" @click="showEstimates = false">{{ $t('hideEstimates') }}</button>
    </button-wrapper>
    <div v-if="showEstimates">
      <h3>{{ $t('estimatesHeading') }}</h3>
      <detail-list>
        <detail-list-item :title="$t('estimatesGwrTitle')">
          <span v-if="totalFloorArea">{{ formatNumber(totalFloorArea, 0) }} m<sup>2</sup></span><span v-else>-</span>
        </detail-list-item>
        <detail-list-item :title="$t('estimatesRoofTitle')">
          <span v-if="roofProjections && building.initial_state.result">{{ formatNumber(roofProjections * building.initial_state.result.envelope.floors_number.value, 0) }} m<sup>2</sup></span><span v-else>-</span>
        </detail-list-item>
        <detail-list-item :title="$t('estimatesVolumeTitle')">
          <span v-if="building.initial_state.envelope.volume">{{ formatNumber(building.initial_state.envelope.volume / 3.0, 0) }} m<sup>2</sup></span><span v-else>-</span>
        </detail-list-item>
      </detail-list>
      <button-wrapper>
        <button class="button" v-if="!showEstimateDetails" @click="showEstimateDetails = true">{{ $t('showDetails') }}</button>
        <button class="button" v-if="showEstimateDetails" @click="showEstimateDetails = false">{{ $t('hideDetails') }}</button>
      </button-wrapper>

      <div v-if="showEstimateDetails">
        <h3>{{ $t('gwrHeading') }}</h3>
        <detail-list class="addresses-list">
          <template #header>
            <span>{{ $t('gwrEgidTitle') }}</span>
            <span>{{ $t('gwrAddressTitle') }}</span>
            <span>{{ $t('gwrBuildingAreaTitle') }}</span>
            <span>{{ $t('gwrFloorsTitle') }}</span>
            <span>{{ $t('gwrFloorAreaTitle') }}</span>
          </template>
          <li v-for="(entry, index) in building.gwr.entries" :key="index">
            <span>{{ entry.properties.egid }}</span>
            <span>{{ entry.properties.strname_deinr }}</span>
            <span v-if="entry.properties.garea">{{ formatNumber(entry.properties.garea, 0) }} m<sup>2</sup></span>
            <span v-else>-</span>
            <span v-if="entry.properties.gastw">{{ getGwrFloors(entry) }} ({{ entry.properties.gastw }})</span>
            <span v-else>-</span>
            <span v-if="entry.properties.garea && entry.properties.gastw">{{ formatNumber(entry.properties.garea * getGwrFloors(entry), 0) }} m<sup>2</sup></span>
            <span v-else>-</span>
          </li>
          <template #footer>
            <span>{{ $t('gwrTotalTitle') }}</span>
            <span></span>
            <span v-if="totalBuildingArea">{{ formatNumber(totalBuildingArea, 0) }} m<sup>2</sup></span>
            <span v-else>-</span>
            <span></span>
            <span v-if="totalFloorArea">
              {{ formatNumber(totalFloorArea, 0) }} m<sup>2</sup>
            </span>
            <span v-else>-</span>
          </template>
        </detail-list>
      </div>

      <div v-if="showEstimateDetails && roofProjections && building.initial_state.result">
        <h3>{{ $t('roofHeading') }}</h3>
        <detail-list>
          <detail-list-item :title="$t('roofAreaTitle')">
            {{ formatNumber(roofProjections, 0) }} m<sup>2</sup>
          </detail-list-item>
          <detail-list-item :title="$t('roofFloorsTitle')">
            {{ building.initial_state.result.envelope.floors_number.value }}
          </detail-list-item>
          <detail-list-item :title="$t('roofFloorAreaTitle')">
            {{ formatNumber(roofProjections * building.initial_state.result.envelope.floors_number.value, 0) }} m<sup>2</sup>
          </detail-list-item>
        </detail-list>
      </div>

      <div v-if="showEstimateDetails && building.initial_state.envelope.volume">
        <h3>{{ $t('volumeHeading') }}</h3>
        <detail-list>
          <detail-list-item :title="$t('volumeTitle')">
            {{ formatNumber(building.initial_state.envelope.volume, 0) }} m<sup>3</sup>
          </detail-list-item>
          <detail-list-item :title="$t('volumeFloorHeightTitle')">
            3.0 m
          </detail-list-item>
          <detail-list-item :title="$t('volumeFloorAreaTitle')">
            {{ formatNumber(building.initial_state.envelope.volume / 3.0, 0) }} m<sup>2</sup>
          </detail-list-item>
        </detail-list>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/shared/Modal'

import ButtonWrapper from '@/components/shared/ButtonWrapper'
import EditZones from '@/components/building/edit/EditZones'
import DetailList from '@/components/shared/lists/DetailList'
import DetailListItem from '@/components/shared/lists/DetailListItem'

export default {
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showEstimates: false,
      showEstimateDetails: false
    }
  },
  methods: {
    // Same logic in backend (buildings.py)!
    getGwrFloors: function (entry) {
      let nFloors = entry['properties']['gastw']
      if (nFloors >= 5) {
        return nFloors - 2 // Assume buildings with at least 5 floors have two unheated floors
      }
      if (nFloors >= 3) {
        return nFloors - 1 // # Assume buildings with at least 3 floors have one unheated floor
      }
      return nFloors
    }
  },
  computed: {
    totalBuildingArea () {
      return this.building.gwr.entries.reduce((a, p) => a + p.properties.garea, 0)
    },
    totalFloorArea () {
      return this.building.gwr.entries.reduce((a, p) => a + p.properties.garea * this.getGwrFloors(p), 0)
    },
    totalEnergyArea () {
      const sum = this.zones.zones.reduce((pv, cv) => pv + parseFloat(cv.energy_area), 0)
      if (Number.isNaN(sum)) {
        return undefined
      }
      return sum
    },
    roofProjections () {
      if (this.building.roof.state === 'OK' && this.building.roof.roof_tiles.length) {
        return this.building.roof.roof_tiles.reduce((pv, cv) => pv + Math.cos(cv.slope / 180 * Math.PI) * cv.area, 0)
      } else {
        return null
      }
    }
  },
  components: {
    ButtonWrapper,
    DetailList,
    DetailListItem,
    EditZones,
    Modal
  }
}
</script>
<style>
.zones-modal .addresses-list > ul > li {
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
}
.zones-modal .validation-comparisson {
  max-width: 500px;
}
</style>
