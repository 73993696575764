<i18n>
{
  "de": {
    "autoEnabled": "Automatisch",
    "autoDisabled": "(Manuell)",
    "autoEnabledQ": "Automatisch",
    "yearHeating": "Baujahr",
    "replacementYearHeating": "Ersatzplanung",
    "nextReplacementInfoBoxTitle": "Jahr nächster Heizungsersatz aus Instandsetzungsplanung",
    "nextReplacementInfoBox": "Wenn dieses Feld leergelassen wird, wird eine Standardlebensdauer angenommen.",
    "distributionReplacementYearHeating": "Ersatzplanung",
    "distributionNextReplacementInfoBoxTitle": "Jahr nächster Eingriff aus Instandsetzungsplanung",
    "distributionNextReplacementInfoBox": "Wenn dieses Feld leergelassen wird, wird eine Standardlebensdauer angenommen.",
    "remarksTitle": "Bemerkungen",
    "heatersTitle": "Wärmeerzeugung",
    "yearEstimatedTooltip": "Das Alter der Wärmeerzeugung ist unsicher",
    "distributionTitle": "Wärmeverteilung",
    "distributionYearTitle": "Baujahr",
    "distributionRemarksTitle": "Bemerkungen",
    "distributionTypeTitle": "Typ",
    "distributionOptions": {
      "UNKNOWN": "Unbekannt",
      "FLOOR_HEATING": "Bodenheizung",
      "CEILING_HEATING": "Deckenheizung",
      "RADIATORS": "Radiatoren",
      "AIR": "Warmluft"
    }
  }
}
</i18n>

<template>
  <edit-container :bind-form-state="formState" @container="onFormEvent" class="edit-heating">
    <h3>{{ $t('heatersTitle') }}</h3>
    <form-row :label="$t('yearHeating')">
      <numeric-input noFormatting int optional
        v-model="model.year" :min="0" :max="2100"
        :edit="isEditing" class="limit-width"
        @validation="onValidation($event, 'year')"
      />
      &nbsp;<estimate-input
        v-model="model.year_estimated"
        :edit="isEditing"
        :tooltip="$t('yearEstimatedTooltip')"
      />
    </form-row>
    <form-row :label="$t('replacementYearHeating')" :info-title="$t('nextReplacementInfoBoxTitle')" :info-text="$t('nextReplacementInfoBox')">
      <numeric-input noFormatting optional int
        v-model="model.replacement_year" :min="0" :max="2100"
        :edit="isEditing" class="limit-width"
        @validation="onValidation($event, 'replacement_year')"
      />
    </form-row>
    <form-row :label="$t('remarksTitle')" v-if="model.remarks || isEditing">
      <text-input
        v-model="model.remarks"
        :edit="isEditing"
        @validation="onValidation($event, 'remarks')"
      />
    </form-row>
    <edit-heaters-table
      v-model="model.heaters"
      :heaters-results="heatersResults"
      :portfolio="portfolio"
      :edit="isEditing"
      @input="onHeatersChange"
      @validation="onValidation($event, 'heaters-table')"
    />
    <h3>{{ $t('distributionTitle') }}</h3>
    <form-row :label="$t('distributionTypeTitle')">
      <div v-if="isEditing" style="max-width:300px;display:inline-block">
        <v-select id="distribution-options" v-model="model.distribution" :options="distributionOptions" :clearable="false" :reduce="t => t.id" class="select-width" />
      </div>
      <div v-else>
        {{ $t(`distributionOptions.${model.distribution}`) }}
      </div>
    </form-row>
    <form-row :label="$t('distributionYearTitle')" v-if="model.distribution_year || isEditing">
      <numeric-input noFormatting int optional v-model="model.distribution_year" :min="0" :max="2100" :edit="isEditing" class="limit-width" />
    </form-row>
    <form-row :label="$t('distributionReplacementYearHeating')" v-if="model.distribution_replacement_year || isEditing" :info-title="$t('distributionNextReplacementInfoBoxTitle')" :info-text="$t('distributionNextReplacementInfoBox')">
      <numeric-input noFormatting optional int v-model="model.distribution_replacement_year" :min="0" :max="2100" :edit="isEditing" class="limit-width" />
    </form-row>
    <form-row :label="$t('distributionRemarksTitle')" v-if="model.distribution_remarks || isEditing">
      <text-input v-model="model.distribution_remarks" :edit="isEditing" />
    </form-row>
  </edit-container>
</template>
<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin'
import EditContainer from '@/components/shared/forms/EditContainer'

import NumericInput from '@/components/shared/forms/NumericInput'
import EditHeatersTable from '@/components/building/edit/EditHeatersTable'
import TextInput from '@/components/shared/forms/TextInput'
import EstimateInput from '@/components/shared/forms/EstimateInput'
import FormRow from '@/components/shared/forms/FormRow'

export default {
  props: {
    building: {
      type: Object,
      required: true
    },
    portfolio: {
      type: Object,
      required: true
    }
  },
  mixins: [
    ApiFormMixin
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation */
  ],
  data () {
    return {
      distributionOptions: [
        { id: 'UNKNOWN', label: this.$t('distributionOptions.UNKNOWN') },
        { id: 'FLOOR_HEATING', label: this.$t('distributionOptions.FLOOR_HEATING') },
        { id: 'CEILING_HEATING', label: this.$t('distributionOptions.CEILING_HEATING') },
        { id: 'RADIATORS', label: this.$t('distributionOptions.RADIATORS') },
        { id: 'AIR', label: this.$t('distributionOptions.AIR') }
      ]
    }
  },
  watch: {
    building: function () {
      this.resetModel()
    }
  },
  computed: {
    heatersResults () {
      return this.building.initial_state.result && this.building.initial_state.result.heaters
    }
  },
  methods: {
    ...mapActions({
      updateBuilding: 'building/updateBuilding'
    }),
    resetModel () {
      this.model = {
        'year': this.building.initial_state.heating.year,
        'year_estimated': this.building.initial_state.heating.year_estimated,
        'replacement_year': this.building.initial_state.heating.replacement_year,
        'remarks': this.building.initial_state.heating.remarks,
        'heaters': [],
        'distribution': this.building.initial_state.heating.distribution,
        'distribution_year': this.building.initial_state.heating.distribution_year,
        'distribution_remarks': this.building.initial_state.heating.distribution_remarks,
        'distribution_replacement_year': this.building.initial_state.heating.distribution_replacement_year
      }
      for (let i = 0; i < this.building.initial_state.heating.heaters.length; i++) {
        const heater = this.building.initial_state.heating.heaters[i]
        this.model.heaters.push({
          'type': heater.type,
          'heat_grid_identifier': heater.heat_grid_identifier,
          'rh_share': heater.rh_share,
          'dhw_share': heater.dhw_share,
          'ghg_factor': heater.ghg_factor,
          'pe_factor': heater.pe_factor,
          'energy_calibration': heater.energy_calibration !== null ? heater.energy_calibration : null
        })
      }
    },
    onHeatersChange (heaters) {
      this.model.heaters = heaters
    },
    async saveHandler (model) {
      await this.updateBuilding({
        'id': this.building.id,
        'initial_state': {
          'heating': {
            'year': model.year,
            'year_estimated': model.year_estimated,
            'replacement_year': model.replacement_year,
            'remarks': model.remarks,
            'heaters': model.heaters.map(h => {
              return {
                type: h.type,
                heat_grid_identifier: h.heat_grid_identifier,
                rh_share: h.rh_share,
                dhw_share: h.dhw_share,
                ghg_factor: h.ghg_factor,
                pe_factor: h.pe_factor,
                energy_calibration: h.energy_calibration
              }
            }),
            'distribution': model.distribution,
            'distribution_year': model.distribution_year,
            'distribution_remarks': model.distribution_remarks,
            'distribution_replacement_year': model.distribution_replacement_year
          }
        }
      })
    }
  },
  components: {
    EditContainer,
    NumericInput,
    EstimateInput,
    EditHeatersTable,
    FormRow,
    TextInput
  }
}
</script>
<style>
.edit-heating .limit-width {
  max-width: 150px;
  display: inline-block;
}
.edit-heating .select-width {
  min-width: 150px;
}
</style>
