<i18n>
{
  "de": {
    "buildingTitle": "Zusammenfassung"
  }
}
</i18n>

<template>
  <div class="overview-section">
    <detail-list :title="$t('buildingTitle')">
      <base-data-summary-row :building="building" />
      <tag-group-summary-row :portfolio="portfolio" :building="building" :tag-group="tagGroup" v-for="(tagGroup, tagIdx) in manualTags" v-bind:key="tagIdx" />
      <location-summary-row :building="building" />
      <zones-summary-row :building="building" />
      <envelope-summary-row :building="building" />
      <heating-summary-row :building="building" :portfolio="portfolio" v-if="!building.initial_state.envelope.without_heating" />
      <pv-summary-row :building="building" />
      <meters-summary-row :building="building" :portfolio="portfolio" />
    </detail-list>
    <kpi-overview :portfolio="portfolio" :building="building" v-if="building && building.kpi && portfolio && portfolio.enabled_features.KPI" />
    <feasibility-summary :building="building" v-if="portfolio && portfolio.enabled_features.HEATING_FEASIBILITY" />
  </div>
</template>

<script>
import DetailList from '@/components/shared/lists/DetailList'
import KpiOverview from '@/components/building/kpi/KpiOverview'
import TagGroupSummaryRow from '@/components/building/overview/TagGroupSummaryRow'
import ZonesSummaryRow from '@/components/building/overview/ZonesSummaryRow'
import LocationSummaryRow from '@/components/building/overview/LocationSummaryRow'
import BaseDataSummaryRow from '@/components/building/overview/BaseDataSummaryRow'
import FeasibilitySummary from '@/components/building/feasibility/FeasibilitySummary'
import EnvelopeSummaryRow from '@/components/building/overview/EnvelopeSummaryRow'
import PvSummaryRow from '@/components/building/overview/PvSummaryRow'
import HeatingSummaryRow from '@/components/building/overview/HeatingSummaryRow'
import MetersSummaryRow from '@/components/building/overview/MetersSummaryRow'

export default {
  props: {
    building: {
      type: Object,
      required: true
    },
    portfolio: {
      type: Object,
      required: true
    }
  },
  computed: {
    manualTags () {
      return this.portfolio.tag_groups.filter(t => !t.auto)
    }
  },
  components: {
    DetailList,
    KpiOverview,
    LocationSummaryRow,
    TagGroupSummaryRow,
    BaseDataSummaryRow,
    ZonesSummaryRow,
    EnvelopeSummaryRow,
    HeatingSummaryRow,
    PvSummaryRow,
    MetersSummaryRow,
    FeasibilitySummary
  }
}
</script>
<style scoped>
/* Small screens: 1 Column */
.overview-section {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-m);
}

/* Medium screens: 2 Columns */
@media screen and (min-width: 45rem) {
  .overview-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Large screens: 3 Columns */
@media screen and (min-width: 70rem) {
  .overview-section {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
