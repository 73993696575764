<i18n>
{
  "de": {
    "heatingHeader": "Heizung",
    "heatingAge": "Alter Heizung",
    "lifecyclePopupTitle": "Zustand Heizung",
    "noHeating": "Keine Heizung",
    "years": "Jahre",
    "yearUnknownWarning": "Das Alter der Wärmeerzeugung unsicher",
    "missingHeatingType": "Heizungstyp unbekannt"
  }
}
</i18n>

<template>
  <detail-list-item class="heating-summary-row" :title="$t('heatingHeader')" expandable @expand="onModalOpen">
    <div class="heating-summary-contents">
      <div v-if="hasMissingHeating">
        <missing-data-label>{{ $t('missingHeatingType') }}</missing-data-label>
      </div>
      <div v-else>
        <span v-for="(h, index) in building.initial_state.heating.heaters" :key="index">
          <span v-if="h.type === 'DISTRICT'">
            <span v-if="heatersResults && heatersResults[index] && heatersResults[index].energy_grid.source === 'GIS_ENERGY_GRID'">{{ heatersResults[index].energy_grid.name }} ({{ heatersResults[index].energy_grid.operator }})</span>
            <span v-else>{{ $t(`_heatingTypes.${h.type}`)}}</span>
          </span>
          <span v-else-if="h.type === 'LOCAL'">
            <span v-if="h.heat_grid_identifier">{{ $t(`_heatingTypes.${h.type}`)}}: {{ getGridLabel(h.heat_grid_identifier) }}</span>
            <span v-else>{{ $t(`_heatingTypes.${h.type}`)}}</span>
          </span>
          <span v-else>{{ $t(`_heatingTypes.${h.type}`)}}</span>
          <span v-if="index !== building.initial_state.heating.heaters.length -1">, </span>
        </span>
        <span v-if="building.initial_state.heating.heaters.length === 0">{{ $t('noHeating')}}</span>
        <span v-else>
          (<data-warning v-if="building.initial_state.heating.year_estimated">{{ $t('yearUnknownWarning') }}</data-warning>{{ building.heating_year }})
        </span>
      </div>
    </div>
    <template #rightContent>
      <v-popover trigger="hover" placement="right" class="lifecycle-popover">
        <ring-indicator :year="building.heating_year" :minLifetime="building.heating_replacement_year" :maxLifetime="building.heating_replacement_year + 5" class="ring-indicator" />
        <template slot="popover">
          <header>
            {{ $t('lifecyclePopupTitle') }}
          </header>
          <main class="heating-summary-row-lifecycle">
            <ring-indicator :year="building.heating_year" :minLifetime="building.heating_replacement_year" :maxLifetime="building.heating_replacement_year + 5" />
            {{ $t('heatingAge') }}: {{ heatingAge }} {{ $t('years') }}
          </main>
        </template>
      </v-popover>
    </template>
    <template #modal>
      <modal v-if="modalOpen" @close="onModalClose" wide>
        <template v-slot:header>
          <h2>{{ $t('heatingHeader') }}</h2>
        </template>
        <edit-heating
          :building="building"
          :allow-edit="building.permissions.EDIT_HEATING"
          :portfolio="portfolio"
        />
      </modal>
    </template>
  </detail-list-item>
</template>

<script>
import Modal from '@/components/shared/Modal'
import ModalMixin from '@/components/shared/ModalMixin'
import DetailListItem from '@/components/shared/lists/DetailListItem'
import RingIndicator from '@/components/shared/RingIndicator'
import MissingDataLabel from '@/components/shared/MissingDataLabel'
import DataWarning from '@/components/shared/DataWarning'
import EditHeating from '@/components/building/edit/EditHeating'

import { VPopover } from 'v-tooltip'

export default {
  mixins: [ModalMixin],
  props: {
    portfolio: {
      type: Object
    },
    building: {
      type: Object,
      required: true
    }
  },
  computed: {
    heatersResults () {
      return this.building.initial_state.result && this.building.initial_state.result.heaters
    },
    hasMissingHeating () {
      if (this.building.initial_state.envelope.without_heating && this.building.initial_state.zones.zones.find(z => z.has_dhw) === undefined) {
        // Don't require heating if envelope is not heated and no dhw demand
        return false
      }
      return this.building.initial_state.heating.heaters.find(z => z.type === 'UNKNOWN') || this.building.initial_state.heating.heaters.length === 0
    },
    heatingAge () {
      return (new Date().getFullYear()) - this.building.heating_year
    }
  },
  methods: {
    getGridLabel (gridIdentifier) {
      const grid = this.portfolio.heat_grids.find(g => g.identifier === gridIdentifier)
      return grid && grid.name
    }
  },
  components: {
    EditHeating,
    DetailListItem,
    MissingDataLabel,
    RingIndicator,
    VPopover,
    DataWarning,
    Modal
  }
}
</script>

<style>
.heating-summary-row {
  position: relative;
}

.heating-summary-row .lifecycle-popover {
    display: inline;
}

.heating-summary-row .heating-summary-contents {
    display: grid;
    grid-template-columns: 1fr max-content max-content;
}

.heating-summary-row .ring-indicator {
  height:28px;
  margin-right:7px;
  vertical-align: middle;
}

.heating-summary-row-lifecycle {
  display: grid;
  grid-template-columns: 32px 1fr;
}

.heating-summary-row-lifecycle .ring-indicator {
  height:24px;
}
</style>
