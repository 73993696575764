<i18n>
{
  "de": {
    "gisError": "GIS-Abfragefehler",
    "deleteBuilding": "Liegenschaft löschen",
    "deleteConfirmation": "Wollen Sie diese Liegenschaft wirklich löschen?",
    "exportBuilding": "JSON-Export",
    "pvProfitabilityTitle": "Variantenvergleich Photovoltaik",
    "measuresTitle": "Massnahmen"
  }
}
</i18n>

<template>
  <main-layout :portfolio="portfolio" :ready="portfolioReady && portfolioBuildingsReady && buildingReady" class="building-page">
    <template v-slot:default>
      <building-map :building="building" v-if="building.latitude !== null && building.longitude !== null" class="building-map" />
      <div class="gis-links">
        <a :href="googleMapsUrl" v-if="googleMapsUrl" target="_blank">Google Maps</a>
        <a :href="swissGisUrl" v-if="swissGisUrl" target="_blank">map.geo.admin.ch</a>
        <a :href="cantonZhGisAvUrl" v-if="cantonZhGisAvUrl" target="_blank">Amtliche Vermessung Kanton ZH</a>
        <a :href="oerebZhGisUrl" v-if="oerebZhGisUrl" target="_blank">ÖREB Kanton ZH</a>
      </div>
      <header class="building-header">
        <div>{{ building.identifier }}</div>
        <h1>{{ building.description }}</h1>
      </header>
      <overview-section class="building-page-section" :building="building" :portfolio="portfolio" />
      <h2>{{ $t('measuresTitle') }}</h2>
      <measures-list :building="building" :portfolio="portfolio" />
      <div v-if="portfolio.enabled_features.PV_PROFITABILITY">
        <br /><br />
        <h2>{{ $t('pvProfitabilityTitle') }}</h2>
        <pv-overview :building="building" :portfolio="portfolio" />
      </div>
      <button-wrapper v-if="building.permissions.DELETE">
        <button class="button" @click="onDelete">{{ $t('deleteBuilding') }}</button>
        <button class="button" @click="onExport">{{ $t('exportBuilding') }}</button>
      </button-wrapper>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin'
import PortfolioBuildingsMixin from '@/pages/vuex-mixins/PortfolioBuildingsMixin'
import BuildingMixin from '@/pages/vuex-mixins/BuildingMixin'

import ButtonWrapper from '@/components/shared/ButtonWrapper'
import BuildingMap from '@/components/building/map/BuildingMap'
import OverviewSection from '@/components/building/OverviewSection'
import PvOverview from '@/components/building/PvOverview'
import MeasuresList from '@/components/building/measures/MeasuresList'
import exportService from '@/services/export-service'

import { mapActions } from 'vuex'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    PortfolioBuildingsMixin, // Provides: portfolio.buildings, portfolioBuildingsLoading, portfolioBuildingsReady
    BuildingMixin // Provides: building, buildingReady, buildingLoading
  ],
  data () {
    return {
      deletePending: false,
      deleteError: null,
      exportPending: false,
      exportError: null
    }
  },
  computed: {
    googleMapsUrl () {
      const zoom = 125
      const tilt = 45
      const angle = 0
      const cameraLatitude = this.building.latitude - 0.001 // move camera south
      const cameraLongitude = this.building.longitude
      return `https://www.google.com/maps/place/${this.building.latitude} N ${this.building.longitude} E/@${cameraLatitude},${cameraLongitude},${zoom}a,35y,${angle}h,${tilt}t/data=!3m1!1e3!4m5!3m4!1s0x0:0x0!8m2!3d${this.building.latitude}!4d${this.building.longitude}`
    },
    swissGisUrl () {
      if (this.building.location_info.state !== 'OK') {
        return false
      }
      const zoom = 12
      const coordsEast = this.building.location_info.data.coordinates_lv95.x
      const coordsNorth = this.building.location_info.data.coordinates_lv95.y
      return `https://map.geo.admin.ch/?lang=de&topic=ech&bgLayer=ch.swisstopo.pixelkarte-farbe&layers=ch.swisstopo.zeitreihen,ch.swisstopo-vd.amtliche-vermessung,ch.kantone.cadastralwebmap-farbe,ch.swisstopo.swissimage-product,ch.bfe.solarenergie-eignung-fassaden,ch.bfe.solarenergie-eignung-daecher,ch.bfs.gebaeude_wohnungs_register&layers_visibility=false,false,true,false,false,false,true&layers_timestamp=18641231,,,,,&E=${coordsEast}&N=${coordsNorth}&zoom=${zoom}`
    },
    cantonZhGisAvUrl () {
      if (this.building.location_info.state !== 'OK' || this.building.gwr.entries[0].properties.gdekt !== 'ZH') {
        return false
      }
      const coordsEast = this.building.location_info.data.coordinates_lv95.x
      const coordsNorth = this.building.location_info.data.coordinates_lv95.y
      const scale = 488
      return `https://maps.zh.ch?topic=AVfarbigZH&scale=${scale}&x=${coordsEast}&y=${coordsNorth}&srid=2056&offlayers=av-stand%2CLCOBJPROJ%2Cbezirkslabels`
    },
    oerebZhGisUrl () {
      if (this.building.location_info.state !== 'OK' || this.building.gwr.entries[0].properties.gdekt !== 'ZH') {
        return false
      }
      const coordsEast = this.building.location_info.data.coordinates_lv95.x
      const coordsNorth = this.building.location_info.data.coordinates_lv95.y
      const scale = 488
      return `https://maps.zh.ch?topic=OerebKatasterZH&scale=${scale}&x=${coordsEast}&y=${coordsNorth}&srid=2056&offlayers=projektierungszonen-flughafenanlagen%2Csicherheitszonen%2Ckbs-oev%2Ckbs-zfp%2Ckbs-militaer-bund%2Cul-breiche%2Claermempfindlichkeitsstufen%2Cls-proj%2Cul-b-proj%2Ckbs%2Cgrundwasserschutzareale%2Cgwsa-proj%2Cgrundwasserschutzzonen%2Cgwsz-proj%2Cgewaesserabstandslinien%2Cgal-proj%2CBaulinien_Nationalstrassen_OeREB%2Cbaulinien%2Cbl-proj%2Cwaldgrenzen%2Cwg-proj%2Cwaldabstandslinien%2Cwal-proj%2Cmarkierung-liegenschaft%2Cmarkierung-sr`
    }
  },
  methods: {
    ...mapActions({
      deleteBuilding: 'building/deleteBuilding',
      refreshBuildingsById: 'portfolio/refreshBuildingsById'
    }),
    onExport () {
      exportService.exportBuildingJson(this.building)
    },
    async onDelete () {
      const confirmationText = this.$t('deleteConfirmation')
      const portfolioId = this.building.portfolio_id
      if (confirm(confirmationText)) {
        try {
          this.deletePending = true
          await this.deleteBuilding({
            'buildingId': this.building.id,
            'portfolioId': this.portfolio.id
          })
        } catch (error) {
          this.deleteError = error
        }
        this.deletePending = false
        await this.refreshBuildingsById(portfolioId)
        this.$router.push({ name: 'portfolio', params: { portfolio_id: portfolioId } })
      }
    }
  },
  components: {
    ButtonWrapper,
    MainLayout,
    BuildingMap,
    PvOverview,
    OverviewSection,
    MeasuresList
  }
}
</script>

<style>
.building-page .building-map {
  margin-bottom: 3px; /* allow header shadow */
}
.building-page .building-page-section {
  margin-bottom: var(--spacing-l);
}
.building-page .gis-links {
  padding: 0 var(--spacing-m);
  display: flex;
  justify-content: flex-end;
}
.building-page .gis-links a {
  padding: var(--spacing-xs);
  margin: var(--spacing-xxs);
  font-size: var(--font-xs);
  border: var(--box-border);
  border-radius: var(--box-radius);
}
</style>
