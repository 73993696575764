<i18n>
{
  "de": {
    "header": "Nutzenergie Raumwärme",
    "highCalibrationFactorWarningTitle": "Effektiver Energieverbrauch höher als erwartet",
    "highCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich über dem modellierten Energiebedarf. Für mögliche Ursachen siehe Kalibrierung Wärmeerzeugung.",
    "lowCalibrationFactorWarningTitle": "Effektiver Energieverbrauch tiefer als erwartet",
    "lowCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich unter dem modellierten Energiebedarf. Für mögliche Ursachen siehe Kalibrierung Wärmeerzeugung."
  }
}
</i18n>

<template>
  <detail-list-item class="rh-summary-row" :title="$t('header')" expandable @expand="onModalOpen">
    <div class="two-columns">
      <span>
        {{ formatNumber(building.kpi.rh_demand / 1000, 0) }} MWh
        <span v-if="building.kpi.rh_calibration > 1.667">
          <data-warning :title="$t('highCalibrationFactorWarningTitle')"><span v-html="$t('highCalibrationFactorWarning')" /></data-warning>
        </span>
        <span v-else-if="building.kpi.rh_calibration < 0.6">
          <data-warning :title="$t('lowCalibrationFactorWarningTitle')"><span v-html="$t('lowCalibrationFactorWarning')" /></data-warning>
        </span>
      </span>
      <span>
        <span>{{ formatNumber(building.kpi.rh_demand / building.kpi.energy_area, 0) }} kWh/m<sup>2</sup></span>
        &nbsp;<energy-label :value="rhRatio" class="energy-label" />
      </span>
    </div>
    <template #rightContent>
      <histogram-chart
          :key="'rh_histogram'"
          :group-by-key="'rh_demand'"
          area-specific
          :buildings="portfolio.buildings"
          :highlight-value="building.kpi['rh_demand'] / building.kpi.energy_area"
          mini-chart
          class="histogram-chart"
        />
    </template>
    <template #modal>
      <rh-modal v-if="modalOpen" @close="onModalClose" :building="building" :portfolio="portfolio" />
    </template>
  </detail-list-item>
</template>

<script>
import EnergyLabel from '@/components/shared/EnergyLabel'
import DetailListItem from '@/components/shared/lists/DetailListItem'
import DataWarning from '@/components/shared/DataWarning'
import RhModal from '@/components/building/kpi/RhModal'
import HistogramChart from '@/components/portfolio/kpi/charts/HistogramChart'

export default {
  data: function () {
    return {
      modalOpen: false
    }
  },
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    building: {
      type: Object,
      required: true
    }
  },
  computed: {
    rhRatio () {
      return (this.building.kpi.rh_demand / this.building.kpi.energy_area) / this.building.kpi.rh_limit
    }
  },
  methods: {
    onModalOpen () {
      this.modalOpen = true
    },
    onModalClose () {
      this.modalOpen = false
    }
  },
  components: {
    DetailListItem,
    EnergyLabel,
    DataWarning,
    RhModal,
    HistogramChart
  }
}
</script>
